@media screen and (min-width: 2501px) {
}

@media screen and (min-width: 1921px) and (max-width: 2500px) {
}

@media screen and (min-width: 1600px) and (max-width: 1920px) {
}

@media screen and (max-width: 1400px) {
  .search_result_page
    .colL
    .propert_list.list_view
    .col.search-explore-grid
    .inner
    .image_slider
    .item {
    padding-bottom: 90%;
  }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
}

@media screen and (max-width: 1200px) {
  /*==================head tags=================*/

  /*====================form placeholder font==============*/

  ::-webkit-input-placeholder {
    font-size: 12px;
  }

  ::-moz-placeholder {
    font-size: 12px;
  }

  :-ms-input-placeholder {
    font-size: 12px;
  }

  :-moz-placeholder {
    font-size: 12px;
  }

  /* nav > ul > li > a{
    font-size: 14px;
    text-align: center;
    padding: 7px 0px 0;
}

#nav > li {
    position: relative;
    padding: 0 10px;
}
nav{
    margin-left: 264px;
} */
  /*_____ Navigation _____*/

  .toggle {
    display: flex;
  }

  nav {
    justify-content: flex-end;
    margin-left: 0;
    margin-right: 40px;
  }

  [nav] {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-flow: column;
    width: auto;
    background: #fffffff7;
    margin: 0;
    padding: 40px 50px 20px;
    opacity: 0;
    visibility: hidden;
    overflow: auto;
    z-index: 3;
    justify-content: center;
  }

  [nav].active {
    opacity: 1;
    visibility: visible;
  }

  [nav] > ul {
    width: 100%;
    flex-flow: wrap;
    margin: 0;
  }

  [nav] > ul > li {
    width: 100%;
    padding: 7px 0px;
    text-align: center;
  }

  [nav] > ul > li .webBtn {
    margin-top: 5px;
  }

  [nav] > #cta {
    margin: auto 0 0;
  }

  nav > #iconBtn > li#srchBtn {
    display: inline-block;
  }

  #nav > li:not(.btnLi) > a:before {
    left: auto;
  }

  .logo {
    margin-top: 0;
  }

  .topNav {
    display: none;
  }

  #nav > li.btnLi {
    margin-top: 10px;
  }

  nav #cta {
    margin-right: 20px;
  }

  .how-it-work .colL {
    width: 100%;
    padding-right: 0;
    padding-bottom: 20px;
  }

  .how-it-work .colR {
    width: 100%;
    padding-left: 0;
  }

  .background_check_sec .col {
    width: 100%;
  }

  .info_sec .col .inner {
    flex-direction: column;
  }

  .info_sec .col {
    display: flex;
    gap: 20px;
  }

  .info_sec .col .inner .image {
    width: 100%;
    padding-bottom: 54%;
    position: relative;
  }

  .info_sec .col .inner .cntnt {
    padding: 15px;
  }

  .extra_info_sec .colL {
    width: 100%;
    padding-right: 0;
  }

  .extra_info_sec .colR {
    width: 100%;
  }

  .destination .flex,
  .extra_info_sec .colR ._inner,
  .info_sec .flex,
  .propert_list {
    flex-flow: nowrap;
    justify-content: normal;
    overflow: hidden;
    overflow-x: auto;
    width: calc(100% + 2rem);
  }

  .why_choose_us .flex {
    flex-flow: nowrap;
    justify-content: normal;
    overflow: hidden;
    overflow-x: auto;
    width: calc(100% + 2rem);
    margin: -10px;
  }

  .destination .col,
  .extra_info_sec .colR ._inner .col,
  .info_sec .col,
  .info_sec .col:nth-child(2),
  .propert_list .col,
  .why_choose_us .flex .col {
    width: auto;
  }

  .destination .col .inner,
  .extra_info_sec .inner,
  .propert_list .col .inner,
  .why_choose_us .flex .inner {
    width: 350px;
  }

  .info_sec .col .inner {
    width: 350px;
    height: 100%;
  }

  .info_sec .col .inner .bTn a {
    width: 49%;
  }

  .top_rated_searches .flex ul li {
    width: calc(100% / 3);
  }

  /* -----footer---- */
  footer .flexRow {
    flex-wrap: wrap;
  }

  footer .flexRow > .col {
    flex: unset;
    width: calc(100% / 4);
  }

  footer .flexRow > .col:last-child {
    width: 50%;
    flex: unset;
  }

  /* ____about us page_____ */
  .right_about_img {
    width: 100%;
  }

  .about_banner .cntnt {
    padding: 100px 0px 100px 0px;
    width: 100%;
    z-index: 1;
  }

  .right_about_img:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgb(241 236 236 / 82%);
  }

  .why_choose_us .col {
    padding: 10px 10px;
  }

  .why_choose_us .col .inner {
    box-shadow: 0px 1px 8px 0 rgb(89 181 210 / 31%);
  }

  .featured_posts .col_feature .inner .image_feature {
    width: 220px;
  }

  .services_banner .top_right_image {
    width: 594px;
    height: auto;
  }

  .service_one .start_arrow {
    bottom: 2%;
  }

  .service_one .start_arrow .arrow_img {
    overflow: hidden;
  }

  .service_one .start_arrow .arrow_img img {
    height: 100%;
  }

  /* ___send offer__ */
  .divide_offer_flex .inner_colL {
    width: 100%;
    padding-right: 0;
    display: flex;
    gap: 20px;
    justify-content: center;
  }

  .divide_offer_flex .inner_colL .property_card {
    width: auto;
    flex-wrap: wrap;
  }

  .divide_offer_flex .inner_colL .landlord_card {
    align-self: flex-start;
  }

  .divide_offer_flex .inner_colR {
    width: 100%;
    padding-left: 0;
  }

  /* ___explore___ */
  .search_result_page .colR {
    opacity: 0;
    width: 100%;
  }

  .search_result_page.active .colR {
    opacity: 1;
  }

  .search_result_page .colL {
    width: 100%;
    min-height: auto;
  }

  .search_result_page.active .colL {
    opacity: 0;
  }

  .search_nav {
    width: 100%;
    top: 70px;
    padding: 12px;
    background: #fff;
    height: 70px;
    border-top: 1px solid #ddd;
    display: none;
  }

  .search_nav.cell_search_nav {
    display: block;
    width: 250px;
    padding: 0;
    background: transparent;
    top: 13px;
    border-top: none;
  }

  .search_btn_show {
    position: absolute;
    top: 17px;
    left: 72px;
    background: #40c1fd;
    width: 40px;
    height: 40px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 20px;
    cursor: pointer;
  }

  .search_nav_header {
    position: absolute;
    top: 17px;
    color: #4c4c4c;
    left: 0;
    width: 100%;
    right: 0;
    display: flex;
    justify-content: center;
  }

  .filter_nav {
    width: 100%;
  }

  .search_result_page .colL .propert_list {
    margin: -20px;
    width: auto;
    flex-wrap: wrap;
  }

  .search_result_page .colL .propert_list.grid_view .col {
    width: calc(100% / 3) !important;
  }

  .search_result_page .colL .propert_list.grid_view .col .inner {
    width: 100%;
  }

  .search_result_page {
    padding-bottom: 70px !important;
    padding-top: 20px !important;
  }

  /* ____dashboard___ */
  .dash_intro_flex .colL {
    width: 30%;
  }

  .dash_intro_flex .colR {
    width: 70%;
  }

  .tile_flex .col {
    width: calc(100% / 3);
  }

  .chart_blk .col {
    width: 50%;
  }

  .chart_blk .col {
    padding: 20px 10px 0;
  }

  /* __property__ */
  .grid_dash_property_listing .col {
    width: calc(100% / 3);
  }

  .list_dash_property_listing .col .inner .cntnt {
    padding: 0px 35px 0px 30px;
  }

  .dash_property_listing .col .inner .cntnt ul.flex {
    gap: 14px;
  }

  .dash_property_listing .col .inner .cntnt .flex li:not(:first-child):before {
    left: -6px;
  }

  /* ______lease____ */
  .offer_dash_page .outer_list .lst > ul > li:nth-child(1) {
    width: 30%;
  }

  .offer_dash_page .outer_list .lst > ul > li:nth-child(2) {
    width: 25%;
  }

  /* lease detail--- */
  .offer_dash_detail_page .colL {
    width: 30%;
  }

  .offer_dash_detail_page .colR {
    width: 70%;
  }

  .offer_dash_detail_page .colL .property_card {
    flex-wrap: wrap;
    width: auto;
  }

  .offer_dash_detail_page .colL .property_card .col .inner {
    width: 100%;
  }

  /* ----offer--- */
  .offer_dash_page .outer_list .lst > ul > li {
    font-size: 12px;
  }

  /* ---bank account--- */
  .bank_show_listing li {
    flex: unset;
    padding: 10px !important;
    width: calc(100% / 3) !important;
  }

  .flex_maint_persons > * {
    width: calc(100% / 4);
  }

  .maintenence_list_team .col {
    width: calc(100% / 2);
  }

  /* -commercial_dashboard_new--- */
  .commercial_dashboard_new > .dashboard > .contain > .dash_intro_flex > .colL {
    display: none;
  }

  .commercial_dashboard_new .dashboard .dash_intro_flex .colR {
    width: 100%;
    padding-left: 0;
  }

  .cell_show_map {
    display: flex !important;
    color: #121b22 !important;
    border: 1px solid #121b22;
    border-radius: 2px;
    background: transparent;
    padding: 2px 7px;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    height: 30px;
    width: 31px;
    margin-left: 10px;
    align-items: center;
    justify-content: center;
    /* margin-bottom: -1px; */
  }

  .search_result_page .colL .propert_list.list_view .col .inner {
    width: 100% !important;
  }

  .search_result_page .colL .propert_list.list_view .col {
    width: 50% !important;
  }

  .search_result_page
    .colL
    .propert_list.list_view
    .col.search-explore-grid
    .inner
    .image_slider
    .item {
    padding-bottom: 110%;
  }

  .custom_tbl_tbl {
    max-width: 100%;
    margin: 0 auto;
  }
  .custom_ofer_heading {
    max-width: 100%;
    margin: 0 auto 2rem;
  }
  .result_maintenance_flex .flex .card_col {
    width: calc(100% / 2);
  }
  .left_nav ul {
    align-self: unset;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
}

@media screen and (max-width: 991px) {
  /*_____ Navigation _____*/

  .toggle {
    display: flex;
  }

  nav {
    justify-content: flex-end;
    margin-left: 0;
    margin-right: 40px;
  }

  [nav] {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-flow: column;
    width: auto;
    background: #fffffff7;
    margin: 0;
    padding: 40px 50px 20px;
    opacity: 0;
    visibility: hidden;
    overflow: auto;
    z-index: 3;
    justify-content: center;
  }

  [nav].active {
    opacity: 1;
    visibility: visible;
  }

  [nav] > ul {
    width: 100%;
    flex-flow: wrap;
    margin: 0;
  }

  [nav] > ul > li {
    width: 100%;
    padding: 7px 0px;
    text-align: center;
  }

  [nav] > ul > li .webBtn {
    margin-top: 5px;
  }

  [nav] > #cta {
    margin: auto 0 0;
  }

  nav > #iconBtn > li#srchBtn {
    display: inline-block;
  }

  #nav > li:not(.btnLi) > a:before {
    left: auto;
  }

  .logo {
    margin-top: 0;
  }

  .topNav {
    display: none;
  }

  #nav > li.btnLi {
    margin-top: 10px;
  }

  nav #cta {
    margin-right: 20px;
  }

  /* ====================home==================== */

  body > main {
    position: relative;
    z-index: 1;
  }

  .search_nav {
    width: 100%;
    top: 70px;
    padding: 12px;
    background: #fff;
    height: 70px;
    border-top: 1px solid #ddd;
    display: none;
  }

  .search_btn_show {
    position: absolute;
    top: 17px;
    left: 72px;
    background: #40c1fd;
    width: 40px;
    height: 40px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 20px;
    cursor: pointer;
  }

  .search_nav.cell_search_nav {
    display: block;
    width: 250px;
    padding: 0;
    background: transparent;
    top: 13px;
    border-top: none;
    left: 10%;
    right: auto;
  }

  /* ____home page___ */
  .banner-form {
    max-width: 80%;
  }

  .feature_icon .col {
    width: 50%;
  }

  /* .propert_list .col{
    width: 50%;
} */
  .how-it-work .colL {
    width: 100%;
    padding-right: 0;
    padding-bottom: 20px;
  }

  .how-it-work .colR {
    width: 100%;
    padding-left: 0;
  }

  .background_check_sec .col {
    width: 100%;
  }

  .info_sec .col .inner {
    flex-direction: column;
  }

  .info_sec .col {
    display: flex;
    gap: 20px;
  }

  .info_sec .col .inner .image {
    width: 100%;
    padding-bottom: 54%;
    position: relative;
  }

  .info_sec .col .inner .cntnt {
    padding: 15px;
  }

  .extra_info_sec .colL {
    width: 100%;
    padding-right: 0;
  }

  .extra_info_sec .colR {
    width: 100%;
  }

  .destination .flex,
  .extra_info_sec .colR ._inner,
  .info_sec .flex,
  .propert_list {
    flex-flow: nowrap;
    justify-content: normal;
    overflow: hidden;
    overflow-x: auto;
    width: calc(100% + 2rem);
  }

  .destination .col,
  .extra_info_sec .colR ._inner .col,
  .info_sec .col,
  .info_sec .col:nth-child(2),
  .propert_list .col {
    width: auto;
  }

  .destination .col .inner,
  .extra_info_sec .inner,
  .propert_list .col .inner {
    width: 350px;
  }

  .info_sec .col .inner {
    width: 350px;
    height: 100%;
  }

  .info_sec .col .inner .bTn a {
    width: 49%;
  }

  .top_rated_searches .flex ul li {
    width: calc(100% / 3);
  }

  /* -----footer---- */
  footer .flexRow {
    flex-wrap: wrap;
  }

  footer .flexRow > .col {
    flex: unset;
    width: calc(100% / 3);
  }

  footer .flexRow > .col:last-child {
    width: 50%;
  }

  footer .flexRow > .col:nth-child(4) {
    width: 50%;
  }

  footer .flexRow > .col:last-child {
    flex: unset;
    min-width: 50%;
  }

  .overview .colL {
    width: 100%;
    padding-right: 0;
    margin-bottom: 20px;
  }

  .overview .colR {
    width: 100%;
    padding-left: 0;
  }

  .overview .colR .flex {
    margin: -10px;
  }

  .testimonial_sec .left_side_image {
    width: 100%;
    /* display: none; */
  }

  .testimonial_sec .left_side_image:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgb(255 255 255 / 81%);
  }

  .testimonial_sec .cntnt {
    width: 100%;
    padding: 80px 0px 80px 0px;
    z-index: 1;
  }

  /* __blog page__ */
  .featured_posts .col_feature .inner {
    flex-direction: column;
  }

  .featured_posts .col_feature .inner .txt {
    margin-left: 0;
    padding: 10px;
  }

  .featured_posts .col_feature .inner .image_feature {
    width: 100%;
    padding-bottom: 50%;
  }

  .blog_page .colL {
    width: 70%;
  }

  .blog_page .colL .inner_blog .col {
    width: 50%;
  }

  .blog_page .colR {
    width: 30%;
  }

  .blog_page .colL .inner_blog .col .inner .image {
    padding-bottom: 60%;
  }

  /* ____services___ */
  .services_banner .top_right_image {
    width: 380px;
    top: -80px;
  }

  .services_banner {
    padding: 75px 0 !important;
  }

  .services_banner .colL {
    width: 53%;
  }

  .service_one .colR {
    padding: 70px 0px 70px 90px;
  }

  .service_one .contain .flex:nth-child(even) .colR {
    padding: 70px 90px 70px 0px;
  }

  .service_one .colL {
    align-self: center;
  }

  /* __partners___ */
  .partner_sec ._col {
    width: 100%;
  }

  .mission_cn_p {
    height: auto;
  }

  .testimonial_sec {
    min-height: auto;
  }

  .goals_sec .goal_slider .inner {
    height: auto;
  }

  /* ___contacts___ */
  .contact_page .colL,
  .contact_page .colR {
    padding: 30px;
  }

  /* ___search prpety__ */
  .search-detail-grid .result_gallery .imgGallery .col {
    height: 145px;
  }

  .icon_listing li {
    width: calc(100% / 4);
  }

  .review_heading ul.all_rating li {
    width: calc(100% / 3);
  }

  /* ___send offer___ */
  .multip_step_form_offer .option_lbl ul li,
  .option_lbl ul li {
    width: 100px;
  }

  .option_lbl ul li {
    padding: 0px 5px;
  }

  .option_lbl ul li.active {
    font-size: 12px;
  }

  .popup {
    padding: 20px 10px;
  }

  .search_result_page .colL .propert_list.grid_view .col {
    width: calc(100% / 2) !important;
  }

  /* ___dashboard___ */
  .chart_blk .col {
    width: 100%;
  }

  /* __properties__ */
  .list_dash_property_listing .col {
    width: 100%;
  }

  .grid_dash_property_listing .col {
    width: calc(100% / 2) !important;
  }

  /* __add property__,, */

  .dash_form_blk form fieldset {
    width: 100%;
    padding: 15px;
  }

  .gallery_pop .col,
  .view_property_gallery .imgGallery .col {
    height: auto;
  }

  /* _leases__ */
  .offer_dash_page .outer_list .lst > ul {
    justify-content: space-between;
  }

  .offer_dash_page .outer_list .lst > ul > li {
    flex: unset;
    width: 25%;
    padding: 10px;
  }

  .offer_dash_page .outer_list .lst > ul {
    margin: -10px;
  }

  /* _lease__ */
  .offer_dash_page .outer_list .lst > ul > li:nth-child(1) {
    width: 50%;
  }

  .offer_dash_page .outer_list .lst > ul > li:nth-child(2) {
    width: 50%;
  }

  .offer_dash_page .outer_list .lst > ul > li {
    width: calc(100% / 5);
    /* text-align: left; */
  }

  .tenants_dps_flex {
    /* justify-content: flex-start; */
    width: 100%;
  }

  /* ---lease detail--- */
  .offer_dash_detail_page .colL {
    width: 35%;
  }

  .offer_dash_detail_page .colR {
    width: 65%;
  }

  .offer_cstm_ul li,
  .offer_dash_detail_page .sender_bio .sender_offer_col {
    width: calc(100% / 2);
  }

  .offer_dash_page.rental-offer-page-dt
    .outer_list
    .lst
    > ul
    > li:last-child
    a {
    font-size: 12px;
  }

  .flex_maint_persons > * {
    width: calc(100% / 3);
  }

  /* ---staff--- */
  .maintenance_detail_flex .colL .inner {
    flex-direction: column;
  }

  .maintenance_detail_flex .colL .inner .image {
    margin: 0px 0 20px;
  }

  .maintenance_detail_flex .colL .inner .txt {
    margin-left: 0;
  }

  .maintenence_list_team .col {
    width: calc(100% / 2);
  }

  /* --invoices--- */
  .add_payment_cstm_btn {
    padding: 4px 7px;
    font-size: 13px;
  }

  .commercial_header_nav {
    display: flex;
  }

  /* ---commercial dashboard--- */
  .listing_list_c > .outer_list_c > .lst > ul > li:nth-child(2),
  .listing_list_c > .outer_list_c > .lst > ul > li:nth-child(1),
  .listing_list_c > .outer_list_c > .lst > ul > li:nth-child(3),
  .listing_list_c > .outer_list_c > .lst > ul > li:nth-child(4),
  .listing_list_c > .outer_list_c > .lst > ul > li:nth-child(5),
  .listing_list_c > .outer_list_c > .lst > ul > li:nth-child(6),
  .listing_list_c > .outer_list_c > .lst > ul > li:nth-child(7) {
    width: auto !important;
    padding: 10px;
  }

  .property_form_commercial_flex .lblBtn {
    margin-bottom: 15px;
  }

  .property_form_commercial_flex .lblBtn label {
    margin-left: 0;
  }

  .property_form_commercial_flex {
    margin: 0px auto !important;
  }

  /* --add media=== */
  .heighlight_fieldset {
    width: 100%;
  }

  .search_result_page .colL .propert_list.list_view .col {
    width: 100% !important;
  }
  .blockLst.cell_block_cell table tr > * {
    width: fit-content;
  }

  /* .invoice_toggle.new_invoice_togg > ul{
    justify-content: space-between;
  }
  .invoice_toggle.new_invoice_togg > ul > li{
    width: unset;
  } */
  .promo_abt_icon {
    right: 6rem;
  }

  .new_responsive_b_dy,
  .result_maintenance_flex .flex .card_col .inner_card {
    flex-direction: column;
  }
  .maintenence_list_team .col .inner .b_dy.new_responsive_b_dy .b_dyinner,
  .result_maintenance_flex .flex .card_col .inner_card .detail_user {
    align-self: flex-start;
    padding-left: 0rem;
    padding-right: 0rem;
  }
  .maintenence_list_team .col .inner .b_dy.new_responsive_b_dy .user_info,
  .result_maintenance_flex .flex .card_col .inner_card .info_user {
    text-align: left;
    max-width: 100%;
  }
  .maintenence_list_team .col .inner .b_dy.new_responsive_b_dy .small_image,
  .result_maintenance_flex .flex .card_col .inner_card .info_user .icon_user {
    margin: 0 0 1rem;
  }
  .result_maintenance_flex .flex .card_col .inner_card .detail_user {
    margin-top: 1rem;
  }

  .recommendation_slider > .slick-slider > .slick-prev{
    left: -1rem!important;
  }
  .recommendation_slider > .slick-slider > .slick-prev:before , .recommendation_slider > .slick-slider > .slick-next:before{
    color: #000!important;
    font-size: 27px;
  }
  .recommendation_slider > .slick-slider > .slick-next{
    right: -1rem!important;
  }
  .recommendation_slider.new_recommended_slider .col{
    width: calc(100% / 3)!important;
  }
}

@media screen and (max-width: 767px) {
  .banner {
    height: auto;
  }

  .banner-form {
    max-width: 100%;
  }

  .how-it-work .colR .col {
    width: 50%;
  }

  /* __blog__ */
  .blog_page .colR {
    width: 100%;
    padding-left: 0;
  }

  .blog_page .colL {
    width: 100%;
    padding-right: 0;
    padding-bottom: 20px;
  }

  .featured_posts .col_feature .inner .image_feature {
    padding-bottom: 60%;
  }

  /* __services__ */
  .services_banner .top_right_image {
    display: none;
  }

  .services_banner .colL {
    width: 100%;
  }

  /* ____contact__ */
  .contact_page .colL,
  .contact_page .colR {
    width: 100%;
  }

  .contact_page .colR .social {
    position: relative;
    margin-bottom: 0;
  }

  /* _________propertydetail__ */
  .property_detail_main_sr .view_propert_detail {
    padding: 28px 0px;
  }

  .property_detail_main_sr .view_propert_detail.small_head {
    padding: 10px 0px;
  }

  .search-detail-grid .result_gallery .imgGallery .col {
    width: calc(100% / 4);
  }

  .result_detail_blk .flex.main_detail_head {
    flex-direction: column;
  }

  .result_detail_blk .main_detail_head .right_info {
    align-self: flex-start;
    margin-top: 20px;
  }

  .feature_lising li,
  .icon_listing li {
    width: calc(100% / 3);
  }

  .view_propert_detail .view_flex .property_heading h2 {
    width: 100%;
  }

  .detail_review_star {
    margin-left: 0;
    margin-top: 0;
    margin-bottom: 10px;
  }

  /* ___send offer__ */
  .divide_offer_flex .inner_colL {
    flex-direction: column;
    gap: 0;
  }

  .divide_offer_flex .inner_colL .landlord_card {
    width: 100%;
  }

  .divide_offer_flex .inner_colL .property_card .col {
    width: 100%;
    margin: 0 auto;
  }

  .option_lbl ul {
    flex-wrap: nowrap;
    padding-bottom: 40px;
  }

  .option_lbl ul:before {
    bottom: 23px;
  }

  .multip_step_form_offer fieldset {
    margin-top: 15px;
  }

  .multip_step_form_offer .option_lbl ul li {
    /* width: auto; */
    flex: 1;
  }

  .offer_send_confirm li {
    width: 100% !important;
  }

  .width_full_9 {
    width: 100%;
  }

  .mini_offer_main_price {
    max-width: 100%;
  }

  .row {
    margin-left: -5px;
    margin-right: -5px;
  }

  .col-lg-1,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-md-1,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-sm-1,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-xs-1,
  .col-xs-10,
  .col-xs-11,
  .col-xs-12,
  .col-xs-2,
  .col-xs-3,
  .col-xs-4,
  .col-xs-5,
  .col-xs-6,
  .col-xs-7,
  .col-xs-8,
  .col-xs-9 {
    padding-right: 5px;
    padding-left: 5px;
  }

  /* _dashboard___ */
  .dash_intro_flex .colL {
    width: 35%;
  }

  .dash_intro_flex .colR {
    width: 65%;
  }

  .dashboard_user_card {
    padding: 15px;
  }

  .tile_flex .col .inner {
    padding: 10px;
  }

  .tile_flex .col .inner .tile_cntnt h4 {
    font-size: 14px;
  }

  .option_lbl {
    overflow: auto;
  }

  /* __add property__., */
  .dash_form_blk {
    margin-top: 15px;
  }

  .view_property_gallery .imgGallery .col {
    height: auto;
    width: calc(100% / 3);
  }

  .view_property_flex .colL {
    margin-top: 180px;
  }

  /* __lease detail--- */
  .offer_dash_detail_page .colL {
    width: 40%;
  }

  .offer_dash_detail_page .colR {
    width: 60%;
  }

  .offer_dash_detail_page .sender_bio .sender_offer_col {
    width: 100%;
  }

  .leases-flex-pay .left_col {
    width: 100%;
    padding-right: 0;
  }

  .leases-flex-pay .right_col {
    width: 100%;
    padding-left: 0;
  }

  .mini-release_width,
  .bTn.offer_action_btn {
    max-width: 100%;
  }

  /* ---add leases--- */
  .offer_leases_pop_outer ul li .flex {
    margin: -10px;
  }

  .offer_leases_pop_outer .col_mini_col {
    padding: 10px;
    font-size: 13px;
  }

  .offer_leases_pop_outer .col_mini_col:first-child {
    width: 15%;
  }

  .offer_leases_pop_outer .col_mini_col:nth-child(2) {
    width: 25%;
  }

  .lease_heading_mini {
    font-size: 11px;
  }

  /* add leases.. */
  .lease_tenants_creat_box .sender_bio .inner {
    padding: 10px;
  }

  .lease_tenants_creat_box .sender_bio .sender_offer_col {
    width: 50%;
  }

  .dash_heading_sec .main_page_heading a {
    align-self: center;
  }

  .filter_status_offer .col_ofer_filter {
    flex-direction: column;
    flex: 1;
  }

  .filter_status_offer .sorted_heading {
    align-self: flex-start;
    margin-right: 0;
    width: 100%;
  }

  .filter_status_offer .sorted_drop_down {
    align-self: flex-start;
    width: 100%;
  }

  /* ---payment table--- */
  .blockLst table tr > * {
    padding: 10px 5px;
    font-size: 13px;
  }

  /* --invoices-- */
  .invoice_bdy .colL {
    width: 100%;
    padding-right: 0;
    margin-bottom: 20px;
  }

  .invoice_bdy .colR {
    width: 100%;
    padding-left: 0;
  }

  /* ---chat-- */
  [inbox] .barBlk {
    width: 100%;
    min-width: initial;
  }

  [inbox] .chatBlk {
    display: none;
    position: fixed;
    top: 80px;
    left: 0;
    right: 0;
    bottom: 0;
    height: auto;
    background: #f6f9fc;
    z-index: 32;
  }

  [inbox] .chatBlk.active {
    display: flex;
  }

  [inbox] .chatPerson .backBtn {
    display: block;
  }

  .expenses_flex .col {
    width: calc(100% / 1) !important;
  }

  .invoice_toggle_outer .invoice_toggle > ul {
    gap: 0;
  }

  .search_toggle_com.invoice_toggle_outer .invoice_toggle > ul > li {
    flex: unset;
    width: 50%;
    padding: 10px;
  }

  .search_expense_com_main .colL {
    width: 100% !important;
  }

  .header_commercial_search_pg {
    flex-direction: column;
  }

  .cstm_mini_search_vertical {
    align-self: flex-start;
  }

  .header_commercial_search_pg .cstm_mini_search_vertical .bTn {
    margin-top: 10px;
    margin-left: -5px;
  }

  /* ---commercial dashboard--- */
  .listing_list_c > .outer_list_c > .lst > ul > li:nth-child(2),
  .listing_list_c > .outer_list_c > .lst > ul > li:nth-child(1),
  .listing_list_c > .outer_list_c > .lst > ul > li:nth-child(3),
  .listing_list_c > .outer_list_c > .lst > ul > li:nth-child(4),
  .listing_list_c > .outer_list_c > .lst > ul > li:nth-child(5),
  .listing_list_c > .outer_list_c > .lst > ul > li:nth-child(6),
  .listing_list_c > .outer_list_c > .lst > ul > li:nth-child(7) {
    width: calc(100% / 3) !important;
    font-size: 12px;
    padding: 10px;
  }

  .listing_list_c > .outer_list_c > .lst > ul > li:last-child {
    width: 100% !important;
  }
  .listing_list_c
    > .outer_list_c
    > .lst.list_promotion_toggle_new
    > ul
    > li:last-child {
    width: 33.33% !important;
  }

  .badge {
    font-size: 9px;
  }

  .user_dash_tbl.blockLst table tr > * {
    font-size: 12px;
  }

  .view_property_flex .around_home_school .colL,
  .around_home_school .colL {
    width: 100%;
    padding-right: 0;
  }
  .view_property_flex .around_home_school .colR,
  .around_home_school .colR {
    width: 100%;
    padding-left: 0;
    margin-top: 3rem;
  }

  .filter_popup.filter_new_design {
    padding-top: 9rem;
  }
  ._new_lst_wide {
    width: 5rem;
  }
  .recommendation_slider.new_recommended_slider .col{
    width: calc(100% / 2)!important;
  }
}

@media screen and (max-width: 580px) {
  nav #cta {
    display: none;
  }

  #nav > li #cta {
    display: flex;
    flex-direction: column;
  }

  #nav > li #cta .webBtn {
    color: #fff;
  }

  nav.explore_nav > ul#iconBtn {
    display: none;
  }

  .top_rated_searches .flex ul li {
    width: 50%;
  }

  .service_one .start_arrow {
    right: auto;
  }

  .service_one .contain .flex {
    width: 70%;
    margin: 0;
    margin-left: auto;
  }

  .service_one .colL {
    align-self: center;
    width: 100%;
    padding: 20px !important;
  }

  .service_one .colR {
    align-self: center;
    width: 100%;
    padding: 20px !important;
  }

  .service_one .start_arrow .arrow_img {
    height: 100%;
  }

  .mission_vision .col {
    width: 100%;
  }

  .mission_vision .col:first-child {
    text-align: left;
  }

  section {
    padding: 50px 0px;
  }

  /* ___home___ */
  .feature_icon {
    padding-top: 50px !important;
  }

  .feature_icon .flex {
    margin-top: 0px;
  }

  .suitability_flex .lblBtn {
    width: calc(100% / 3);
  }

  .cmn_filter_btn {
    padding: 0px 12px;
  }

  .tile_flex .col {
    width: 50%;
  }

  .chart_blk .col {
    width: 100%;
  }

  .option_lbl {
    justify-content: flex-start;
  }

  /* _lease__ */
  .offer_dash_page .outer_list .lst > ul > li:nth-child(1) {
    width: 100%;
  }

  .offer_dash_page .outer_list .lst > ul > li:nth-child(2) {
    width: 50%;
  }

  .offer_dash_page .outer_list .lst > ul > li {
    width: calc(100% / 4);
    text-align: left;
  }

  .tenants_dps_flex {
    justify-content: flex-start;
    width: 100%;
  }

  /* --lease detail--- */

  .offer_dash_detail_page .colL {
    width: 100%;
    padding-right: 0;
  }

  .offer_dash_detail_page .colR {
    width: 100%;
    padding-left: 0;
    margin-top: 20px;
  }

  /* ---offer page--- */
  .offer_dash_page.rental-offer-page-dt .outer_list .lst > ul > li:first-child {
    width: 100%;
  }

  .offer_dash_page.rental-offer-page-dt .outer_list .lst > ul > li {
    width: 50%;
  }

  .dash_heading_sec .main_page_heading a,
  .dash_heading_sec h2 {
    font-size: 12px;
  }

  /* ---commercial dashboard--- */

  /* .listing_list_c > .outer_list_c > .lst > ul > li:nth-child(2) , .listing_list_c > .outer_list_c > .lst > ul > li:nth-child(1), .listing_list_c > .outer_list_c > .lst > ul > li:nth-child(3){
        width: 100%;
    } */
  .user_dash_tbl.blockLst table tr > * {
    font-size: 11px;
  }

  .blockLst table tr {
    display: flex;
  }

  .blockLst table tr > * {
    flex: 1;
    height: auto;
  }

  .small_web_btn {
    padding: 3px 7px;
    font-size: 11px;
    gap: 5px;
  }

  .commercial_dashboard_new .nav.nav-tabs {
    margin-bottom: 0;
  }

  .outer_around_home ul li .left_li {
    flex: 1;
  }

  .result_maintenance_flex .flex .card_col {
    width: calc(100% / 1);
  }

  .more_service.more_more:hover .after_hover_blk,
  .result_maintenance_flex
    .flex
    .card_col
    .inner_card
    .services_count
    span.more_service:hover
    .after_hover_blk {
    left: -4rem;
  }
  .more_service.more_more .after_hover_blk,
  .result_maintenance_flex
    .flex
    .card_col
    .inner_card
    .services_count
    span.more_service
    .after_hover_blk {
    bottom: 31px;
    left: -4rem;
  }
  .recommendation_slider.new_recommended_slider .col{
    width: calc(100% / 1)!important;
  }
}

@media screen and (max-width: 480px) {
  /*_____ col-xx-? _____*/
  .col-xx-12,
  .col-xx-11,
  .col-xx-10,
  .col-xx-9,
  .col-xx-8,
  .col-xx-7,
  .col-xx-6,
  .col-xx-5,
  .col-xx-4,
  .col-xx-3,
  .col-xx-2,
  .col-xx-1,
  .col-xs-6 {
    width: 100% !important;
  }

  .banner .cntnt h1 {
    font-size: 37px;
  }

  .feature_icon .col {
    width: 100%;
  }

  .feature_icon .flex .col:not(:last-child) {
    border-right: none;
    border-bottom: 1px solid #00000014;
  }

  .sec_heading h2 {
    font-size: 30px;
  }

  .how-it-work .colR .col {
    width: 100%;
  }

  .background_check_sec .col .inner {
    flex-direction: column;
  }

  .background_check_sec .col .inner .image {
    width: 100%;
    padding-bottom: 60%;
  }

  .background_check_sec .col .inner .cntnt {
    padding: 20px;
  }

  /* _footer__ */
  footer .flexRow > .col {
    width: 50%;
  }

  footer .flexRow > .col:last-child {
    width: 100%;
    min-width: 100%;
  }

  .copyright .inner {
    flex-direction: column-reverse;
    text-align: center;
    gap: 10px;
  }

  footer .smLst {
    justify-content: center;
  }

  /* __about us page__ */
  .page_heading h1 {
    font-size: 35px;
  }

  .about_banner .cntnt {
    padding: 80px 0px 80px 0px;
  }

  /* __blog___ */
  .featured_posts .col_feature,
  .blog_page .colL .inner_blog .col {
    width: 100%;
  }

  /* __services___ */
  .service_one .start_arrow {
    display: none;
  }

  .service_one .contain .flex {
    width: 100%;
  }

  .service_one .colR,
  .service_one .colL {
    padding: 10px 0px !important;
  }

  .service_one .contain .flex:nth-child(even) {
    margin: 40px 0px;
  }

  .bottom_right_service_top {
    bottom: 0;
  }

  /* ___partner-- */
  .partner_sec ._col .inner {
    flex-direction: column;
  }

  .partner_sec .colR {
    width: 100%;
    background: #fff;
    text-align: center;
  }

  /* _____-peroprty detail__ */
  .view_propert_detail .view_flex .property_heading {
    flex: unset;
    margin-right: 0;
  }

  .feature_lising li,
  .icon_listing li,
  .review_heading ul.all_rating li {
    width: 50%;
  }

  /* .review_heading .total_rating{
    flex-direction: column;
   } */
  .space_between_flex li.review_btn {
    align-self: center;
    margin-top: 10px;
  }

  .review_blk_listing li .client_info {
    flex-direction: column;
  }

  .intro_review_left {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .property_detail_main_sr .view_propert_detail {
    position: relative;
  }

  .property_detail_main_sr .search-detail-grid {
    margin-top: 0px;
  }

  .search-detail-grid .result_gallery .imgGallery .col {
    width: 50%;
  }

  /* __send offer__ */
  .property_card.propert_list .col .inner {
    width: 100%;
  }

  .col-lg-1,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-md-1,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-sm-1,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-xs-1,
  .col-xs-10,
  .col-xs-11,
  .col-xs-12,
  .col-xs-2,
  .col-xs-3,
  .col-xs-4,
  .col-xs-5,
  .col-xs-6,
  .col-xs-7,
  .col-xs-8,
  .col-xs-9 {
    width: 100% !important;
    /* padding:5px!important; */
  }

  .ofer_pkg_tbl {
    margin: -5px -10px;
  }

  .ofer_pkg_tbl li:nth-child(even) {
    width: 30%;
  }

  .ofer_pkg_tbl li:nth-child(odd) {
    width: 70%;
  }

  .chk .flex-2 .inner {
    width: 100%;
  }

  .creditCard .headCredit .inner:nth-child(2) {
    display: none;
  }

  .offer_send_confirm li {
    flex-direction: column;
  }

  .offer_send_confirm li * {
    align-self: flex-start !important;
    /* text-align: left!important; */
  }

  .offer_send_confirm li em {
    flex: unset;
  }

  .suitability_flex .lblBtn {
    width: 50%;
  }

  .popup {
    padding: 20px 10px;
  }

  .search_result_page .colL .propert_list.grid_view .col {
    width: calc(100% / 1) !important;
  }

  .show_mobile_filter_explore {
    display: block;
  }

  .filters .filter_col:nth-child(1),
  .filters .filter_col:nth-child(2),
  .filters .filter_col:nth-child(3),
  .filters .filter_col:nth-child(4),
  .filters .filter_col:nth-child(5) {
    display: none;
  }

  .filter_nav .result_header .sorted_filter .sorting-options {
    /* display: none; */
  }

  .filter_nav .result_header.cell_result_header {
    max-width: 90%;
  }

  .cell_inner_filters {
    width: fit-content;
    position: absolute;
    right: 13px;
    height: 66px;
    display: flex;
    align-items: flex-end;
  }

  .left_95 {
    left: -95px;
  }

  .sorted_filter {
    justify-content: space-between;
    width: 100%;
  }

  /* ___dashboard__ */
  .dash_intro_flex .colL {
    width: 100%;
    padding-right: 0;
    margin-bottom: 20px;
  }

  .dash_intro_flex .colR {
    width: 100%;
    padding-left: 0;
  }

  /* __property__ */
  .grid_dash_property_listing .col {
    width: calc(100% / 1) !important;
  }

  .list_dash_property_listing .col .inner .image {
    width: 90px;
    display: block;
    height: 90px;
  }

  .list_dash_property_listing .col .inner .cntnt {
    padding: 0px 0px 0px 10px;
    flex: 1;
  }

  .dash_property_listing .col .inner .cntnt h3 {
    padding-right: 20px;
  }

  /* .page_body .listing_tabs .nav-tabs{
        margin-left: -15px;
    } */
  /* __add property__, */
  .dash_heading_sec .main_page_heading {
    gap: 7px;
    margin-right: 0;
  }

  .dash_heading_sec .main_page_heading a,
  .dash_heading_sec h2 {
    font-size: 12px;
  }

  .small_flex_form .address_show p {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .small_flex_form .address_show {
    flex-direction: column;
  }

  .small_flex_form .address_show .bTn a {
    width: 100%;
    padding: 10px;
  }

  .crud_image h4 {
    margin-bottom: 20px;
    width: fit-content;
    margin-top: 5px;
  }

  .outer_image_blk_col {
    top: 9px;
    right: 6px;
    left: auto;
  }

  .crud_image .inside .imgLst > li {
    width: auto;
  }

  .add_more_curd .inner_curd {
    width: 63%;
    margin-bottom: 50px;
    margin-top: 0;
    flex-direction: column;
  }

  .add_more_curd .inner_curd h6 {
    align-self: flex-start;
  }

  .add_more_curd .outer_image_blk_col {
    top: 44px;
    left: auto;
  }

  .mrgn_auto {
    margin: 5px auto 10px;
    margin-bottom: 10px !important;
  }

  .crud_image {
    padding: 10px;
  }

  .full_wide_price_listing li {
    flex-direction: column;
  }

  .total_count_price_listing li {
    gap: 10px;
  }

  .view_property_flex .colL {
    margin-top: 20px;
  }

  .view_property_gallery .imgGallery .col {
    width: calc(100% / 2);
  }

  .filter_status_offer {
    /* flex-direction: column; */
    gap: 10px;
  }

  /* ---add lease--- */
  .offer_leases_pop_outer .col_mini_col:first-child {
    width: 100%;
  }

  .offer_leases_pop_outer .col_mini_col:nth-child(2) {
    width: 100%;
  }

  .offer_leases_pop_outer .col_mini_col {
    width: calc(100% / 1);
  }

  .popup_select_property .form_blk {
    margin-bottom: 15px;
  }

  .lease_tenants_creat_box .sender_bio .sender_offer_col {
    width: 100%;
  }

  .new_blk_cost_add .inner_blk .inner_colL {
    width: 100%;
    padding-right: 0;
  }

  .new_blk_cost_add .inner_blk .inner_colR {
    width: 100%;
    padding-left: 0;
    margin-top: 10px;
  }

  /* ---offer--- */
  .offer_dash_page.rental-offer-page-dt .outer_list .lst > ul > li {
    width: 50%;
    justify-content: flex-start;
  }

  .nav-tabs > li > a {
    padding: 10px 5px;
  }

  /* --bank account-- */
  .bank_show_listing li {
    width: calc(100% / 2) !important;
  }

  .blockLst table tr > * {
    display: block;
    text-align: center;
  }

  .payment_payment_new .tenants_dps_flex {
    justify-content: center;
  }

  .blockLst table tr {
    margin-bottom: 17px;
    display: block;
    box-shadow: 0px 0px 10px #ddd;
  }

  .tenants_dps_flex .inner {
    left: -140px;
  }

  .blockLst table thead {
    display: none;
  }

  .mini-show-cell {
    display: block;
    font-family: "Poppins SemiBold";
    margin-bottom: 5px;
  }

  .pay_ment_new_pop .pay_address .pay_cntnt_pop_new {
    flex: 1;
  }

  .pay_ment_new_pop {
    flex-direction: column;
    padding-right: 20px;
  }

  .pay_ment_new_pop .pay_address .pro_img_pop {
    height: 85px;
    width: 85px;
  }

  .pay_ment_new_pop .pay_address {
    margin-right: 0;
    margin-top: 30px;
  }

  .pay_ment_new_pop .pay_bal_pop {
    align-self: flex-end;
  }

  .view_offer_popup ._inner .blockLst {
    margin: -15px;
    background: #fff;
    border: 1px solid rgba(238, 238, 238, 0);
    padding: 15px;
  }

  /* ---Service Request--- */
  .offer_dash_page.maintenance_requests_lsting .outer_list .lst > ul > li {
    width: calc(100% / 2);
  }

  .offer_dash_page.maintenance_requests_lsting
    .outer_list
    .lst
    > ul
    > li:first-child,
  .offer_dash_page.maintenance_requests_lsting
    .outer_list
    .lst
    > ul
    > li:nth-child(2) {
    width: 100%;
    justify-content: flex-start;
  }

  .dash_heading_sec .main_page_heading {
    flex-wrap: wrap;
  }

  .time_ago_maintenance_request {
    position: relative;
    margin-bottom: 10px;
    justify-content: center;
  }

  .flex_maint_persons > * {
    width: calc(100% / 2);
  }

  /* --staff--- */
  .maintenance_detail_flex .colL {
    width: 100%;
    padding-right: 0;
  }

  .maintenance_detail_flex .colR {
    width: 100%;
    padding-left: 0;
    margin-top: 20px;
  }

  .staff_head_flex_view {
    flex-direction: column;
    gap: 15px;
  }

  .staff_head_flex_view > * {
    align-self: flex-start !important;
  }

  .maintenence_list_team .col {
    width: calc(100% / 1);
  }

  /* --profile-- */

  ._dropCnt.lg_drop {
    right: -78px;
  }

  ._dropCnt.lg_drop {
    min-width: 240px;
    max-width: 240px;
  }

  /* --invoices--- */
  /* .invoice_toggle_outer .invoice_toggle > ul{
        flex-direction: column;
    } */
  .invoice_toggle_outer .invoice_toggle > ul > li {
    align-self: flex-start;
    padding-right: 10px;
  }

  .invoice_toggle_outer .invoice_toggle > ul:after {
    right: 10px;
  }

  .btn_add_pay {
    margin-top: 0;
  }

  /* --create invoice--- */
  .cell_mrgn_top {
    margin-top: 15px;
  }

  .search_toggle_com.invoice_toggle_outer .invoice_toggle > ul > li {
    /* width: 100%; */
    width: auto;
  }

  .search_toggle_com.invoice_toggle_outer .invoice_toggle > ul {
    padding: 10px;
  }

  .search_toggle_com.invoice_toggle_outer
    .invoice_toggle
    > ul
    > li:nth-child(2) {
    padding-right: 30px;
  }

  /* add promotion=== */
  .listing_c_flex.head_flex {
    display: none;
  }

  .listing_c_flex_4 .col {
    width: 100% !important;
    padding: 5px 10px;
  }

  .listing_c_flex_4 {
    padding-bottom: 10px;
    margin-bottom: 10px;
  }

  .listing_c_flex_c .add_more_term_c .closeBtn {
    top: 3px;
    right: 4px;
  }

  .add_more_term_c .listing_c_flex .col:last-child {
    padding-right: 10px;
  }

  .listing_c_flex_c {
    padding: 10px;
  }

  .listing_list_c > .outer_list_c > .lst > ul > li:nth-child(2),
  .listing_list_c > .outer_list_c > .lst > ul > li:nth-child(1),
  .listing_list_c > .outer_list_c > .lst > ul > li:nth-child(3),
  .listing_list_c > .outer_list_c > .lst > ul > li:nth-child(4),
  .listing_list_c > .outer_list_c > .lst > ul > li:nth-child(5),
  .listing_list_c > .outer_list_c > .lst > ul > li:nth-child(6) {
    /* width: calc(100% / 3)!important; */
    width: unset !important;
    align-self: flex-start;
    padding: 3px;
  }

  .listing_list_c > .outer_list_c > .lst > ul > li:last-child {
    width: 100% !important;
  }

  .cell_center_self {
    justify-content: center;
  }

  .user_dash_tbl.blockLst table tr > *:last-child {
    height: 50px;
  }

  .flex_action_user_dash .action_drop {
    left: 0;
    right: 0;
  }

  .flex_action_user_dash .action_drop ._dropCnt {
    right: auto;
  }

  .user_dash_tbl.blockLst table tr > * {
    font-size: 13px;
  }

  /* ---new changes--- */
  .offer_dash_page.rental-offer-page-dt
    .hide_sm_clmn
    .lst
    > ul
    > li:nth-child(2) {
    display: none;
  }

  .offer_dash_page.rental-offer-page-dt .hide_sm_clmn .lst > ul > li {
    width: calc(100% / 3);
  }

  .offer_dash_page.rental-offer-page-dt
    .hide_sm_clmn
    .lst
    > ul
    > li:nth-child(1) {
    width: 100%;
  }

  .hide_cell_hyp {
    display: none !important;
  }

  .cell_lbl {
    position: absolute;
    top: 5px;
    right: 5px;
    display: block;
  }

  .hide_desktop_col {
    display: block;
  }

  .wide_mobile_img {
    width: 110px !important;
    height: 110px !important;
  }

  .pay_ment_new_pop .pay_address .pay_cntnt_pop_new p:nth-child(2) {
    font-size: 12px;
  }

  .search_nav.cell_search_nav {
    left: 16%;
  }

  .listing_list_c > .outer_list_c > .lst > ul > li.bTn {
    display: none;
  }

  .blockLst.cell_block_cell table tr {
    display: table-row;
    box-shadow: none;
  }

  .blockLst.cell_block_cell table tr > * {
    display: table-cell;
    font-size: 11px;
    text-align: left;
    width: fit-content;
  }

  .cell_status_offer_cell {
    justify-content: flex-start;
  }

  .cell_status_offer_cell .col_ofer_filter {
    flex: unset;
    flex-direction: row;
  }

  .cell_status_offer_cell .col_ofer_filter .sorted_heading {
    align-self: center;
  }

  .blockLst.cell_block_cell thead {
    display: table-row-group;
  }

  .blockLst.cell_block_cell .mini-show-cell {
    display: none;
  }

  .overlay_cell_lnk_all {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
  }

  .show_lbl_com_cell {
    display: inline;
  }

  .ascending_filter .change-view-btn {
    width: 31px;
  }

  .cell_show_map {
    display: flex !important;
    margin-left: 2px !important;
  }

  .cell_show_map .change-view-btn {
    width: 31px !important;
  }

  .ascending_filter {
    margin-left: 2px;
  }

  .justify_center_lbl_cell {
    justify-content: center;
  }

  .hide_cell_divide_line {
    display: none;
  }

  .crud_image_mini_heading h4 {
    margin-bottom: 0 !important;
  }

  .full_wide_price_listing.cell_full_wide_lst_cell li {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .cell_status_offer_cell_end {
    justify-content: flex-end;
  }

  .view_propert_detail .view_flex .property_heading p {
    font-size: 12px;
    width: 193px;
  }

  .view_propert_detail .view_flex .property_heading p em {
    display: block;
  }

  .detail_review_star {
    position: absolute;
    bottom: 47px;
    right: 10px;
    padding: 3px 10px;
    font-size: 12px;
  }

  .cta_cell_cell {
    margin-left: auto;
    margin-top: 10px;
  }

  .show_cell_floor_only {
    display: inline;
  }

  .hide_dektop_dektop {
    display: flex;
  }

  .cmn_filter_btn span {
    display: none;
  }

  .more_filters .cmn_filter_btn {
    padding: 0 5px;
    border-radius: 4px;
  }

  .more_filters .cmn_filter_btn svg {
    margin-right: 0;
  }

  .search_result_page .colL .propert_list.list_view .col .inner .cntnt {
    padding: 15px;
  }

  .propert_list .col .inner .fav_icon {
    width: 20px;
    height: 20px;
    font-size: 13px;
  }
  .termination_costs_flex .full_wide_price_listing li {
    width: 100%;
  }

  .dash_user_dp {
    width: 9rem;
    height: 9rem;
  }
  .dashboard_user_card .dash_user_title p {
    font-size: 15px;
  }
  .dashboard_user_card .dash_user_title h4 {
    font-size: 12px;
  }
  .dashboard_user_card .bTn {
    margin-top: 0;
  }
  .dashboard_user_card .bTn .webBtn {
    height: 28px;
    padding: 0px 1rem;
    font-size: 11px;
    width: unset;
    min-width: unset;
  }
  .blk_toggle_promotion_blk ul li {
    font-size: 12px;
  }

  .new_filter_property span {
    display: block;
  }
  .invoice_toggle.new_invoice_togg > ul {
    justify-content: unset;
  }
  .invoice_toggle.new_invoice_togg > ul > li:first-child {
    width: 63%;
  }
  .promo_abt_icon {
    top: 1.6rem;
    right: 3rem;
  }

  .search_staff_form {
    flex-direction: column;
  }
  .search_staff_form .bTn {
    justify-content: right;
  }
  .result_maintenance_flex .flex .card_col .inner_card .services_count {
    flex-wrap: wrap;
  }
  .result_maintenance_flex .flex .card_col .inner_card {
    flex-direction: column;
  }
  .result_maintenance_flex .flex .card_col .inner_card .info_user {
    max-width: 100%;
    text-align: left;
    margin-bottom: 1rem;
  }
  .result_maintenance_flex .flex .card_col .inner_card .info_user .icon_user {
    margin: 0 0 0.2rem;
  }
  .result_maintenance_flex .flex .card_col .inner_card .detail_user {
    align-self: unset;
    padding-left: 0;
    padding-right: 0;
  }

  .detail_pop_cntnt li {
    flex-direction: column !important;
  }
  .detail_pop_cntnt.flex_flex {
    flex-direction: column !important;
  }
  .property_new_lst_new li {
    width: 100%;
  }
  .detail_pop_outer ._inner {
    padding: 2rem;
  }
  .flterFlexListing .nav-tabs {
    width: 100%;
  }
  .filter_status_offer {
    width: 100%;
  }
}

@media screen and (max-width: 380px) {
  .search_result_page
    .colL
    .propert_list.list_view
    .col.search-explore-grid
    .inner
    .image_slider
    .item {
    padding-bottom: 140%;
  }
}

@media screen and (min-width: 320px) and (max-width: 330px) {
  .search_nav.cell_search_nav {
    width: 220px;
    left: 18%;
  }

  footer .flexRow > .col {
    width: 100%;
  }

  .sorted_filter {
    justify-content: flex-start;
  }

  .ascending_filter .change-view-btn,
  .filter_nav .result_header .sorted_filter .sorting-options .change-view-btn {
    width: 30px;
    padding: 2px 7px;
  }

  .ascending_filter {
    margin-left: 5px;
  }

  .filter_nav .result_header .sorted_filter .sorting-options {
    gap: 5px;
    margin-left: 5px;
  }

  .filter_nav .result_header .sorted_filter .sorted_heading {
    margin-right: 7px;
  }

  .listing_tabs .sorted_filter {
    justify-content: space-between;
  }

  .offer_dash_page .outer_list .lst > ul > li {
    width: calc(100% / 2);
  }

  .nav-tabs > li > a {
    padding: 10px 5px;
  }

  /* --bank account-- */
  .bank_show_listing li {
    width: calc(100% / 1) !important;
  }

  .dash_heading_sec .main_page_heading a,
  .dash_heading_sec h2 {
    font-size: 10px;
  }

  .dash_form_blk form fieldset .cmnBtn {
    flex-wrap: wrap;
    gap: 10px;
  }

  .dash_form_blk form fieldset .cmnBtn .webBtn {
    width: 100%;
  }

  .cardFooter .footerCol {
    flex-direction: column;
    align-items: center;
  }

  ._dropCnt {
    min-width: 230px;
    max-width: 230px;
  }

  .cell_result_header .sorted_filter .ascending_filter {
    display: none;
  }

  .outer_list_c .lst {
    padding: 15px;
  }

  .filter_nav .result_header.cell_result_header {
    max-width: 70%;
  }

  .filter_nav .result_header .sorted_filter .sorted_heading {
    margin-right: 2px;
  }

  .filter_nav .result_header .sorted_filter .sorted_drop_down {
    width: 45%;
  }

  .view_propert_detail .view_flex .property_heading p {
    width: 130px;
  }

  .view_propert_detail .view_flex .property_heading h2 {
    font-size: 21px;
  }
  .invoice_toggle.new_invoice_togg > ul > li:first-child {
    width: 100%;
  }
}

@media screen and (min-width: 411px) and (max-width: 420px) {
}
