/* __________side bar___ */

.side_bar_dash_links ul li {
  display: block;
}

.side_bar_dash_links ul li a {
  display: flex;
  padding: 8px;
  border-radius: 5px;
  margin-bottom: 2px;
  color: #4c4c4c;
}

.side_bar_dash_links ul li.active a {
  background: #ffffff;
  border: 1px solid #eaeef9;
}

.side_bar_dash_links ul li a .small_icon {
  width: 18px;
  align-self: center;
  height: 20px;
  border-radius: 5px;
}

.side_bar_dash_links ul li a .small_icon img {
  filter: brightness(0) invert(0.5);
  -webkit-filter: brightness(0) invert(0.5);
}

.side_bar_dash_links ul li.active a .small_icon img {
  filter: brightness(0) invert(0.3);
  -webkit-filter: brightness(0) invert(0.3);
}

.side_bar_dash_links ul li a span {
  flex: 1;
  margin-left: 10px;
  font-size: 14px;
  align-self: center;
}

.side_bar_dash_links ul li a:hover {
  color: #00acfc;
}

.side_bar_dash_links ul li.active a span {
  font-family: "Poppins SemiBold";
}

/* _____add branches___ */

.small_opt_lbl ul:before {
  right: 23%;
  left: 23%;
}

.align_hald_mid {
  align-self: center;
  padding-top: 5px;
}

.align_mid {
  align-self: center;
}

/* _____branches____ */
.user_dash_tbl.blockLst {
  overflow: unset;
}

.user_dash_tbl.blockLst table tr > *:last-child {
  padding-right: 20px;
}

.user_dash_tbl.blockLst table tr > * {
  font-size: 14px;
}

.flex_action_user_dash {
  position: relative;
  height: 100%;
}

.flex_action_user_dash .action_drop {
  position: absolute;
  top: 0px;
  right: 30px;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex_action_user_dash .action_drop .action_dots {
  border: 1px solid #ddd;
}

.flex_action_user_dash .action_drop:hover ._dropCnt,
.action_drop_lease .action_drop:hover ._dropCnt {
  opacity: 1;
  visibility: visible;
}

.flex_action_user_dash .action_drop ._dropCnt {
  min-width: 110px;
  max-width: 210px;
}

/* _______________Floor Plans________ */

.medium_opt_lbl ul:before {
  right: 15%;
  left: 15%;
}

.small_web_btn {
  background: #f1f1f1;
  padding: 3px 14px;
  border-radius: 50px;
  font-size: 14px;
  border: 1px solid #e9e9e9;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.small_web_btn i {
  align-self: center;
}

/* _____Media_____ */

.heighlight_fieldset {
  width: 900px;
  background-color: #fff;
  padding: 20px;
  margin: 0px auto;
  border: 1px solid #e9f8ff;
}

.heighlight_fieldset .cmnBtn {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

/* ___employee___ */
.cmn_user_form .form_row > * {
  margin-bottom: 15px;
}

.cmn_user_form .form_row h6 {
  opacity: 0.8;
  font-size: 13px;
}

.cmn_user_form .form_row .lblBtn label {
  font-size: 13px;
  opacity: 0.8;
  font-family: "Poppins Regular";
}

.user_dash_form_heading {
  margin-bottom: 0;
  font-family: "Poppins Medium";
}

/* _________expenses_flex________ */
.expenses_flex {
  display: flex;
  flex-wrap: wrap;
  margin: -10px;
  justify-content: center;
}

.expenses_flex .col {
  width: calc(100% / 3) !important;
  padding: 10px;
}

.sm_btn_lst {
  justify-content: flex-end;
}

.sm_btn_lst a {
  font-size: 13px;
  height: 32px;
  border-radius: 2px;
  padding: 3px 10px !important;
  min-width: 80px;
}

.sm_btn_lst a.redBtn {
  background: red;
}

/* _listing_list_c_______ */
.outer_list_c ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.outer_list_c .lst {
  position: relative;
  background: #fff;
  padding: 20px;
  border-radius: 7px;
  -webkit-box-shadow: 0 5px 20px rgb(18 15 56 / 7%);
  box-shadow: 0 5px 20px rgb(18 15 56 / 7%);
  /* overflow: hidden; */
  max-width: 100%;
  margin: 0px auto;
  margin-bottom: 20px;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.outer_list_c .lst > .listing_link {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.listing_list_c ul li .action_drop {
  z-index: 9999;
}

.listing_list_c ul li {
  padding: 0 10px;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
  align-self: center;
  font-size: 14px;
}

.listing_list_c > .outer_list_c > .lst > ul > li:nth-child(1) {
  width: 12%;
}

.listing_list_c > .outer_list_c > .lst > ul > li:nth-child(2) {
  width: 23%;
}

.listing_list_c > .outer_list_c > .lst > ul > li:nth-child(3) {
  width: 15%;
}

.listing_list_c > .outer_list_c > .lst > ul > li:nth-child(4) {
  width: 15%;
}

.listing_list_c > .outer_list_c > .lst > ul > li:nth-child(5) {
  width: 15%;
}

.listing_list_c > .outer_list_c > .lst > ul > li:nth-child(6) {
  width: 10%;
}

.listing_list_c > .outer_list_c > .lst > ul > li:nth-child(7) {
  width: 10%;
}

/* __listing_add_c___ */
.listing_add_c form fieldset {
  max-width: 460px;
}

.dim_btn {
  margin: 0;
  background: #f1f1f1;
  width: 100%;
  text-align: center;
  padding: 10px 15px;
  border-radius: 3px;
  cursor: pointer;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.dim_btn.active,
.dim_btn:hover {
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  background: #ff6c40;
  color: #fff;
}

.listing_c_flex_c {
  background-color: #fbfbfb;
  padding: 20px;
  border-radius: 3px;
  border: 1px solid #e9f8ff;
}

.listing_c_flex .col {
  width: 50%;
  padding: 10px;
}

.listing_c_flex_c .bTn .mini_web_btn {
  width: 30px;
  height: 30px;
}

.listing_c_flex {
  background-color: #fff;
  /* border-radius: 3px; */
  border: 1px solid #e9f8ff;
}

.listing_c_flex.head_flex {
  border-bottom: none;
}

.listing_c_flex.head_flex .col h6 {
  margin-bottom: 2px;
}

.listing_c_flex .col .form_blk {
  margin-bottom: 0;
}

.add_more_term_c {
  position: relative;
}

.add_more_term_c .listing_c_flex {
  border-top: none;
}

.add_more_term_c .listing_c_flex .col:last-child {
  padding-right: 10px;
}

.add_more_term_c .closeBtn {
  position: absolute;
  top: 2px;
  right: 2px;
  width: 21px;
  height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  color: #778ca3;
  font-size: 12px;
  cursor: pointer;
  line-height: 1;
  text-align: center;
  border: 1px solid #aaa;
  border-radius: 75%;
  padding-top: 1px;
  padding-left: 1px;
  z-index: 99;
}

.add_more_term_c .closeBtn:before {
  content: "\2715";
}

.add_listing_c_flex_c {
  margin-top: 15px;
}

.expenses_toggle .invoice_toggle ul li:nth-child(3) {
  flex: 1;
}

.promotion_lst > ul > li {
  width: calc(80% / 4) !important;
}

.promotion_lst > ul > li:last-child,
.promotion_lst > ul > li:nth-child(5) {
  width: 10% !important;
}

.listing_c_flex_4 .col {
  width: 25% !important;
}
.listing_c_flex_4.promotion_c_flex .col {
  width: 25%;
}
.listing_c_flex_4.promotion_c_flex .col.promotion_discount {
  /* width: 50% !important; */
  position: relative;
}
.listing_c_flex_4.promotion_c_flex .col.promotion_discount select {
  position: absolute;
  top: unset;
  bottom: 4px;
  right: 10px;
  border: none;
  width: 46px;
  height: 40px;
  background: #fefefe;
  border-left: 1px solid #eee;
  padding-left: 5px;
}

/* _____________contacts___________ */
.contacts_flex_com {
  margin: -10px;
}

.contacts_flex_com > * {
  width: calc(100% / 3);
  padding: 10px;
  align-self: center;
}

.contacts_flex_com .week_col {
  opacity: 0.8;
  font-size: 13px;
}

.info_heading_confirm_com h5 {
  font-family: "Poppins SemiBold";
  color: #ff6c40;
}

/* ____credits_____ */
.credits_flex_com {
  justify-content: flex-start !important;
}

.listing_list_c_credit_com .lst > ul > li {
  /* align-self: center; */
}

/* ___search property detail___ */

.bg_com_search {
  background-color: #fff;
  padding: 20px;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}

.bg_com_search .view_propert_detail {
  border-bottom: 1px solid #dddddd52;
  padding-bottom: 20px;
}

.bg_com_search .view_property_flex .colL {
  margin-top: 20px;
}

.bg_com_search .view_property_gallery .imgGallery .col {
  width: calc(100% / 4);
}

.search_expense_com h5 {
  font-family: "Poppins SemiBold";
  margin-bottom: 15px;
  font-size: 15px;
}

.search_expense_com .inner {
  background: #fbfbfb;
  border: 1px solid #e9f8ff;
}

.search_expense_com .inner .balance_lst {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;
  padding: 10px;
}

.search_expense_com .inner .balance_lst:not(:last-child) {
  border-bottom: 1px solid #e9f8ff;
}

.search_expense_com .colL {
  margin-top: 0 !important;
}

.search_toggle_com.invoice_toggle_outer .invoice_toggle > ul > li {
  flex: 1;
}
.search_toggle_com.invoice_toggle_outer.new_list_design .invoice_toggle > ul > li {
  flex: unset;
}
.search_toggle_com.invoice_toggle_outer.new_list_design .invoice_toggle > ul > li:nth-child(2){
  flex: unset;
}
.search_toggle_com.invoice_toggle_outer.new_list_design .invoice_toggle > ul > li:last-child{
  flex: .2;
}
.search_toggle_com.invoice_toggle_outer
  .invoice_toggle
  > ul
  > li
  .specific_flex_span {
  display: flex;
  gap: 10px;
}

.search_toggle_com.invoice_toggle_outer .invoice_toggle {
  border: 1px solid #dcf1fb;
}

.property_form_commercial_flex {
  max-width: 400px;
  margin: 0px auto;
}

.promotion_icon {
  position: relative;
}

.promotion_icon:before {
  position: absolute;
  top: 0;
  right: 35px;
  bottom: 0;
  width: 200px;
  padding: 10px 15px 10px;
  content: attr(data-description);
  background: #fff;
  white-space: break-spaces;
  height: max-content;
  box-shadow: 0px 2px 8px 3px #f7f5f5;
  color: #000;
  font-size: 11px;
  font-family: "Poppins Regular";
  text-align: left;
  border-radius: 4px;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: all ease 0.5s;
}

.promotion_icon:hover:before {
  opacity: 1;
  right: 46px;
  visibility: visible;
  pointer-events: unset;
  transition: all ease 0.5s;
}

.promotion_icon img {
  filter: brightness(0) invert(1);
  padding: 0.4rem;
}

.lease_header_address > .flex {
  justify-content: space-between;
  align-items: center;
}

.lease_header_address .colR > .flex {
  align-items: center;
  gap: 10px;
}

.lease_header_address .colR h5 {
  margin: 0;
}

.promotion_breadcrumb img {
  width: 20px;
}

.promotion_breadcrumb {
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
}
.promotion_breadcrumb .hover_desc{
  position: absolute;
  top: 41px;
  padding: 1rem;
  background-color: #ffffff;
  border-radius: 5px;
  border: 1px solid #e9f8ff;
  opacity: 0;
  pointer-events: none;
  transition: all 0.5s ease;
}
.promotion_breadcrumb:hover .hover_desc{
  top: 31px;
  opacity: 1;
  pointer-events: unset;
  transition: all 0.5s ease;
}

.promotion_ul_flex{
  display: block!important;
}

.list_promotion_toggle_new .ul_list_promotion_toggle_new{
  position: relative;
  cursor: pointer;
  padding-right: 6rem;
}
.list_promotion_toggle_new .ul_list_promotion_toggle_new:after{
  content: "\f107";
  position: absolute;
  top: 0px;
  right: 20px;
  font-size: 20px;
  font-family: "FontAwesome";
  font-weight: bold;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.blk_toggle_promotion_blk{
  padding-top: 2rem;
  border-top: 1px solid #ddd;
  margin-top: 2rem;
  display: none;
}
.blk_toggle_promotion_blk.active{
  display: block;
}
.blk_toggle_promotion_blk ul{
  justify-content: unset;
  gap: 1rem;
}
.blk_toggle_promotion_blk ul li{
  flex: 1;
  padding: 0;
}
.blk_toggle_promotion_blk ul:not(:first-child) , .blk_toggle_promotion_blk ul:not(:last-child){
margin-bottom: 1rem;
}
.blk_toggle_promotion_blk .btnBlk{
  gap: 1rem;
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
}

.invoice_toggle.new_invoice_togg > ul{
  justify-content: unset;
  gap: unset;
  column-gap: 1rem;
}
.invoice_toggle.new_invoice_togg > ul > li{
  width: 17%;
} 

.invoice_toggle.new_invoice_togg > ul > li:nth-child(2){
  width: 19%;
}
.invoice_toggle.new_invoice_togg > ul > li:nth-child(3){
  width: 22%;
}
.invoice_toggle.new_invoice_togg > ul > li:last-child{
  flex: unset!important;
}

.invoice_toggle.new_invoice_togg > ul > li:first-child{
  width: 30%;
}

.invoice_toggle.new_invoice_togg > ul > li:nth-child(4) > div{
  max-width: 15rem;
}

._new_lst_wide{
  width: 5.9rem;
}

.promo_abt_icon{
  position: absolute;
  right: 9rem;
  width: fit-content;
  width: unset !important;
}