:root {
  --chevron: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="300px" height="300px" fill="%230f0f0f" viewBox="-155 247 300 300" style="enable-background:new -155 247 300 300;"><polygon points="78.6356201,306.8178101 -5.0166931,390.4367371 -88.6356277,306.8178101 -137, 355.1821899 -5.0166931,487.1821899 127,355.1821899 "/></svg>');
  --tick: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="300px" height="300px" fill="%23fff" viewBox="0 0 300 300" style="enable-background:new 0 0 300 300;" xml:space="preserve"><polygon points="115.7434006,270.8831787 0,173.503006 21.3676376,148.106369 111.6297684,224.0477905 274.5331421,29.1168175 300,50.3998222"/></svg>');
  --question: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="300px" height="300px" fill="%230f0f0f" viewBox="-155 247 300 300" style="enable-background:new -155 247 300 300;"><path className="st0" d="M-5,265c-72.9016113,0-132,59.0983887-132,132S-77.9016113,529-5,529s132-59.0984497,132-132 S67.9016113,265-5,265z M-5.47052,468.3762817c-7.4328003,0-13.4794922-6.0470581-13.4794922-13.4790649 c0-7.4320679,6.0466919-13.479126,13.4794922-13.479126c7.432373,0,13.4794922,6.0470581,13.4794922,13.479126 C8.0089722,462.3292236,1.961853,468.3762817-5.47052,468.3762817z M34.1941528,371.9846802 c-2.6166992,12.1121216-11.4063721,21.4836426-19.1619263,29.7519531c-0.956665,1.0199585-1.8609619,1.9437866-2.7167969,2.8183594 c-3.1091309,3.1724243-4.4174805,4.5682983-5.5913696,7.2835083c-1.3748169,3.1787109-1.890686,6.8474121-1.5335083,10.9046021 c0.4846191,5.5038452-3.5842896,10.3590088-9.0881348,10.8435669c-0.298584,0.0258179-0.5952148,0.0391235-0.8890381,0.0391235 c-5.1287231,0-9.4957886-3.9219971-9.9542236-9.1272583c-0.6522217-7.40625,0.3908081-14.3372803,3.0997314-20.602417 c2.6948853-6.2315063,6.2381592-9.8479004,9.6646118-13.3450317c0.7612915-0.7765503,1.5654907-1.5960083,2.4151001-2.5018311 c6.2698364-6.6848755,12.7529907-13.5967407,14.1989136-20.2897949c1.2380371-5.7316895-0.3782959-13.9069824-5.8634033-18.4417114 c-6.9536743-5.7469482-18.0630493-4.09198-24.7865601,0.8272705c-10.0702515,7.3675537-8.9369507,21.3914185-8.9244385,21.5324707 c0.5568848,5.4968872-3.4495239,10.3880005-8.9467163,10.9448853c-5.5003662,0.5560913-10.406311-3.4647827-10.9631958-8.9619751 c-0.953125-9.4090576,1.5588379-28.3513794,17.0200195-39.6635132c13.0418091-9.5411377,34.1811523-12.6392822,49.3492432-0.1000366 C33.7728882,344.0241089,36.7139893,360.3235474,34.1941528,371.9846802z"/></svg>');
}

.dashboard {
  padding: 30px 0px;
}

.main_dashboard {
  background: #fbfbfb;
  position: relative;

  height: 90vh;
  overflow: auto;
}

.emailDashboard {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.emailDashboard .small_popup ._inner {
  max-width: 600px;
  margin: 10px;
}

.floor_popup {
  z-index: 40;
  position: relative;
}

.dash_intro_flex .colL {
  width: 20%;
  padding-right: 10px;
}

.dash_intro_flex .colR {
  width: 80%;
  padding-left: 20px;
}

.change_layout .dashboard_user_card {
  /* display: flex;
  gap: 2rem; */
  margin: 0 auto 2rem;
  /* max-width: 40rem; */
  width: fit-content;
}

/* .change_layout .dashboard_user_card .dash_user_dp {
  margin: 0;
}
.change_layout .dashboard_user_card .dash_user_title {
  flex: 1;
  align-self: center;
  text-align: left;
}
.change_layout .dashboard_user_card .dash_user_title .bTn {
  justify-content: flex-start;
} */
.change_layout .tile_flex {
  max-width: 70rem;
  margin: 0 auto;
  justify-content: center;
}

.change_layout .tile_flex .col {
  width: 50%;
}

.dashboard_user_card {
  background: #ffffff;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  padding: 30px;
  /* text-align: center; */
  border: 1px solid #eaeef9;
  display: flex;
  flex-wrap: wrap;
}

.dash_user_dp {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  /* margin: 0px auto 20px; */
  /* -webkit-box-shadow: 0 20px 30px 0 rgb(89 181 210 / 31%);
    box-shadow: 0 20px 30px 0 rgb(89 181 210 / 31%); */
  border: 1px solid #eaeef9;
}

.dash_user_dp img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.dashboard_user_card .dash_user_title {
  flex: 1;
  padding-left: 2rem;
  align-self: center;
}

.dashboard_user_card .dash_user_title h4 {
  margin-bottom: 0px;
  font-size: 15px;
  color: #ff6c40;
  font-family: "Poppins SemiBold";
}

.dashboard_user_card .dash_user_title p {
  font-size: 20px;
  margin-bottom: 3px;
}

.dashboard_user_card .dash_user_title h6 {
  margin-bottom: 0;
  font-size: 12px;
  color: #ff6c40;
  font-family: "Poppins Medium";
}

.dashboard_user_card .bTn {
  /* justify-content: center; */
  margin-top: 10px;
}

.dashboard_user_card .bTn .webBtn {
  height: 40px;
  padding: 0px;
}

/* ===.tile_flex == */
.tile_flex {
  margin: -10px;
}

.tile_flex .col {
  width: calc(100% / 4);
  padding: 10px;
}

.tile_flex .col .inner {
  background: #ffffff;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  padding: 26px 10px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  border: 1px solid #eaeef9;
  text-align: center;
  flex-direction: column-reverse;
  gap: 12px;
}

.tile_flex .col .inner .tile_cntnt {
  flex: 1;
  margin-right: 10px;
  align-self: center;
}

.tile_flex .col .inner .tile_icon {
  width: 50px;
  height: 50px;
  overflow: hidden;
  /* background: #fea28814; */
  border-radius: 7px;
  /* padding: 5px; */
  align-self: center;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
  /* border: 1px solid #fe977a42; */
}

.tile_flex .col .inner .tile_icon img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  object-position: center;
}

.tile_flex .col .inner .tile_cntnt h4 {
  margin-bottom: 5px;
  font-family: "Poppins SemiBold";
  opacity: 0.8;
  font-size: 16px;
}

.tile_flex .col .inner .tile_cntnt h2 {
  margin-bottom: 0;
  color: #ff6c40;
  font-size: 23px;
  font-family: "Poppins Bold";
}

.chart_blk {
  margin: 0 -10px;
}

.chart_blk .col {
  width: calc(100% / 3);
  padding: 30px 10px;
}

.chart_blk .col .outer_chart_blk {
  background-color: #fff;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  border: 1px solid #eaeef9;
}

.chart_blk .col .outer_chart_blk h4 {
  padding: 10px 15px;
  margin-bottom: 0;
  border-bottom: 1px solid #eaeef9;
  font-size: 16px;
}

.chart_blk .col .outer_chart_blk .inner_chart {
  padding: 15px;
}

.chart_blk .col .outer_chart_blk .inner_chart ul li {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.chart_blk .col .outer_chart_blk .inner_chart ul li:not(:last-child) {
  margin-bottom: 10px;
}

.chart_blk .col .outer_chart_blk .inner_chart ul li p {
  margin-bottom: 0;
  flex: 1;
  margin-right: 10px;
  font-size: 13px;
  font-family: "Poppins Medium";
  line-height: 1.4;
}

.chart_blk .col .outer_chart_blk .inner_chart ul li p span {
  display: block;
  font-size: 10px;
  color: rgb(141 141 141);
  font-family: "Poppins Medium";
}

.chart_blk .col .outer_chart_blk .inner_chart ul li .price {
  align-self: center;
  font-size: 14px;
}

/* ___dashpages_heading___ */
.dashpages_heading h4 {
  display: flex;
  gap: 8px;
}

.dashpages_heading h4 * {
  font-size: 16px;
}

.dashpages_heading h4 span,
.dashpages_heading h4 a {
  color: #9d9d9d;
}

.dashpages_heading h4 p {
  color: #00acfc;
}

/* ===============dash_heading_sec================ */
.dash_heading_sec {
  display: flex;
  margin: 0px 0px 20px;
  border: 1px solid #e9f8ff;
  padding: 10px;
  background: #fff;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.pagination {
  margin-top: 40px;
}

.pagination > li > a.active {
  background: #119df0;
  color: #fff;
  border: 1px solid #119df0;
}

.dash_heading_sec h2 {
  margin-bottom: 0;
  font-size: 16px;
  font-family: "Poppins Bold";
  align-self: center;
}

.dash_heading_sec .main_page_heading {
  flex: 1;
  margin-right: 20px;
  align-self: center;
  display: flex;
  gap: 10px;
}

.dash_heading_sec .main_page_heading a {
  font-size: 16px;
  color: #9d9d9d;
}

.dash_heading_sec .main_page_heading span {
  color: #9d9d9d;
}

.dash_heading_sec > a {
  color: #0071bd;
  font-size: 16px;
  font-family: "Poppins SemiBold";
  align-self: center;
}

.dash_heading_sec .bTn {
  align-self: center;
}

.dash_heading_sec .search_form {
  width: 260px;
  margin-right: 10px;
}

.dash_heading_sec .search_form .txtBox {
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  padding-left: 20px;
  padding-right: 50px;
}

.dash_heading_sec .search_form button {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 46px;
  padding: 0;
  background-color: transparent;
  color: #ff6c40;
  box-shadow: none;
  font-size: 18px;
}

.dash_heading_sec .search_form button i {
  margin-right: 0px;
}

/* __page_body__ */

.page_body .listing_tabs .nav-tabs {
  flex: 1;
  margin-right: 20px;
  align-self: center;
  border-bottom: none;
}

.page_body .listing_tabs .sorted_filter {
  align-self: center;
}

.page_body .listing_tabs .sorted_filter .sorted_heading {
  align-self: center;
  margin-right: 10px;
  font-size: 13px;
  font-family: "Poppins Medium";
}

.page_body .listing_tabs .sorted_filter .sorted_heading i {
  opacity: 0.8;
}

.page_body .listing_tabs .sorted_filter .sorting-options {
  display: flex;
  gap: 10px;
  align-self: center;
  margin-left: 10px;
}

.page_body .listing_tabs .sorted_filter .sorting-options .change-view-btn {
  color: #121b22 !important;
  border: 1px solid #00acfc;
  border-radius: 2px;
  background: transparent;
  padding: 2px 9px;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.page_body .listing_tabs .sorted_filter .sorting-options .active-view-btn,
.page_body
  .listing_tabs
  .sorted_filter
  .sorting-options
  .change-view-btn:hover {
  background: #00acfc !important;
  color: #fff !important;
  padding: 2px 9px;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

/* ____tabs___ */

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:focus,
.nav-tabs > li.active > a:hover {
  color: #ff6c40;
  cursor: default;
  background-color: #fff0;
  border: 1px solid #ddd0;
  border-bottom-color: transparent;
}

.nav-tabs > li > a {
  text-transform: uppercase;
  font-family: "Poppins Medium";
  font-size: 14px;
  color: #555;
}

.nav-tabs > li > a:hover {
  border-color: #eee0 #eee0 #ddd0;
  color: #ff6c40;
}

.nav > li > a:focus,
.nav > li > a:hover {
  text-decoration: none;
  background-color: #eee0;
}

/* ________________dash_property_listing___________________ */
.grid_dash_property_listing {
  display: flex;
  flex-wrap: wrap;
}

.grid_dash_property_listing .col {
  width: calc(100% / 4);
  padding: 10px;
}

.grid_dash_property_listing {
  margin: -10px;
}

.dash_property_listing {
  margin-top: 10px !important;
}

.dash_property_listing .col .inner {
  border-radius: 10px;
  background: #fff;
  overflow: hidden;
  border: 1px solid #ddf5fd;
  position: relative;
}

.dash_property_listing .col .inner .cntnt {
  padding: 10px 15px 10px;
}

.dash_property_listing .col .inner .image {
  width: 100%;
  position: relative;
  padding-bottom: 60%;
  overflow: hidden;
}

.dash_property_listing .col .inner .image img {
  position: absolute;
  height: 100%;
  object-fit: cover;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.dash_property_listing .col .inner .image .show_lbl_overlay {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #ff6c40;
  border-radius: 50px;
  padding: 3px 10px;
  font-size: 12px;
  color: #fff;
}

.dash_property_listing .col .inner .cntnt ul.flex {
  gap: 20px;
  margin: 5px 0px;
}

.dash_property_listing .col .inner .cntnt .flex li {
  font-size: 14px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  align-self: center;
}

.dash_property_listing .col .inner .cntnt .flex li span {
  flex: 1;
  color: #848484;
}

.dash_property_listing .col .inner .price_flex .price {
  flex: 1;
}

.dash_property_listing .col .inner .price_flex p {
  margin-bottom: 0;
  align-self: center;
  font-size: 11px;
  opacity: 0.6;
}

.dash_property_listing .col .inner .cntnt .flex li:not(:first-child):before {
  position: absolute;
  content: "";
  left: -10px;
  top: 1px;
  width: 1px;
  height: 20px;
  background-color: #8080808a;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
}

.dash_property_listing .col .inner .cntnt h3 {
  font-size: 19px;
  color: #000000bd;
  font-family: "Poppins SemiBold";
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin-bottom: 5px;
}

/* ______card footer___ */
.cardFooter {
  /* background: #fbfbfb9c; */
  border-top: 1px solid #1e62e917;
}

.cardFooter > .flex {
  justify-content: center;
}

.cardFooter .footerCol {
  /* width: calc(100% / 3); */
  flex: 1;
  padding: 2px 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  color: #505050;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.cardFooter .footerCol:first-child {
  border-right: 1px solid #1e62e917;
}

.cardFooter .footerCol span {
  font-size: 13px;
  margin-right: 5px;
}

.cardFooter .footerCol h5 {
  color: #505050;
  font-size: 12px;
  margin-bottom: 0px;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  /* flex: 1; */
  font-family: "Poppins Medium";
}

.cardFooter .footerCol:hover {
  color: #ff6c40;
}

.cardFooter .footerCol:hover h5 {
  color: #ff6c40;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.cardFooter .footerCol:last-child {
  border-left: 1px solid #1e62e917;
}
.cardFooter.border_left_none .footerCol:last-child {
  border-left: none;
}
/* ______action_drop___ */
.action_drop {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: unset;
}

.action_dots {
  width: 30px;
  height: 30px;
  background: #fff;
  border-radius: 50px;
  padding: 5px;
}

.action_drop ._dropCnt {
  right: 0;
  left: auto;
  min-width: 210px;
  max-width: 277px;
  margin-top: 0;
}

.action_drop ._dropCnt li a {
  font-size: 13px;
  padding: 2px 15px;
}

.action_drop ._dropCnt li a:hover {
  background: none;
}

/* ____list_dash_property_listing___ */
.list_dash_property_listing {
  display: flex;
  flex-wrap: wrap;
  margin: -10px;
}

.list_dash_property_listing .col {
  width: 50%;
  padding: 10px;
}

.list_dash_property_listing .col .inner {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  overflow: unset;
}

.list_dash_property_listing .col .inner .image {
  width: 180px;
  padding-bottom: 20%;
}

.list_dash_property_listing .col .inner .action_drop {
  z-index: 9999;
}

.list_dash_property_listing .col .inner .link {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.grid_dash_property_listing .col .inner .link {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.list_dash_property_listing .col .inner .cntnt {
  padding: 0px 55px 0px 30px;
  flex: 1;
}

.list_dash_property_listing .action_dots {
  border: 1px solid #ddd;
}

.list_dash_property_listing .action_drop:hover ._dropCnt {
  visibility: visible;
  opacity: 1;
}

.list_dash_property_listing .cardFooter {
  position: absolute;
  bottom: 10px;
  right: -20px;
  border-top: none;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  opacity: 0;
  visibility: hidden;
}

.list_dash_property_listing .col .inner:hover .cardFooter {
  right: 0;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  opacity: 1;
  visibility: visible;
}

.list_dash_property_listing .cardFooter .flex {
  flex-direction: column;
}

.list_dash_property_listing .cardFooter .flex a {
  width: 100%;
  padding: 2px 5px;
}

.list_dash_property_listing .cardFooter .footerCol:first-child {
  border-right: none;
}

.list_dash_property_listing .cardFooter .footerCol:last-child {
  border-left: none;
}

.list_dash_property_listing .cardFooter .flex a h5 {
  display: none;
}

.list_dash_property_listing .cardFooter .flex a span {
  width: 30px;
  height: 30px;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  border: 1px solid #dddddde6;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.list_dash_property_listing .cardFooter .flex a span:hover {
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  border: 1px solid #ff6c40;
  background: #ff6c40;
  color: #fff;
}

/* ________add property____ */

.option_lbl {
  width: 100%;
  display: flex;
  justify-content: center;
}

.option_lbl ul {
  position: relative;
  display: flex;
}

.option_lbl ul:before {
  position: absolute;
  content: "";
  bottom: -17px;
  right: 0%;
  left: 0%;
  height: 3px;
  background-color: #e1e4f1;
}

.option_lbl ul li {
  color: #9dacc1;
  position: relative;
  padding: 0px 15px;
  width: 160px;
  text-align: center;
  font-size: 12px;
  font-family: "Poppins Medium";
}

.option_lbl ul li:after {
  position: absolute;
  content: "";
  bottom: -24px;
  width: 16px;
  height: 16px;
  background-color: #00acfc;
  left: 45%;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  border: 3px solid #fff;
}

.option_lbl ul li.active {
  color: #ff6c40;
  font-size: 14px;
}

.option_lbl ul li.active:before,
.option_lbl ul li.active:after {
  background-color: #ff6c40;
}

.option_lbl.profileTabs ul li:after,
.option_lbl.profileTabs ul:before {
  display: none;
}

.option_lbl.profileTabs ul li {
  width: auto;
}

.dash_form_blk {
  margin-top: 60px;
}

.dash_form_blk form fieldset:not(:first-child) {
  /* display: none; */
}

.dash_form_blk form fieldset {
  width: 900px;
  background-color: #fff;
  padding: 20px;
  margin: 0px auto;
  border: 1px solid #e9f8ff;
}

.dash_form_blk form fieldset .form_blk {
  margin-bottom: 15px;
  position: relative;
}

.dash_form_blk form fieldset .form_blk > div {
  width: 100%;
}

.dash_form_blk form fieldset .form_blk > div > div,
.dash_form_blk form .form_blk > div > div,
.popup form .form_blk > div > div {
  min-height: 46px;
  border: 1px solid #eee;
  border-radius: 5px;
  overflow: hidden;

  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.dash_form_blk form fieldset .form_blk .add_more_prop_imgs .bTn {
  border: none;
  background: none;
}

.dash_form_blk form fieldset .form_blk .add_more_prop_imgs .bTn a {
  box-shadow: none;
}

.dash_form_blk form fieldset .form_blk .add_more_prop_imgs .bTn:hover,
.dash_form_blk form fieldset .form_blk .add_more_prop_imgs .bTn:focus {
  border: none !important;
}

.react-datepicker__tab-loop__end,
.react-datepicker-popper,
.react-datepicker__tab-loop__start {
  border: none !important;
  min-height: unset !important;
}

.dash_form_blk form fieldset .form_blk > div > div input {
  border: none;
}

.dash_form_blk
  form
  fieldset
  .form_blk
  .react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle {
  margin-left: -10px !important;
}

/* .dash_form_blk form fieldset .form_blk > div > div:focus,
.dash_form_blk form fieldset .form_blk > div > div:hover {
  border: 1px solid #eee !important;
} */

.dash_form_blk form fieldset .form_blk.static_form_blk {
}

.dash_form_blk form fieldset .form_blk > div > div.outer_image_blk_col,
.dash_form_blk form fieldset .form_blk > div > div.inner_curd {
  border: none !important;
  background: none;
}

.dash_form_blk form fieldset .form_blk > div > div.inner_curd input {
  border: 1px solid #eee;
}

.dash_form_blk form fieldset .form_blk > div > div.crosBtn {
  min-height: 20px;
  border-radius: 75%;
  -webkit-border-radius: 75%;
  -moz-border-radius: 75%;
  -ms-border-radius: 75%;
  -o-border-radius: 75%;
  font-size: 12px;
}

.dash_form_blk form fieldset .form_blk.static_form_blk .txtBox {
  background-color: #eee;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.dash_form_blk form fieldset .form_blk.edit_field {
  position: relative;
}

.dash_form_blk form fieldset .form_blk.edit_field input {
  padding-right: 40px;
  overflow: hidden;
}

.dash_form_blk form fieldset .form_blk.edit_field span {
  position: absolute;
  right: 0;
  top: 13px;
  right: 10px;
  font-size: 12px;
  color: #358fff;
  cursor: pointer;
}

.small_flex_form .address_show p {
  flex: 1;
  margin-right: 20px;
  margin-bottom: 0;
  align-self: center;
}

.emptyBtn {
  background: #eeeeee;
  color: #afafaf;
}

.dash_form_blk form fieldset .cmnBtn {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.dash_form_blk form fieldset label {
  font-size: 13px;
  opacity: 0.8;
  font-family: "Poppins Regular";
}

.dash_form_blk form fieldset .radioBtns label {
  position: relative;
}

.dash_form_blk form fieldset .radioBtns input {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
}

.hide_blk_check {
  display: none;
}

.highlightes_text {
  background: #fbfbfb;
  padding: 5px;
  font-family: "Poppins SemiBold";
  color: #ff6c40;
}

.dash_form_blk form fieldset h6 {
  opacity: 0.8;
  font-size: 13px;
}

/* ___image_upload_blk___ */
.outer_image_blk_col {
  margin: -10px;
  justify-content: center;
  position: absolute;
  top: 12px;
  right: 20px;
}

.outer_image_blk_col .image_blk_col {
  width: calc(100% / 4);
  padding: 10px;
}

.outer_image_blk_col .image_blk_col .image_upload_blk {
  text-align: center;
  border: 1px solid #efefef;
  position: relative;
  /* height: 180px; */
  width: fit-content;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
  background: #fbfbfb;
  overflow: hidden;
  padding: 5px 10px;
  border-radius: 5px;
  gap: 10px;
}

.outer_image_blk_col .image_blk_col .image_upload_blk:hover {
  background-size: 30px 30px;
  background-image: -webkit-linear-gradient(
    135deg,
    #f6f6f6 25%,
    transparent 25%,
    transparent 50%,
    #f6f6f6 50%,
    #f6f6f6 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    -45deg,
    #f6f6f6 25%,
    transparent 25%,
    transparent 50%,
    #f6f6f6 50%,
    #f6f6f6 75%,
    transparent 75%,
    transparent
  );
  -webkit-animation: stripes 2s linear infinite;
  animation: stripes 2s linear infinite;
}

@-webkit-keyframes stripes {
  from {
    background-position: 0 0;
  }

  to {
    background-position: 60px 30px;
  }
}

@keyframes stripes {
  from {
    background-position: 0 0;
  }

  to {
    background-position: 60px 30px;
  }
}

.outer_image_blk_col .image_blk_col .image_upload_blk .small_upload_icon {
  width: 24px;
  height: 24px;
  margin: 0px auto;
  /* margin-bottom: 10px; */
  align-self: center;
}

.outer_image_blk_col .image_blk_col .image_upload_blk h3 {
  font-size: 14px;
  margin-bottom: 0;
}

.outer_image_blk_col
  .image_blk_col
  .image_upload_blk
  .uploaded_thumbnail_image {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.outer_image_blk_col
  .image_blk_col
  .image_upload_blk
  .uploaded_thumbnail_image
  img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.thumbnail_close_btn {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  color: #66615b;
  font-size: 14px;
  cursor: pointer;
  line-height: 1;
  text-align: center;
  border: 1px solid #ababab;
  border-radius: 75%;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.thumbnail_close_btn:before {
  content: "\2715";
}

.thumbnail_close_btn:hover {
  opacity: 0.7;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.gap_line {
  margin: 20px 0px;
  height: 1px;
  width: 100%;
}

.add_more_gallery_dash_btn {
  justify-content: center;
  margin-top: 20px;
}

.more_image_view {
  width: 95px;
  height: 95px;
  overflow: hidden;
}

.more_image_view img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* ____view_propert_detail___ */
.view_propert_detail .view_flex .property_heading {
  flex: 1;
  margin-right: 20px;
  align-self: center;
}

.view_propert_detail .view_flex .property_heading h2 {
  color: #000;
  margin-bottom: 5px;
}

.view_propert_detail .view_flex .property_heading p {
  color: #00acfc;
}

.view_propert_detail .view_flex .view_actions {
  align-self: center;
}

.view_propert_detail .view_flex .view_actions > ul {
  display: flex;
  flex-wrap: wrap;
}

.view_propert_detail .view_flex .view_actions > ul > li {
  padding-left: 10px;
  align-self: center;
}

.view_propert_detail .view_flex .view_actions > ul > li .cmn_action_icon {
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ffc8b7;
  display: flex;
  justify-content: center;
  align-items: center;
}

.view_propert_detail .view_flex .view_actions > ul > li .cmn_action_icon i {
  font-size: 20px;
  color: #ff6c40;
}

.view_propert_detail .view_flex .view_actions ._dropCnt {
  right: 0;
  left: auto;
}

.view_propert_detail .view_flex .view_actions ._dropCnt.active {
  margin-top: 0;
}

.view_propert_detail .view_flex .view_actions ._dropCnt .bg_heighlight {
  border-bottom: 1px solid #ddd;
}

.view_propert_detail .view_flex .view_actions ._dropCnt .bg_heighlight h6 {
  margin-bottom: 5px;
}

.view_propert_detail .view_flex .view_actions ._dropCnt .dropLst li:hover * {
  background: none;
}

/* ================view_property_flex=========== */

.view_property_flex .colL {
  width: 100%;
  padding-right: 0px;
  /* margin-top: 150px; */
  /* margin-bottom: 20px; */
}

.view_property_flex .logs_section h5 {
  margin: 0;
  text-align: center;
}

.view_property_flex .logs_section .colL {
  /* width: 65% !important; */
}

.view_property_flex .colR {
  width: 100%;
  padding-left: 0px;
}

.view_property_flex .logs_section .colR {
  /* width: 35% !important; */
  padding: 10px;
}

.view_property_gallery {
  border-radius: 35px;
  overflow: hidden;
  position: relative;
  -webkit-border-radius: 35px;
  -moz-border-radius: 35px;
  -ms-border-radius: 35px;
  -o-border-radius: 35px;
}

.view_property_gallery .imgGallery {
  /* margin: -10px; */
}

.view_property_gallery .imgGallery .col {
  width: calc(100% / 5);
  padding: 10px;
  overflow: hidden;
  height: 190px;
}

.view_property_gallery .imgGallery .col img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -webkit-transform: rotateY(0deg) rotateX(0deg);
  transform: rotateY(0deg) rotateX(0deg);
}

.view_property_gallery .imgGallery .col:hover img {
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24);
  /* transform: rotateY(7deg) rotateX(7deg);
    -webkit-transform: rotateY(7deg) rotateX(7deg);
    -moz-transform: rotateY(7deg) rotateX(7deg);
    -ms-transform: rotateY(7deg) rotateX(7deg);
    -o-transform: rotateY(7deg) rotateX(7deg); */
  transform: perspective(75em) rotateX(18deg);
}

.view_property_gallery .popup-full .imgGallery .col:hover img {
  transform: none;
  -webkit-transform: none;
  -moz-transform: none;
  -ms-transform: none;
  -o-transform: none;
}

.view_heading {
  font-family: "Poppins Bold";
  color: #4c4c4c;
}

.address_view_blk {
  display: flex;
  flex-wrap: wrap;
  background: #fbeae5;
  padding: 10px 15px;
  margin: 20px 0px;
  width: fit-content;
}

.address_view_blk i {
  font-size: 23px;
  align-self: center;
  margin-right: 10px;
  color: #ff6c40;
}

.address_view_blk h3 {
  flex: 1;
  margin-bottom: 0;
  font-size: 18px;
  color: #000;
}

.listing_property_listing ul {
  display: flex;
  flex-wrap: wrap;
}

.listing_property_listing ul li {
  width: 50%;
  padding: 5px 0px;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  border-bottom: 1px solid#e5e5e585;
}

.listing_property_listing ul li:nth-child(even) {
  padding-left: 20px;
}

.listing_property_listing ul li:nth-child(odd) {
  padding-right: 20px;
  border-right: 1px solid#e5e5e585;
}

.listing_property_listing ul li:nth-last-child(1),
.listing_property_listing ul li:nth-last-child(2) {
  border-bottom: none;
}

/* ____small_popup___ */
.small_popup ._inner {
  max-width: 400px;
}

.small_popup ._inner .crosBtn {
  top: 5px;
  right: 3px;
  color: #000;
}

.small_popup ._inner h4 {
  font-family: "Poppins SemiBold";
}

.small_popup ._inner .txtBox::placeholder {
  color: rgb(172, 172, 172);
}

.gap_line_divide {
  padding: 10px 0px;
}

.full_wide_uploade_blk .image_blk_col {
  width: 100%;
}

.crud_image {
  background-color: #fbfbfb;
  padding: 20px;
  border: 1px solid #e9f8ff;
  position: relative;
}

.crud_image .image_upload_blk {
  background-color: #fff !important;
}

.crud_image h4 {
  margin-bottom: 0px;
  font-size: 16px;
}

.default_image_property_pg {
  position: relative;
  width: 130px;
  height: 130px;
  overflow: hidden;
  margin: 10px auto 10px;
}

.default_image_property_pg .image {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
}

.default_image_property_pg .image img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border: 1px solid #eee;
}

.default_image_property_pg .closeBtn,
.staff_dp_blk .closeBtn {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  color: #778ca3;
  font-size: 14px;
  cursor: pointer;
  line-height: 1;
  text-align: center;
  border: 1px solid #aaa;
  border-radius: 75%;
}

.default_image_property_pg .closeBtn:before,
.staff_dp_blk .closeBtn:before {
  content: "\2715";
}

.default_btn_prop {
  border: 1px solid #efefef;
  position: relative;
  /* height: 180px; */
  width: fit-content;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
  background: #fbfbfb;
  overflow: hidden;
  padding: 5px 10px;
  border-radius: 5px;
  gap: 10px;
  margin: 0px auto;
}

.default_btn_prop:hover {
  background-size: 30px 30px;
  background-image: -webkit-linear-gradient(
    135deg,
    #f6f6f6 25%,
    transparent 25%,
    transparent 50%,
    #f6f6f6 50%,
    #f6f6f6 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    -45deg,
    #f6f6f6 25%,
    transparent 25%,
    transparent 50%,
    #f6f6f6 50%,
    #f6f6f6 75%,
    transparent 75%,
    transparent
  );
  -webkit-animation: stripes 2s linear infinite;
  animation: stripes 2s linear infinite;
}

.default_btn_prop .small_upload_icon {
  width: 24px;
  height: 24px;
  margin: 0px auto;
  /* margin-bottom: 10px; */
  align-self: center;
}

.default_btn_prop h3 {
  font-size: 14px;
  margin-bottom: 0 !important;
}

.crud_image .upLoadBlk {
  height: auto;
  padding: 0;
  overflow: hidden;
  /* margin-bottom: 20px; */
  margin-top: 20px;
}

.crud_image .inside {
  /* height: 300px; */
  padding: 10px;
  overflow: auto;
}

.crud_image .inside.scrollbar::-webkit-scrollbar {
  height: 8px;
}

.crud_image .inside .imgLst {
  margin: -7px;
  flex-wrap: nowrap;
  width: calc(100% + 1rem);
}

.crud_image .inside .imgLst > li {
  width: 25%;
  /* cursor: move; */
  padding: 7px;
}

.add_more_prop_imgs {
  width: fit-content;
  margin: 0px auto;
}

.add_more_prop_imgs .bTn {
  justify-content: center;
}

.crud_image .inside .imgLst > li .image {
  position: relative;
  /* width: 100%;
    height: 0;
    padding-bottom: 100%; */
  width: 120px;
  height: 120px;
}

.add_more_curd {
  padding-bottom: 10px;
  position: relative;
}

.add_more_curd .crosBtn {
  top: -8px;
  right: -8px;
  background-color: tomato;
  border-radius: 75%;
  -webkit-border-radius: 75%;
  -moz-border-radius: 75%;
  -ms-border-radius: 75%;
  -o-border-radius: 75%;
  width: 20px;
  height: 20px;
  color: #fff;
  font-size: 14px;
}

.add_more_curd .inner_curd {
  width: 70%;
  display: flex;
  gap: 15px;
  margin-top: -10px;
}

.add_more_curd .inner_curd .txtBox {
  height: 40px;
}

.add_more_curd .inner_curd h6 {
  margin-bottom: 0;
  align-self: center;
}

.crud_image .inside .imgLst > li .image img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border: 1px solid #eee;
}

.crud_image .inside .imgLst > li .closeBtn {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  color: #778ca3;
  font-size: 14px;
  cursor: pointer;
  line-height: 1;
  text-align: center;
  border: 1px solid #aaa;
  border-radius: 75%;
}

.crud_image .inside .imgLst > li .closeBtn:before {
  content: "\2715";
}

/* ====add listing=== */
.justify_center_flex {
  justify-content: center;
  display: flex;
}

.total_count_price_listing {
  display: flex;
  flex-wrap: wrap;
}

.total_count_price_listing li {
  width: 50%;
  display: flex;
  justify-content: space-between;
  padding: 6px 0px;
  border-bottom: 1px solid#e5e5e585;
  font-size: 14px;
  gap: 20px;
}

.total_count_price_listing li del {
  opacity: 0.6;
}

.total_count_price_listing li:nth-child(even) {
  padding-left: 20px;
}

.total_count_price_listing li:nth-child(odd) {
  padding-right: 20px;
  border-right: 1px solid#e5e5e585;
}

.total_count_price_listing li:nth-last-child(1),
.total_count_price_listing li:nth-last-child(2) {
  border-bottom: none;
}

.lg_lbl ul:before {
  left: 10%;
  right: 10%;
}

.full_wide_price_listing li {
  background: #fefefe;
  margin-bottom: 8px;
  border: 1px solid #eee;
  padding: 10px;
  width: 100%;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}

.termination_costs_flex .full_wide_price_listing {
  margin: -1rem;
}

.termination_costs_flex .full_wide_price_listing li {
  width: 50%;
  padding: 0.5rem 1rem;
  background: transparent;
  margin-bottom: 0;
  border: none !important;
  border-radius: 0;
}

.termination_costs_flex .full_wide_price_listing li .inner {
  background: #fefefe;
  border: 1px solid #eee;
  padding: 10px;
  width: 100%;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
}

.full_wide_price_listing li:nth-child(odd) {
  border-right: 1px solid #eee;
  padding-right: 10px;
}

.full_wide_price_listing li:nth-last-child(1) {
  border-bottom: 1px solid #eee;
}

.full_wide_price_listing li:nth-last-child(2) {
  border-bottom: 1px solid#e5e5e585;
}

.full_wide_price_listing li:nth-child(even) {
  padding-left: 10px !important;
}

.chk .headCredit {
  margin: 0px;
  background: #fbfbfb;
  /* border-bottom: 1px solid #e9f8ff; */
  border: 1px solid #f1f1f1;
}

.chk .headCredit {
  height: 40px;
  padding-left: 12px;
  padding-right: 12px;
}

.chk .flex-2 .inner {
  width: 50%;
  padding: 5px;
  position: relative;
}

.chk .flex-2 .inner:nth-child(2) ul {
  height: 20px;
}

.creditCard .headCredit .inner {
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.chk .flex-2 .inner .lblBtn {
  display: flex;
}

.chk .cardSec {
  padding: 10px 10px;
  /* border: 1px solid #4b4b4b; */
  border-top: none;
  margin-bottom: 10px;
  background: #fefefe;
  border: 1px solid #f1f1f1;
  border-top: 0;
}
.chk .cardSec.listCardSec {
  border: none;
}
.chk .cardSec.listCardSec select.txtBox {
  max-width: 400px;
  margin: 0 auto;
}
.chk .creditCard .cardSec .inner .info {
  position: absolute;
  top: 18px;
  right: 14px;
}

.chk .creditCard .cardSec .inner .info {
  position: absolute;
  top: 18px;
  right: 14px;
}

.chk .creditCard .cardSec .inner .info > i {
  width: 18px;
  height: 18px;
  padding-top: 2.4px;
  text-align: center;
  border: 1px solid #aaa;
  color: #aaa;
  border-radius: 75%;
  font-size: 12px;
  display: block;
  padding-left: 0.3px;
  cursor: pointer;
}

.chk .creditCard .cardSec .inner .info > .infoTip {
  position: absolute;
  z-index: 10;
  /*color: #333;*/
  color: #222222;
  width: 160px;
  top: 22px;
  left: -115px;
  background: #ffffff;
  border: 1px solid #f1f1f1;
  text-align: justify;
  -moz-text-align-last: center;
  text-align-last: center;
  font-size: 12px;
  padding: 5px 10px 5px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.15) 2px 2px 6px 0px;
  box-shadow: rgba(0, 0, 0, 0.15) 2px 2px 6px 0px;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.chk .creditCard .cardSec .inner .info > .infoTip:before {
  position: absolute;
  content: "";
  left: 120px;
  top: -3px;
  border-top: 8px solid #ffffff;
  border-left: 8px solid #ffffff;
  -webkit-box-shadow: #ffffff 1px -1px 0px;
  box-shadow: #ffffff 1px -1px 0px;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.chk .creditCard .cardSec .inner .info:hover .infoTip {
  visibility: visible;
  opacity: 1;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.chk .creditCard .paypalSec .ico,
.chk .creditCard .creditSec .ico {
  max-width: 160px;
  margin: 20px auto 10px;
  background: transparent;
  border-radius: 0;
  box-shadow: none;
}

.chk .creditCard .paypalSec .ico img,
.chk .creditCard .creditSec .ico img {
  border-radius: 0px;
}

.paypalSec,
.paylaterSec {
  padding: 10px 10px;
  /* border: 1px solid #4b4b4b; */
  border-top: none;
  margin-bottom: 10px;
  background: #fefefe;
  border: 1px solid #f1f1f1;
  border-top: 0;
  display: none;
}

.pay_after_btns {
  justify-content: center;
  padding: 20px;
  gap: 20px;
}

.pay_after_btns .webBtn {
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
  min-width: 220px;
}

.pay_after_btns .webBtn.yellowBtn {
  background: #ffdf00;
}

.pay_after_btns .webBtn.yellowBtn img {
  width: 60px;
}

.pay_later_btn img {
  width: 23px;
  align-self: center;
  margin-top: 4px;
}

.pay_later_btn:hover {
  background: #ffdf00;
}

.chk .small_card_txt {
  font-size: 17px;
  max-width: 400px;
  margin: -5px auto 20px;
  color: #898989;
}

.other_pay img {
  width: 55px;
  margin-left: auto;
}

.other_pay_more img {
  width: 19px;
  margin-left: auto;
  margin-top: 4px;
}

.justify_btn_center {
  justify-content: center !important;
}

.check_flex_box {
  gap: 20px;
}

.personal_info_listing {
  padding: 20px;
  background: #fbfbfb;
  margin-top: 15px;
  border: 1px solid #f5f5f5;
}

.personal_info_listing h3,
.info_heading_confirm h4 {
  font-size: 20px;
  font-family: "Poppins SemiBold";
  margin-bottom: 20px;
  color: #088fff;
}

/*
|----------------------------------------------------------------------
|       Inbox
|----------------------------------------------------------------------
*/

[inbox] {
  flex: none;
  height: 92vh;
  min-height: 200px;
  /* background: #f3f9fd; */
}

[inbox] .contain-fluid {
  display: flex;
  height: 100%;
  padding: 0;
}

[inbox] .contain-fluid,
[inbox] .barBlk,
[inbox] .chatBlk {
  height: 100%;
}

[inbox] .barBlk,
[inbox] .chatBlk {
  display: flex;
  width: 100%;
  /* background: #fff; */
  padding: 15px;
  flex-direction: column;
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  overflow: hidden;
}

[inbox] .barBlk {
  width: 320px;
  min-width: 320px;
  background: #ffffff;
  padding: 0;
}

/*_____ barBlk _____*/

[inbox] .time {
  color: #8b9593;
  font-size: 80%;
  font-family: "Poppins Medium";
}

[inbox] .barBlk h3 {
  margin: -20px -20px 20px;
  padding: 20px;
  border-bottom: 1px solid #eee;
}

[inbox] .barBlk .frnds {
  height: 100%;
  overflow: auto;
}

[inbox] .barBlk .frnds > li {
  display: block;
}

[inbox] .barBlk .frnds > li:hover .inner {
  background: rgb(0 0 0 / 5%);
}

[inbox] .barBlk .frnds > li.active .inner {
  background: #00acfc;
  color: #fff;
  position: relative;
  /* box-shadow: 0 5px 20px rgb(18 15 56 / 20%); */
}

[inbox] .barBlk .frnds > li.unread .inner:before {
  content: "";
  position: absolute;
  right: 18px;
  top: 50%;
  width: 10px;
  height: 10px;
  background-color: #00acfc;
  border-radius: 75%;
}

[inbox] .barBlk .frnds > li.unread .inner p {
  color: #000;
  font-family: "Poppins Bold";
}

[inbox] .barBlk .frnds > li.active .inner p {
  color: #f7f7f7;
}

[inbox] .barBlk .frnds > li .inner p {
  font-size: 13px;
}

[inbox] .barBlk .txt {
  position: relative;
  flex: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

[inbox] .barBlk .time {
  margin: -12px 0 0;
}

[inbox] .inner {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 12px 15px;
  transition: all ease 0.5s;
}

[inbox] .barBlk .ico,
[inbox] .chatBlk .ico {
  width: 46px;
  min-width: 46px;
  height: 46px;
  margin-right: 15px;
  box-shadow: none;
}

[inbox] .barBlk .inner {
  position: relative;
}

[inbox] .barBlk .inner .ico:after {
  position: absolute;
  top: 25px;
  right: -2px;
  bottom: 0;
  display: block;
  width: 12px;
  height: 12px;
  background: #ff6c40;
  margin: auto;
  border: 2px solid #fff;
  border-radius: 75%;
}

[inbox] .barBlk .ico > i.status {
  right: 0;
}

[inbox] .barBlk .frnds > li h5 {
  font-size: 100%;
  margin-bottom: 3px;
  font-family: "Poppins SemiBold";
}

[inbox] .barBlk .frnds > li h5,
[inbox] .barBlk .frnds > li p {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

[inbox] .barBlk .inner.unread .txt {
  margin-right: 10px;
}

[inbox] .barBlk .frnds > li .time_msg_ago {
  position: absolute;
  top: 7px;
  right: 7px;
  font-size: 11px;
  font-family: "Poppins Medium";
  padding: 1px 6px;
  border-radius: 3px;
  opacity: 0.7;
}

[inbox] .barBlk .frnds > li.active .inner .time_msg_ago {
  opacity: 1;
}

[inbox] .barBlk .inner.unread .ico:after {
  content: "";
}

[inbox] .barBlk .inner.unread p {
  color: #080a0f;
  font-family: "Poppins Medium";
}

[inbox] .barBlk .srch {
  display: flex;
  align-items: center;
  padding: 15px;
}

[inbox] .barBlk .srch > .txtBox {
  height: 40px;
  /* background: #f7f7f7; */
  padding-left: 42px;
  border: 0;
  box-shadow: 0 5px 20px rgb(18 15 56 / 7%);
}

[inbox] .barBlk .srch > button {
  position: absolute;
  top: 0;
  left: 30px;
  bottom: 0;
  width: 16px;
  height: 16px;
  background: transparent;
  padding: 0;
  margin: auto;
  border: 0;
  filter: brightness(0) invert(0.5);
}

/*_____ chatPerson _____*/

[inbox] .chatBlk .chat {
  display: none;
  max-height: 100%;
  padding: 45px 15px 0;
  margin: -15px -15px 0;
  flex: 1;
  flex-direction: column;
  overflow: auto;
}

[inbox] .chatBlk .chat.active {
  display: block;
}

[inbox] .buble {
  display: flex;
  margin-bottom: 35px;
  width: 70%;
}

[inbox] .buble.me {
  flex-flow: row-reverse;
  margin-left: auto;
}

[inbox] .buble.me .ico {
  margin-left: 15px;
  margin-right: 0;
}

[inbox] .buble .cntnt {
  background: #e4f6ff;
  color: #565656;
  padding: 12px 20px;
  line-height: 1.5;
  margin-bottom: 5px;
  border-radius: 7px;
  /* box-shadow: 0 5px 20px rgb(18 15 56 / 7%); */
  font-size: 14px;
  max-width: 400px;
}

[inbox] .buble .txt {
  margin-top: -30px;
}

[inbox] .buble.me .txt .cntnt {
  margin-right: -40px;
  padding-right: 35px;
}

[inbox] .buble.you .txt .cntnt {
  margin-left: -40px;
  padding-left: 35px;
}

[inbox] .buble.me .cntnt {
  background: #00acfc;
  color: #fff;
}

[inbox] .buble .ico {
  box-shadow: none;
  border: 7px solid #fbfbfb;
  width: 60px;
  min-width: 60px;
  height: 60px;
  align-self: flex-end;
}

[inbox] .buble.me .time {
  text-align: right;
}

[inbox] .chatPerson {
  display: flex;
  margin: -15px -15px 15px;
  padding: 15px;
  align-items: center;
  border-bottom: 1px solid #eee;
  /* box-shadow: 0 5px 15px rgb(0 0 0 / 7%); */
  background-color: #fff;
}

[inbox] .chatPerson h6 {
  flex: 1;
  margin: 0;
}

[inbox] .chatPerson .ico {
  width: 28px;
  min-width: 28px;
  height: 28px;
}

[inbox] .chatPerson .backBtn {
  display: none;
  font-size: 120%;
  margin-right: 15px;
  line-height: 0;
}

[inbox] .chatPerson .backBtn a {
  line-height: 0;
}

[inbox] .buble .cntnt.card {
  min-width: 300px;
  background: #fff;
  color: #080a0f;
  padding: 0;
  overflow: hidden;
}

[inbox] .buble .cntnt.card .fig {
  height: 150px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

[inbox] .buble .cntnt.card .btm {
  display: flex;
  justify-content: space-between;
  padding: 15px;
}

[inbox] .buble .cntnt.card .btm .price {
  text-align: right;
}

[inbox] .buble .cntnt.card .btm small {
  display: block;
  color: #8b9593;
}

[inbox] .buble .cntnt.card .bTn {
  padding: 10px;
  border-top: 2px dashed #eef1f4;
}

/*_____ write _____*/

[inbox] .chatBlk .write {
  background: #fff;
  padding: 10px 15px;
  margin: 0 -15px -15px;
  border-top: 1px solid #eee;
}

[inbox] .chatBlk .write .btm {
  display: flex;
  align-items: center;
  margin-top: 10px;
  position: relative;
}

[inbox] .chatBlk .write textarea {
  height: 46px;
  max-height: 120px !important;
  padding: 10px 20px;
  /* transition: all linear 0.1s !important; */
  transition: none !important;
  box-shadow: 0 5px 20px rgb(18 15 56 / 7%);
  overflow: hidden;
}

[inbox] .chatBlk .write .arrowBtn {
  width: auto;
  min-width: initial;
  padding: 0;
  width: 40px;
}

[inbox] .chatBlk .write .arrowBtn:not(:hover) {
  filter: brightness(0) invert(0.7);
}

[inbox] .chatBlk .write .arrowBtn:hover,
[inbox] .chatBlk .write .arrowBtn:focus {
  background: #00000000;
}

[inbox] .chatBlk .write .icoBtn {
  margin-left: auto;
  right: 6px;
  margin-left: 15px;
  height: 46px;
  width: 46px;
}

[inbox] .chatBlk .write .icoBtn > img {
  margin: 0;
}

[inbox] .chatBlk .chat .atch {
  display: block;
}

[inbox] .chatBlk .chat .atch span {
  max-width: 80px;
  background: #fff;
  padding: 4px 8px 2px;
  border: 1px solid #eee;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

[inbox] .filesAtch {
  margin: -2px -2px 2px;
  display: flex;
  flex-wrap: wrap;
}

[inbox] .filesAtch > span {
  position: relative;
  max-width: 20ch;
  background: #f5f8fa;
  color: #8b9593;
  margin: 2px;
  padding: 1px 5px 4px;
  padding-right: 20px;
  border: 1px solid #eee;
  border-radius: 4px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

[inbox] .filesAtch > span.fail {
  color: #0071bd;
}

[inbox] .filesAtch > span > i {
  position: absolute;
  top: 5px;
  right: 3px;
  cursor: pointer;
}

[inbox] .filesAtch > span > em {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  /* width: 0; */
  height: 2px;
  background: #ff3939;
}

[data-payment] .insideBlk {
  margin: 15px 0 0;
}

[data-payment] .insideBlk:not(.active) {
  display: none;
}

.flex_col_chat {
  display: flex;
  flex-wrap: wrap;
  margin: -10px;
}

.flex_col_chat .col {
  width: calc(100% / 3);
  padding: 10px;
}

/*
|----------------------------------------------------------------------
|       Notifications
|----------------------------------------------------------------------
*/
.notiBlk {
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  max-width: 800px;
}

.notiBlk .col {
  width: 100%;
  padding: 10px;
}

.notiBlk .inner {
  display: flex;
  padding: 25px;
  width: 100%;
  background-color: #fff;
  border-radius: 5px;
  overflow: hidden;
  border: 1px solid #e9f8ff;
}

.notiBlk .inner:not(:nth-last-child(1)) {
  border-bottom: 1px solid #eee;
}

.notiBlk .inner .ico {
  width: 42px;
  min-width: 42px;
  height: 42px;
  margin-right: 20px;
}

.notiBlk .inner .txt {
  flex: 1;
  align-self: center;
}

.notiBlk .inner .txt p {
  margin: 0;
}

.notiBlk .inner .txt .time {
  color: #8b9593;
  font-size: 80%;
  font-family: "Poppins Medium";
}

.popup_select_property {
  margin-bottom: 20px;
}
.popup_select_property > div > div {
  border: 1px solid hsl(0, 0%, 80%) !important;
}
/* ___create lease___ */
.heading_fieldset {
  margin-bottom: 20px;
  border-bottom: 1px solid #f1f1f1;
  padding-bottom: 15px;
}

.heading_fieldset h4 {
  font-family: "Poppins SemiBold";
  margin-bottom: 5px;
}

.heading_fieldset p {
  color: #a3a3a3;
}

.option_pdf label h5 {
  margin-bottom: 5px;
}

.bottom_line_divide {
  border-bottom: 1px solid #f1f1f1;
  margin-bottom: 15px;
}

.upload_file_btn {
  display: flex;
  gap: 10px;
}

.upload_file_btn img {
  width: 25px;
  filter: brightness(0) invert(1);
}

canvas#signature-pad {
  background: rgb(241, 241, 241);
  width: 100%;
  height: 100%;
  cursor: crosshair;
}

button#clear {
  height: 100%;
  background: #ff6c40;
  border: 1px solid transparent;
  color: #fff;
  cursor: pointer;
  border-radius: 3px;
}

button#clear span {
  display: block;
}

.signature_cost ._inner {
  min-height: 200px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fdfdfd;
  border: 1px solid #d5ecff;
  padding: 20px;
  border-radius: 7px;
}

.termination_popup .signature_cost ._inner {
  background: transparent;
  border: none;
  box-shadow: none;
  padding: 0;
  border-radius: 0;
  min-height: unset;
}

.termination_popup .dash_form_blk form fieldset {
  width: 100%;
}

.termination_popup .leases-flex-pay .left_col {
  width: 100%;
}

.termination_popup .mini-release_width {
  max-width: 100%;
  gap: 10px;
}

.termination_popup .leases-flex-pay .left_col li {
  width: calc(100% / 2);
}

.signature_cost ._inner h4 {
  font-family: "Poppins SemiBold";
}

.signature_cost_flex {
  display: flex;
  flex-wrap: wrap;
}

.signature_cost_flex .colL {
  width: 100%;
  padding-right: 0px;
}

.signature_cost_flex .colR {
  width: 50%;
  padding-left: 10px;
}

.new_blk_cost_add .inner_blk {
  position: relative;
  background-color: #e7eef3;
  padding: 10px;
  margin: 10px 0px;
  border-radius: 7px;
}

.new_blk_cost_add .inner_blk .inner_colL {
  width: 50%;
  padding-right: 10px;
}
.new_blk_cost_add .inner_blk .inner_colR > div {
  padding-right: 10px;
}
.new_blk_cost_add .inner_blk .inner_colL > div > div,
.new_blk_cost_add .inner_blk .inner_colR > div > div {
  min-height: 46px !important;
  border: 1px solid #eee !important;
  border-radius: 6px;
  padding: 4px 0;
}
.new_blk_cost_add .inner_blk .inner_colR {
  width: 50%;
  padding-left: 10px;
}

.new_blk_cost_add .inner_blk .closeBtn {
  position: absolute;
  top: -10px;
  right: -10px;
  width: 21px;
  height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  color: #778ca3;
  font-size: 12px;
  cursor: pointer;
  line-height: 1;
  text-align: center;
  border: 1px solid #aaa;
  border-radius: 75%;
  padding-top: 1px;
  padding-left: 1px;
}

.new_blk_cost_add .inner_blk .closeBtn:before {
  content: "\2715";
}

.termination_costs_flex.leases-flex-pay .left_col {
  width: 100%;
}

.termination_costs_flex.leases-flex-pay .left_col .bTn .webBtn {
  width: 30px;
  height: 30px;
  min-width: 30px;
  border-radius: 75%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  align-items: center;
}

.termination_costs_flex.leases-flex-pay .left_col .bTn .webBtn i {
  margin: 0;
}

.termination_costs_flex.leases-flex-pay .left_col .inner_inner > .flex {
  justify-content: space-between;
  /* border-bottom: 1px solid #ddd; */
  padding-bottom: 5px;
  margin-bottom: 20px;
}

.termination_costs_flex.leases-flex-pay
  .left_col
  .inner_inner
  > .mini-release_width {
  margin: 0;
  flex-wrap: nowrap;
}

.offer_dash_detail_page .colL .webBtn img {
  filter: brightness(0) invert(1);
  width: 18px;
  height: 18px;
  object-fit: contain;
  margin-right: 5px;
}

/* ______________Account Settings____________ */

.account_settings_flex .colL {
  width: 70%;
  padding-right: 10px;
}

.account_settings_flex .colR {
  width: 30%;
  padding-left: 10px;
}

.upLoadDp .ico {
  width: 142px;
  min-width: 142px;
  height: 142px;
  margin: 0 auto 15px;
  border: 2px solid #f6f9fc;
}

.upLoadDp .webBtn.uploadImg {
  max-width: 142px;
}

.upLoadDp .webBtn {
  margin: 0 auto;
}

.upLoadDp .noHats {
  max-width: 160px;
  color: #8b9593;
  font-size: 11px;
  margin: 7px auto 0;
}

.setting_blk {
  background-color: #fff;
  padding: 20px;
  margin: 0px auto;
  border: 1px solid #e9f8ff;
}
.setting_blk.propertiesTab,
.dash_form_blk form fieldset.propertiesFieldset,
.setting_blk.propertiesTab .maintenance_profile_flex .col .inner {
  border: none;
}
.settings_header,
.p_help {
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
}

.settings_header h4,
.p_help h4 {
  flex: 1;
}

.settings_header .info,
.lease_month_info,
.p_help .info {
  position: relative;
  align-self: flex-start;
  color: #8b9593;
  line-height: 1;
  margin-left: auto;
  border: none !important;
  min-height: auto !important;
  border-radius: 0 !important;
  overflow: unset !important;
}

.lease_month_info {
  margin-left: 0;
}

.lease_month_info:hover,
.dash_form_blk form fieldset .form_blk > div > div.lease_month_info:hover {
  border: none !important;
}

.settings_header .info > strong,
.lease_month_info strong,
.p_help .info > strong {
  display: flex;
  align-items: center;
  font-size: 90%;
  font-family: "Poppins SemiBold" !important;
  cursor: pointer;
}

.settings_header .info > strong:after,
.lease_month_info strong:after,
.p_help .info > strong:after {
  content: "";
  display: block;
  width: 20px;
  min-width: 20px;
  height: 20px;
  background-image: var(--question);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin-left: 5px;
  filter: brightness(0) invert(0.6);
}

.credits_settings_header.settings_header .info > strong:after {
  background-image: url("../images/info-icon.png");
  filter: none;
}

.credits_settings_header.settings_header .info,
.lease_month_info {
  margin: 0;
}

.settings_header .info .infoIn,
.lease_month_info .infoIn,
.p_help .infoIn {
  position: absolute;
  top: 100%;
  right: 0;
  width: 240px;
  /* background: #fff; */
  background: rgb(255 255 255 / 0.7);
  padding: 20px;
  margin: 20px 0 0;
  line-height: 1.4;
  text-align: left;
  font-size: 90%;
  opacity: 0;
  visibility: hidden;
  border-radius: 6px;
  backdrop-filter: blur(10px);
  box-shadow: 0 15px 35px 0 rgba(49, 49, 93, 0.1),
    0 5px 15px 0 rgba(0, 0, 0, 0.08);
  transition: all ease 0.5s;
  z-index: 2;
}

.settings_header .info:hover .infoIn,
.lease_month_info:hover .infoIn,
.p_help .info:hover .infoIn {
  margin-top: 7px;
  opacity: 1;
  visibility: visible;
}

.account_settings_flex form .form_blk {
  margin-bottom: 15px;
}

/* _____ Service Staff____ */
.outer_list {
  margin-top: 30px;
}

.outer_list.offers_outer_list {
  margin-top: 0;
}

.outer_list .lst {
  position: relative;
  background: #fff;
  padding: 20px;
  border-radius: 7px;
  -webkit-box-shadow: 0 5px 20px rgb(18 15 56 / 7%);
  box-shadow: 0 5px 20px rgb(18 15 56 / 7%);
  /* overflow: hidden; */
  max-width: 1000px;
  margin: 0px auto;
  margin-bottom: 20px;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.outer_list .lst:hover {
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  box-shadow: 0 5px 20px rgb(191 234 254);
}

.outer_list .lst ul {
  display: flex;

  flex-wrap: wrap;
}

.outer_list .lst li {
  padding: 0 10px;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
  /* align-self: center; */
}

.outer_list .lst > ul > li:nth-child(1) {
  /* margin-right: auto; */
  padding-left: 0;
  width: 30%;
}

.outer_list .lst > ul > li:nth-child(2) {
  width: 40%;
}

.outer_list .lst > ul > li .card_brand {
  display: flex;
  gap: 10px;
  align-items: center;
}

.outer_list .lst > ul > li .card_brand img {
  width: 40px;
  object-fit: contain;
}

.outer_list .lst > ul > li:nth-child(3) {
  width: 10%;
}

.outer_list .lst > ul > li:nth-child(4) {
  width: 20%;
  justify-content: flex-end;
}

.outer_list .lst ul .icoBlk {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.outer_list .lst ul .icoBlk .icon {
  width: 70px;
  height: 70px;
  overflow: hidden;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.outer_list .lst ul .icoBlk .icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.outer_list .lst ul .icoBlk .txt {
  flex: 1;
  margin-left: 15px;
  align-self: center;
}

.outer_list .lst ul .icoBlk .txt h5 {
  margin-bottom: 3px;
  font-family: "Poppins SemiBold";
}

.outer_list .lst ul .icoBlk .txt p small {
  color: #999;
}

.outer_list .lst ul .icoBlk a,
.offer_dash_page .outer_list .lst > a {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
}

.offer_dash_page .outer_list .lst .tenants_dps_flex {
  z-index: 9999;
}

.outer_list .lst ul li.property_title p {
  margin-bottom: 3px;
  font-size: 14px;
}

.outer_list .lst ul li.property_title span {
  font-size: 12px;
  background: #bfeafe;
  padding: 1px 10px;
  border-radius: 3px;
  color: #2a2a2a;
}

.badge {
  font-weight: 100;
  cursor: pointer;
}

.outer_list .lst ul li.bTn {
  gap: 9px;
}

.outer_list .lst.header_lst {
  box-shadow: none;
  background-color: transparent;
}

.outer_list .lst ul li.bTn .labelBtn:focus {
  background-color: transparent;
  color: #000;
}

/* ___maintenance_form___ */

.maintenance_form {
  max-width: 700px;
  margin: 0px auto;
  background-color: #fff;
  padding: 20px;
  border: 1px solid #e9f8ff;
}

.maintenance_form .upLoadDp {
  margin-bottom: 20px;
}

.maintenance_form .form_blk {
  margin-bottom: 15px;
}

/* ___maintenance_detail_flex___ */
.maintenance_detail_flex {
  max-width: 1000px;
  margin: 0px auto;
}

.maintenance_detail_flex .colL {
  width: 50%;
  padding-right: 20px;
}

.maintenance_detail_flex .colR {
  width: 50%;
  padding-left: 20px;
}

.maintenance_detail_flex .inner {
  background-color: #fff;
  padding: 20px;
  margin: 0px auto;
  border: 1px solid #e9f8ff;
}

.maintenance_detail_flex .colL .inner {
  display: flex;
  flex-wrap: wrap;
}

.maintenance_detail_flex .colL .inner .image {
  width: 200px;
  overflow: hidden;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.popup .maintenance_detail_flex .colL .inner .image {
  width: 80px;
}
.maintenance_detail_flex .colL .inner .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.maintenance_detail_flex .colL .inner .txt {
  flex: 1;
  align-self: center;
  margin-left: 20px;
}

.maintenance_detail_flex .colL .inner h5 {
  font-family: "Poppins Medium";
  color: #5c5c5c;
  font-size: 18px;
}

.maintenance_detail_flex .colL .inner p {
  font-size: 13px;
  color: #898989;
}

.maintenance_detail_flex .colL .inner a {
  display: block;
  font-size: 13px;
  font-family: "Poppins Medium";
  color: #898989;
  display: flex;
  gap: 10px;
}

.maintenance_detail_flex .colL .inner a i {
  color: #ff6c40;
  font-size: 15px;
  width: 15px;
}

.maintenance_detail_flex .colR ul li {
  display: block;
}

.maintenance_detail_flex .colR ul li a {
  color: #898989;
  font-size: 13px;
}

.maintenance_detail_flex .colR ul li a:hover {
  color: #00acfc;
}

/* ____offers page___ */
.offer_dash_page .outer_list .lst {
  max-width: 100%;
}

.offer_dash_page .outer_list .lst > ul > li {
  /* width: 10%; */
  flex: 1;
  text-align: center;
  /* z-index: 11; */
  align-self: center;
}

.offer_dash_page .outer_list .lst > ul > li:nth-child(1) {
  width: 25%;
  flex: unset;
  text-align: left;
}

.commercial_dashboard_new
  .offer_dash_page
  .outer_list
  .lst
  > ul
  > li:nth-child(1) {
  width: 30%;
}

.offer_dash_page .outer_list .lst > ul > li:nth-child(2) {
  width: 30%;
  flex: unset;
  display: flex;
  justify-content: center;
}

.offer_dash_page .outer_list .lst > ul > li:nth-last-child(1) {
  /* width: 15%; */
  justify-content: flex-end;
}

.border_solid {
  border-radius: 2px !important;
  -webkit-border-radius: 2px !important;
  -moz-border-radius: 2px !important;
  -ms-border-radius: 2px !important;
  -o-border-radius: 2px !important;
}

.filter_status_offer {
  /* max-width: 400px; */
  margin-left: auto;
  margin-bottom: 10px;
  display: flex;
  gap: 30px;
  justify-content: flex-end;
}

.filter_status_offer .col_ofer_filter {
  display: flex;
  gap: 5px;
}

.filter_status_offer .sorted_heading {
  align-self: flex-end;
  margin-right: 10px;
  font-size: 13px;
  font-family: "Poppins Medium";
}

.filter_status_offer .sorted_drop_down {
  align-self: center;
}

.filter_status_offer select.txtBox {
  height: 30px;
  padding: 1px 5px;
  border: none;
  border-bottom: 1px solid #ddd;
  border-radius: 0;
  font-size: 13px;
  background-color: transparent;
}

/* ____offer_dash_detail_page____ */

.offer_dash_detail_page .colL {
  width: 25%;
  padding-right: 10px;
}

.offer_dash_detail_page .colR {
  width: 75%;
  padding-left: 10px;
}

.offer_dash_detail_page .offer_blk_border {
  border-radius: 10px;
  background: #fff;
  border: 1px solid #ddf5fd;
  padding: 20px;
}

.offer_dash_detail_page .sender_bio {
  margin: -10px;
}

.offer_dash_detail_page .sender_bio .sender_offer_col {
  width: calc(100% / 3);
  padding: 10px;
}

.commercial_dashboard_new
  .offer_dash_detail_page
  .sender_bio
  .sender_offer_col {
  width: calc(100% / 2);
}

.offer_dash_detail_page .sender_bio .inner {
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.offer_dash_detail_page .sender_bio .inner:hover,
.offer_dash_detail_page .sender_bio .inner.active {
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  border: 1px solid #ff6c40;
}

.offer_dash_detail_page .sender_bio .inner .mini_icon {
  width: 60px;
  height: 60px;
  overflow: hidden;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.offer_dash_detail_page .sender_bio .inner .mini_icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.offer_dash_detail_page .sender_bio .inner .bio_info {
  flex: 1;
  margin-left: 10px;
  align-self: center;
}

.offer_dash_detail_page .sender_bio .inner .bio_info .name h4 {
  font-size: 16px;
  margin-bottom: 6px;
  font-family: "Poppins SemiBold";
}

.offer_dash_detail_page .sender_bio .inner .bio_info small {
  font-size: 12px;
}

.offer_dash_detail_page .sender_bio .inner .bio_info .address {
  line-height: 1.2;
}

.offer_cstm_ul {
  border-radius: 10px;
  background: #fff;
  border: 1px solid #ddf5fd;
  padding: 15px;
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
}

.offer_cstm_ul li em {
  display: block;
  opacity: 0.8;
  font-family: "Poppins Medium";
}

.offer_cstm_ul li em del {
  opacity: 0.6;
}

.offer_cstm_ul li {
  width: calc(100% / 3);
  font-size: 13px;
  padding: 5px;
}

.offer_cstm_ul.costs_offer_ul li {
  width: 100%;
  display: flex;
}

.offer_cstm_ul.costs_offer_ul li div {
  width: calc(100% / 3);
  font-size: 13px;
  padding: 5px;
}

.check_background_offer {
  display: flex;
  max-width: 500px;
  border-radius: 10px;
  background: #fff;
  border: 1px solid #ddf5fd;
  padding: 15px;
  margin: 0px auto;
}

.check_background_offer p {
  margin-bottom: 0;
  flex: 1;
  margin-right: 20px;
  align-self: center;
  font-size: 13px;
  font-family: "Poppins SemiBold";
}

.check_lbl_offer {
  padding: 5px 15px;
  color: #fff;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  font-size: 13px;
}

.bTn.offer_action_btn {
  justify-content: center;
  max-width: 500px;
  margin: 0px auto;
}

.bTn.offer_action_btn.auto_btn_action {
  max-width: fit-content;
}

/* ____leases___ */
/* .lst.lst_header_all{
    box-shadow: none;
    background: #00acfc;
    margin-bottom: 10px;
    padding: 10px 20px;
}
.lst.lst_header_all li{
    font-family: 'Poppins SemiBold';
    color: #ffffff;
    font-size: 14px;
} */
.lease_heading_mini {
  width: 100%;
  font-size: 13px;
  font-family: "Poppins SemiBold";
  color: #999;
}

.offer_dash_page.leases_page_dash .outer_list .lst > ul > li:nth-child(2) {
  flex-wrap: wrap;
}

/* ___action_drop___ */
.action_drop_lease {
  position: relative;
  height: 50px;
}

.action_drop li {
  display: block;
  width: 100%;
  padding: 0 !important;
}

.action_drop_lease .action_dots {
  background: #fbfbfb;
}

.action_drop_lease .action_drop ._dropCnt {
  min-width: 115px;
}

.action_drop li a {
  display: block;
}

.tenants_dps_flex {
  -webkit-box-orient: vertical;
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  justify-content: center;
}

.tenants_dps_flex li {
  position: relative;
  padding: 0 !important;
}

.tenants_dps_flex li:not(:last-child) {
  margin-right: -1rem;
}

.tenants_dps_flex li .ico {
  border: 0.3rem solid #fff;
  border-radius: 75%;
  box-shadow: 0 0.5rem 2.5rem rgb(15 13 54 / 4%);
  height: 4rem;
  min-width: 4rem;
  overflow: hidden;
  width: 4rem;
}

.tenants_dps_flex .inner {
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  border-radius: 10px;
  background: #f9f9f9;
  border: 1px solid #ddf5fd;
  padding: 20px;
  text-align: left;
  box-shadow: 0px 0px 11px 0px #dddddd80;
  position: absolute;
  width: 285px;
  top: -109px;
  left: -50px;
  visibility: hidden;
  opacity: 0;
  transform: translateY(10px);
  -webkit-transform: translateY(10px);
  -moz-transform: translateY(10px);
  -ms-transform: translateY(10px);
  -o-transform: translateY(10px);
}

.tenants_dps_flex li:hover .inner {
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  visibility: visible;
  opacity: 1;

  transform: translateY(0px);
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  -ms-transform: translateY(0px);
  -o-transform: translateY(0px);
}

.tenants_dps_flex .inner .mini_icon {
  width: 60px;
  height: 60px;
  overflow: hidden;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.tenants_dps_flex .inner .mini_icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.tenants_dps_flex .inner .bio_info {
  flex: 1;
  margin-left: 10px;
  align-self: center;
}

.tenants_dps_flex .inner .bio_info .name h4 {
  font-size: 16px;
  margin-bottom: 6px;
  font-family: "Poppins SemiBold";
}

.tenants_dps_flex .inner .bio_info .address {
  line-height: 1.2;
}

.tenants_dps_flex .inner .bio_info small {
  font-size: 12px;
}

/* ____dash_property_listing____ */

.dash_property_listing .fav_icon {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgb(255 108 64);
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  border-radius: 50px;
  font-size: 18px;
  padding-top: 6px;
  cursor: pointer;
  z-index: 2;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  color: #fff;
}

.dash_property_listing .image_slider {
  overflow: hidden;
}

.dash_property_listing .image_slider .item {
  position: relative;
  overflow: hidden;
  padding-bottom: 70%;
}

.dash_property_listing .image_slider .item img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  object-fit: cover;
  height: 100%;
}

/* ____bank_show_listing____ */
.bank_show_listing li {
  flex: 1;
  text-align: center;
}

.bank_show_listing li:nth-last-child(1) {
  display: flex;
  justify-content: center;
}

.bank_show_listing li h6 {
  margin-bottom: 3px;
  font-size: 13px;
  font-family: "Poppins SemiBold";
  color: #999;
}

.combine_blk {
  border-radius: 10px;
  background: #fff;
  border: 1px solid #ddf5fd;
  padding: 15px;
}

.maintenance_tenant_flex .mini_icon {
  width: 50px;
  height: 50px;
  overflow: hidden;
  border-radius: 50%;
}

.maintenance_tenant_flex .mini_icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.maintenance_tenant_flex .bio_info {
  flex: 1;
  align-self: center;
  margin-left: 10px;
}

.maintenance_tenant_flex .bio_info .name h4 {
  margin-bottom: 0;
  font-size: 14px;
  font-family: "Poppins Medium";
}

.maintenance_requests_lsting .lst li {
  font-size: 13px;
}

.time_ago_maintenance_request {
  position: absolute;
  right: 3px;
  top: 0px;
  display: flex;
  gap: 10px;
}

.time_ago_maintenance_request .time_ago {
  font-size: 13px;
  font-family: "Poppins Medium";
  color: #9b9b9b;
  align-self: center;
}

.time_ago_maintenance_request .mini_status_lbl {
  font-size: 13px;
  font-family: "Poppins Medium";
  color: #fff;
  padding: 4px 10px 2px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
}

.wide_maintenance_col_purpose {
  flex: 1;
}

.maint_persons_outer {
  border-radius: 10px;
  background: #fff;
  border: 1px solid #ddf5fd;
  padding: 15px;
}

.flex_maint_persons {
  display: flex;
  flex-wrap: wrap;
  margin: -10px;
}

.flex_maint_persons > * {
  width: calc(100% / 6);
  padding: 10px;
  text-align: center;
}

.flex_maint_persons .mini_icon {
  width: 60px;
  height: 60px;
  overflow: hidden;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  margin: 0px auto 10px;
}

.flex_maint_persons .mini_icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.flex_maint_persons h4 {
  margin-bottom: 0;
  font-size: 13px;
}

.flex_maint_persons > * ._innercol {
  background: #fbfbfb;
  padding: 10px;
  border: 1px solid #ddf5fd;
  cursor: pointer;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.flex_maint_persons > * ._innercol:hover,
.flex_maint_persons > * ._innercol.active {
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  border: 1px solid #ff6c40;
}

.mini_option_lbl_setting ul:before {
  right: 15%;
  left: 15%;
}

.overlay_link_dt {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.mini_web_btn i {
  margin-right: 0px;
}

.mini_web_btn {
  min-width: auto;
  width: 40px;
  border-radius: 50px;
  height: 40px;
  padding: 5px;
}

.cstm_mini_search_vertical .mini_web_btn {
  /* background: transparent;
    box-shadow: none;
    border-radius: 0;
    color: #222;
    width: auto;
    font-size: 20px; */
}

.mini_status_lbl_select {
  font-size: 13px;
  font-family: "Poppins Medium";
  padding: 2px 10px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
}

.mini_status_lbl_select option.yellow_color {
  color: #ff6c40;
}

.mini_status_lbl_select option.blue_color {
  color: #5883ff;
}

.mini_status_lbl_select option.green_color {
  color: #43b753;
}

.sp_lbl ul:before {
  left: 8%;
  right: 8%;
}

/* _____________additional_flex_prop______________ */
.additional_flex_prop {
  font-size: 13px;
}

.additional_flex_prop .additional_expired_info {
  flex: 1;
  padding: 3px;
}

.additional_flex_prop .additional_expired_info em {
  display: block;
  opacity: 0.8;
  font-size: 12px;
}

/* ________detail_listing_prop____ */
.detail_listing_prop {
  flex: 0.5;
  align-self: center;
  border-left: 1px solid #dddddd6b;
  padding-left: 35px;
  margin-left: 30px;
}

.listing_dt_heading {
  display: flex;
  flex-wrap: wrap;
}

/* .listing_dt_heading .inner_listing_prop_head{
    flex: 1;
} */
/* _____potential_flex____ */
.check_bg_ur {
  margin-top: 20px;
}

.potential_flex {
  justify-content: center;
  margin: -10px;
}

.potential_flex .col {
  width: 50%;
  padding: 10px;
}

.potential_flex .col .inner {
  border-radius: 10px;
  background: #fff;
  border: 1px solid #ddf5fd;
  padding: 10px;
  text-align: center;
  flex-direction: column;
}

.potential_flex .col .inner .image_sm {
  width: 40px;
  height: 40px;
  overflow: hidden;
  margin: 0px auto 6px;
}

.potential_flex .col .inner .image_sm img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.potential_flex .col .inner h5 {
  font-size: 13px;
}

/* ________send-sale-offer-form___________ */

.send-sale-offer-form h6,
.add-maintenance-form-prop h6 {
  opacity: 0.8;
  font-size: 13px;
}

.send-sale-offer-form label {
  font-size: 13px;
  opacity: 0.8;
  font-family: "Poppins Regular";
}

.rental-offer-page-dt .outer_list .lst > ul > li:nth-child(2) {
  width: 15%;
}

/* ____buyer-dps-flex____ */
.buyer-dps-flex p {
  margin-bottom: 0 !important;
  opacity: 0.8;
  font-size: 13px !important;
}

.buyer_icon {
  margin: 0px auto 5px;
}

.check_sale {
  cursor: pointer;
  font-size: 12px;
  width: fit-content;
  border-radius: 50px;
  color: #fff;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
}

.sale-dash-detail-offer .offer_cstm_ul li .inner.flex strong {
  flex: 1;
  margin-left: 10px;
  align-self: center;
}

.sale-dash-detail-offer .offer_cstm_ul.col1 {
  width: 30%;
}

.sale-dash-detail-offer .offer_cstm_ul.col1 li {
  width: 100%;
}

.chat_mini_icon {
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}

.chat_mini_icon a {
  font-size: 17px;
  width: fit-content;
  border-radius: 50px;
  color: #fff;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  background-color: #00acfc;
  padding-bottom: 2px;
  padding-right: 2px;
}

.chat_mini_icon a:hover,
.start_chat:hover {
  background-color: #ff6c40;
  color: #fff;
}

.start_chat {
  background-color: #00acfc;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  color: #fff;
  padding: 3px 10px;
  font-size: 12px;
}

/* ____landlord_card_offer_dash____ */

.landlord_card_offer_dash {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  overflow: hidden;
}

.landlord_card_offer_dash .main_heading {
  margin: -20px;
  margin-bottom: 20px;
  background: #00acfc;
  padding: 10px 20px;
  border-bottom: 1px solid #dddddd63;
  color: #fff;
}

.landlord_card_offer_dash .main_heading h3 {
  font-size: 18px;
  margin-bottom: 0;
}

.landlord_card_offer_dash .flex .image_icon {
  width: 60px;
  height: 60px;
  overflow: hidden;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.landlord_card_offer_dash .flex .image_icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.landlord_card_offer_dash .flex .txt {
  flex: 1;
  margin-left: 10px;
  align-self: center;
}

.landlord_card_offer_dash .flex .txt h5 {
  margin-bottom: 5px;
  font-family: "Poppins Medium";
}

/* ____offer_leases_pop_outer____ */

.offer_leases_pop_outer > ul > li {
  width: 100%;
  padding: 10px;
  border: 1px solid #dcf4ff;
  border-radius: 7px;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  cursor: pointer;
}

.offer_leases_pop_outer > ul > li:hover,
.offer_leases_pop_outer > ul > li.active {
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  border: 1px solid #3ec1fd;
}

.offer_leases_pop_outer > ul > li:not(:last-child) {
  margin-bottom: 10px;
}

.offer_leases_pop_outer .col_mini_col {
  width: 20%;
}

.offer_leases_pop_outer .col_mini_col .tenants_dps_flex {
  justify-content: flex-start;
}

.popup_for_lease .crosBtn {
  color: #000000;
}

.add_more_cost_btn {
  cursor: pointer;
  margin-bottom: 15px;
  font-size: 13px;
  background-color: #088fff;
  width: fit-content;
  /* padding: 3px 15px; */
  border-radius: 50px;
  color: #fff;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2px;
  border: none;
}

.add_more_cost_btn:hover {
  background-color: #ff6c40;
}

.lbl_align_center {
  justify-content: center !important;
}

.lease_tenants_creat_box .sender_bio {
  margin: -10px;
}

.lease_tenants_creat_box .sender_bio .sender_offer_col {
  width: calc(100% / 3);
  padding: 10px;
}

.lease_tenants_creat_box .sender_bio .inner {
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  border-radius: 10px;
  background: #fff;
  border: 1px solid #ddf5fd;
  padding: 20px;
}

.lease_tenants_creat_box .sender_bio .inner .crosBtn {
  top: -10px;
  right: -6px;
  background: #fff;
  color: #778ca3;
  font-size: 12px;
  border-radius: 75%;
  width: 21px;
  height: 21px;
  border: 1px solid #ddf5fd;
  padding-top: 1px;
  padding-left: 1px;
}

.lease_tenants_creat_box .sender_bio .inner .mini_icon {
  width: 60px;
  height: 60px;
  overflow: hidden;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.lease_tenants_creat_box .sender_bio .inner .mini_icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.lease_tenants_creat_box .sender_bio .inner .bio_info {
  flex: 1;
  margin-left: 10px;
  align-self: center;
}

.lease_tenants_creat_box .sender_bio .inner .bio_info .name h4 {
  font-size: 16px;
  margin-bottom: 6px;
  font-family: "Poppins SemiBold";
}

.mini-release_width {
  max-width: 500px;
  margin: 0px auto;
}

/* ____leases-flex-pay___ */
.leases-flex-pay .left_col {
  width: 50%;
  padding-right: 10px;
}

.leases-flex-pay .right_col {
  width: 50%;
  padding-left: 10px;
}

.leases-flex-pay .right_col:nth-child(3) {
  padding-left: 0;
  margin-top: 10px;
}

.landlord_lease_details {
  margin-top: 20px;
}

.big_lst_cost {
  font-size: 16px !important;
  background: #e0f1ff !important;
}

/* ____payment_history_dash____ */
.payment_history_dash .topBlk {
  padding: 20px;
  overflow: hidden;
  background: #fff;
  padding: 25px;
  border-radius: 7px;
  -webkit-box-shadow: 0 5px 20px rgb(18 15 56 / 7%);
  box-shadow: 0 5px 20px rgb(18 15 56 / 7%);
  margin-bottom: 20px;
}

.payment_fix_link {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.payment_history_dash .blkLst {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: -20px;
}

.payment_history_dash .blkLst > li {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background: transparent;
  color: #8b9593;
  padding: 25px 20px;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.payment_history_dash .blkLst > li .price_bold {
  color: #080a0f;
  font-size: 30px;
  line-height: 1;
  margin-bottom: 10px;
}

.payment_history_dash .blkLst > li strong {
  color: #ff5722;
  font-size: 12px;
  text-transform: uppercase;
}

.payment_history_dash .blkLst > li:not(:nth-child(1)) {
  border-left: 2px dashed #f1f4f7;
}

/* .payment_table{
    max-width: 1000px;
    margin: 40px auto 0px;
    padding: 0!important;
    box-shadow: none!important;
    border-radius: 0!important;
} */
.payment_history_dash .blockLst table {
  width: 100%;
}

.payment_info_popup ._inner {
  background: #fbfbfb;
}

.payment_info_popup .crosBtn {
  color: #000;
}

.payment_info_popup .payment_table {
  margin: 20px auto 0px !important;
}

.blockLst {
  padding: 0;
  overflow: hidden;
  background: #fff;
  border-radius: 7px;
  -webkit-box-shadow: 0 5px 20px rgb(18 15 56 / 7%);
  box-shadow: 0 5px 20px rgb(18 15 56 / 7%);
}

.blockLst table {
  width: 100%;
  font-size: inherit;
}

.blockLst table tr > *:nth-child(1) {
  padding-left: 20px;
}

.blockLst table tr .sm_icon {
  width: 30px;
  height: 30px;
}

.blockLst table tr > th {
  font-family: "Poppins SemiBold";
  font-weight: normal;
}

.payment-new-info .blockLst table tr > th:last-child {
  text-align: right;
}

.blockLst table tr > * {
  padding: 15px 7px;
  border-bottom: 1px solid #eee;
}

/* ____pop_transction_lst___ */
.pop_transction_lst {
  overflow: hidden;
  background: #fff;
  border-radius: 7px;
  -webkit-box-shadow: 0 5px 20px rgb(18 15 56 / 7%);
  box-shadow: 0 5px 20px rgb(18 15 56 / 7%);
  padding: 20px;
}

.pop_transction_lst ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.pop_transction_lst ul li {
  align-self: center;
}

.pop_transction_lst ul li .icoBlk {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.pop_transction_lst ul li .icoBlk .icon {
  width: 70px;
  height: 70px;
  overflow: hidden;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.pop_transction_lst ul li .icoBlk .icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.pop_transction_lst ul li .icoBlk .txt {
  flex: 1;
  margin-left: 15px;
  align-self: center;
}

.pop_transction_lst ul li .icoBlk .txt h5 {
  margin-bottom: 3px;
  font-family: "Poppins SemiBold";
}

.pop_transction_lst ul li .icoBlk .txt p small {
  color: #999;
}

.pop_transction_lst ul li:not(:first-child) {
  text-align: center;
}

/* _____________________invoices_______________ */

.invoice_toggle_outer .invoice_toggle {
  position: relative;
  display: block;
  background: #fff;
  border-radius: 7px;
  -webkit-box-shadow: 0 5px 20px rgb(18 15 56 / 7%);
  box-shadow: 0 5px 20px rgb(18 15 56 / 7%);
  margin-bottom: 10px;
  border-radius: 10px;
  transition: all ease 0.5s;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.invoice_toggle_outer .invoice_toggle > ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;
  padding: 20px;
  position: relative;
  cursor: pointer;
}

.invoice_toggle_outer .invoice_toggle > ul:after {
  content: "\f107";
  position: absolute;
  top: 0px;
  right: 20px;
  font-size: 20px;
  font-family: "FontAwesome";
  font-weight: bold;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.invoice_toggle_outer .invoice_toggle.active > ul:after {
  content: "\f106";
}

.invoice_toggle_outer .invoice_toggle > ul > li {
  align-self: center;
}

.invoice_toggle_outer .invoice_toggle > ul > li:nth-child(1) {
  flex: 1;
}

.invoice_toggle_outer .invoice_toggle > ul > li:nth-child(2) {
  flex: 1;
}

.invoice_toggle_outer .invoice_toggle > ul > li .total_invoices_lbl {
  width: 30px;
  height: 30px;
  background: #ff6c40;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  margin-right: 30px;
  font-size: 14px;
}

.invoice_bdy {
  border-top: 1px solid #f7f7f7;
  padding: 20px;
  display: none;
}

.invoice_bdy.active {
  display: block;
}

.invoice_bdy > .flex {
  max-width: 900px;
  margin: 0px auto;
}

.invoice_bdy > .flex.listing_features {
  margin-left: 0;
}

.invoice_bdy .colL {
  width: 50%;
  padding-right: 20px;
}

.invoice_bdy .colR {
  width: 50%;
  padding-left: 20px;
}

.invoice_bdy .colL .inner {
  background: #fbfbfb;
  border: 1px solid #e9f8ff;
}

.invoice_bdy .colL .inner .balance_lst {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;
  padding: 10px;
}

.invoice_bdy .colL .inner .balance_lst:not(:last-child) {
  border-bottom: 1px solid #e9f8ff;
}

.invoice_bdy .colL .inner .balance_lst.footer_invoice {
  border-top: 1px solid #e9e9e9;
  background: #f5f5f5;
  font-family: "Poppins SemiBold";
}

.invoice_bdy .colL h5 {
  font-family: "Poppins SemiBold";
  margin-bottom: 15px;
  font-size: 15px;
}

.invoice_bdy form h6 {
  opacity: 0.8;
  font-size: 13px;
}

.invoice_bdy .colR form > .flex > div {
  align-self: center;
  margin-bottom: 15px;
}

.btn_add_pay {
  margin-top: 24px;
}

.add_payment_cstm_btn {
  background: #00acfc;
  padding: 4px 17px;
  border-radius: 5px;
  color: #fff;
  font-size: 14px;
}

.add_payment_cstm_btn:hover {
  color: #fff;
}

.add_payment_cstm_btn i {
  font-size: 13px;
}

.invoice_bdy form .flex.lbl_invoce {
  gap: 30px;
}

.invoice_bdy form .flex.lbl_invoce label {
  opacity: 0.8;
  font-size: 13px;
}

.invoice_bdy form .bTn {
  justify-content: flex-end;
}

.check_flex_invoice {
  max-width: 600px;
  margin: 0px auto;
  background: #fff;
  border-radius: 7px;
  -webkit-box-shadow: 0 5px 20px rgb(18 15 56 / 7%);
  box-shadow: 0 5px 20px rgb(18 15 56 / 7%);
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
  padding: 20px;
}

.check_flex_invoice .checkout_blk {
  margin-bottom: 20px;
}

.check_flex_invoice .colL h5 {
  font-family: "Poppins SemiBold";
  margin-bottom: 15px;
}

.check_flex_invoice .colL .inner {
  background: #fbfbfb;
  border: 1px solid #e9f8ff;
  margin-bottom: 30px;
}

.check_flex_invoice .colL .inner .balance_lst {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;
  padding: 10px;
}

.check_flex_invoice .colL .inner .balance_lst:not(:last-child) {
  border-bottom: 1px solid #e9f8ff;
}

.check_flex_invoice .colL .inner .balance_lst.footer_invoice {
  border-top: 1px solid #e9e9e9;
  background: #f5f5f5;
  font-family: "Poppins SemiBold";
}

/* ___creat_invoice_form___ */
.creat_invoice_form {
  max-width: 700px;
  margin: 0px auto;
  background: #fff;
  border-radius: 7px;
  -webkit-box-shadow: 0 5px 20px rgb(18 15 56 / 7%);
  box-shadow: 0 5px 20px rgb(18 15 56 / 7%);
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
  padding: 20px;
}

.creat_invoice_form h6 {
  opacity: 0.8;
  font-size: 13px;
}

.additional_invoice_cost .inner_additional_cost {
  background: #fbfbfb;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  padding: 10px;
  border: 1px solid #ddf5fd;
}

.additional_invoice_cost h5 {
  font-family: "Poppins SemiBold";
  margin-bottom: 15px;
}

.flex_add_new_field {
  display: flex;
  gap: 15px;
  justify-content: flex-end;
  height: 100%;
  align-items: center;
  padding-top: 20px;
}

.additional_invoice_cost .add_new_field {
  width: 27px;
  height: 27px;
  background: #ff6c40;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  color: #fff;
  font-size: 13px;
  cursor: pointer;
}

.additional_invoice_cost
  .inner_additional_cost
  .new_add_invoice:not(:last-child) {
  margin-bottom: 20px;
}

.add_other_tenants_cst {
  background: #fbfbfb;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  padding: 15px 15px 0px;
  border: 1px solid #ddf5fd;
  margin-bottom: 15px;
  position: relative;
}

.mrgn_auto {
  margin: 0px auto;
}

.add_other_tenants_cst .closeBtn {
  position: absolute;
  top: -10px;
  right: -10px;
  width: 21px;
  height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  color: #778ca3;
  font-size: 12px;
  cursor: pointer;
  line-height: 1;
  text-align: center;
  border: 1px solid #aaa;
  border-radius: 75%;
  padding-top: 1px;
  padding-left: 1px;
}

.add_other_tenants_cst .closeBtn:before {
  content: "\2715";
}

/* ____amenities_blk_pro___ */
.amenities_blk_pro h5 {
  position: relative;
  text-align: center;
  margin: 10px 0 20px;
  z-index: 0;
}

.amenities_blk_pro h5:before {
  content: "";
  position: absolute;
  top: 3px;
  left: 0;
  right: 0;
  bottom: 0;
  height: 1px;
  background: #e0e0e0;
  margin: auto;
}

.amenities_blk_pro h5 span {
  position: relative;
  background: #fff;
  font-size: 20px;
  font-family: "Poppins Medium";
  padding: 0 6px 0;
  z-index: 2;
}

/* __screening_heading_af__ */
.screening_heading_af {
  margin-bottom: 20px;
  font-family: "Poppins Medium";
}

/* ___add maintenece form___ */
.permission_blk h5 {
  font-family: "Poppins Medium";
  font-size: 15px;
  line-height: 1.4;
  color: #666;
}

.permission_blk .permission_flex {
  gap: 20px;
}

.permission_blk .permission_flex label {
  color: #666;
  font-family: "Poppins Medium";
}

/* _______maintenence_list_team______ */
/* .maintenence_list_team{
    display: flex;
    flex-wrap: wrap;
    margin:-10px;
  } */
.maintenence_list_team .col {
  width: calc(100% / 3);
  padding: 10px;
}

.maintenence_list_team .col .inner {
  position: relative;
  background: #ffffff;
  border: 1px solid #e6f4ff;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  box-shadow: rgb(81 24 24 / 20%) 0 5px 10px 0;
}

.maintenence_list_team .col .inner .b_dy {
  padding: 25px;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}
.maintenence_list_team .col .inner .new_lnk_card_lnk {
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 3rem;
  height: 3rem;
}

.maintenence_list_team .col .inner .b_dy .b_dyinner {
  /* flex: 1; */
  align-self: center;
  max-width: 205px;
  width: 100%;
}

.maintenence_list_team .col .inner .b_dy .b_dyinner h5 {
  margin-bottom: 5px;
}
.maintenence_list_team .col .inner .user_info {
  max-width: 35%;
  text-align: center;
}
.maintenence_list_team .col .inner .b_dy .b_dyinner .rating_rate {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
}
.maintenence_list_team .col .inner .b_dy .b_dyinner .rating_rate .review_star {
  display: flex;
  gap: 0.3rem;
}
.maintenence_list_team
  .col
  .inner
  .b_dy
  .b_dyinner
  .rating_rate
  .review_star
  i {
  color: #088fff;
  align-self: center;
}
.maintenence_list_team
  .col
  .inner
  .b_dy
  .b_dyinner
  .rating_rate
  .review_star
  span {
  font-size: 1.4rem;
}
.maintenence_list_team .col .inner .b_dy .b_dyinner .rating_rate .rate_rate {
  font-size: 1.4rem;
}
.maintenence_list_team .col .inner .small_image {
  width: 60px;
  height: 60px;
  overflow: hidden;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.maintenence_list_team .col .inner .user_info .small_image {
  margin: 0 auto 1rem;
}
.maintenence_list_team .col .inner .user_info h5 {
  margin-bottom: 0;
  font-size: 1.3rem;
  font-family: "Poppins Regular";
}
.maintenence_list_team .col .inner .yellow_badge {
  margin-top: 1rem;
  background: #f2be00;
}

.maintenence_list_team .col .inner .small_image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.maintenence_list_team .col .inner .view_property_tooltip {
  position: relative;
}

.view_property_tooltip .property_mainti_tooltip {
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  border-radius: 10px;
  background: #f9f9f9;
  border: 1px solid #ddf5fd;
  padding: 20px;
  text-align: left;
  box-shadow: 0px 0px 11px 0px #dddddd80;
  position: absolute;
  width: 252px;
  top: -192px;
  left: -45px;
  height: 190px;
  overflow: auto;
  /* right: 0; */
  visibility: hidden;
  opacity: 0;
  transform: translateY(10px);
  -webkit-transform: translateY(10px);
  -moz-transform: translateY(10px);
  -ms-transform: translateY(10px);
  -o-transform: translateY(10px);
  z-index: 1;
}

.view_property_tooltip .view_pro {
  font-size: 12px;
  width: fit-content;
  color: rgb(26 26 26 / 65%);
  cursor: pointer;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  font-family: "Poppins Medium";
  text-decoration: underline;
}

.view_property_tooltip .view_pro:hover {
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  opacity: 0.8;
}

.maintenence_list_team .col .inner .cardFooter {
  position: absolute;
  bottom: 10px;
  right: -20px;
  border-top: none;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  opacity: 0;
  visibility: hidden;
}

.maintenence_list_team .col .inner .cardFooter .flex {
  flex-direction: column;
}

.maintenence_list_team .col .inner .cardFooter .flex a {
  width: 100%;
  padding: 2px 5px;
}

.maintenence_list_team .col .inner .cardFooter .footerCol:first-child {
  border-right: none;
}

.maintenence_list_team .col .inner .cardFooter .flex a span {
  width: 30px;
  height: 30px;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  border: 1px solid #dddddde6;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.maintenence_list_team .col .inner .cardFooter .flex a h5 {
  display: none;
}

.maintenence_list_team .col .inner:hover .cardFooter {
  right: 0;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  opacity: 1;
  visibility: visible;
}

.view_property_tooltip:hover .property_mainti_tooltip {
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  visibility: visible;
  opacity: 1;
  transform: translateY(0px);
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  -ms-transform: translateY(0px);
  -o-transform: translateY(0px);
}

.property_mainti_tooltip ul li {
  width: 100%;
}

.property_mainti_tooltip ul li:not(:last-child) {
  margin-bottom: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid #ddd;
}

.property_mainti_tooltip ul li a {
  font-size: 13px;
}

.termination_popup .txtGrp {
  margin-bottom: 15px;
}

/* ___service_payment_table___ */
.service_payment_table {
  max-width: 700px;
}

.payment_payment_new .blockLst {
  overflow: unset !important;
}

.payment_payment_new .tenants_dps_flex {
  justify-content: flex-start;
}

.payment_payment_new tr {
  position: relative;
}

.lease_cstm_pop tbody > tr {
  cursor: pointer;
}

.pay_ment_new_pop {
  padding-right: 50px;
}

.pay_ment_new_pop .pay_address {
  flex: 1;
  margin-right: 20px;
  display: flex;
}

.offer_dash_detail_page .pay_ment_new_pop .pay_address {
  padding: 20px;
}

.pay_ment_new_pop .pay_address .pro_img_pop {
  width: 100px;
  overflow: hidden;
  margin-right: 15px;
  border-radius: 7px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
}

.pay_ment_new_pop .pay_address .pro_img_pop img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.pay_ment_new_pop .pay_address .pay_cntnt_pop_new {
  align-self: center;
}

.pay_ment_new_pop .pay_address .pay_cntnt_pop_new p {
  margin-bottom: 0;
  font-family: "Poppins SemiBold";
}

.pay_ment_new_pop .pay_address .pay_cntnt_pop_new p:nth-child(2) {
  font-family: "Poppins Regular";
  font-size: 13px;
}

.pay_ment_new_pop .pay_address .pay_cntnt_pop_new div:last-child {
  display: flex;
  align-items: center;
}

.pay_ment_new_pop
  .pay_address
  .pay_cntnt_pop_new
  div:last-child
  .lease_heading_mini {
  color: #ff6c40;
  margin-bottom: 0;
  margin-top: 2px;
  width: fit-content;
  margin-right: 5px;
  font-size: 15px;
}

.pay_ment_new_pop .pay_address .pay_cntnt_pop_new div:last-child strong {
  font-size: 15px;
}

.pay_ment_new_pop .pay_bal_pop {
  align-self: center;
}

.pay_ment_new_pop .pay_bal_pop h5 {
  color: #ff6c40;
  margin-bottom: 3px;
}

.abt_btn {
  position: absolute;
  right: 0;
  top: -54px;
}

/* --------------new chnges----------- */

.hide_sm_clmn .lst > ul {
  position: relative;
  cursor: pointer;
}

.hide_sm_clmn .lst ul .icoBlk .txt p {
  margin-bottom: 0px;
}

.offer_dash_page .hide_sm_clmn .lst > ul > li:nth-last-child(1) {
  display: none;
}

.offer_dash_page .hide_sm_clmn .lst > ul > li:nth-last-child(2) {
  display: none;
}

.hide_last_child .lst > ul {
  position: relative;
  cursor: pointer;
}

.hide_last_child .lst ul .icoBlk .txt p {
  margin-bottom: 0px;
}

.hide_last_child .lst > ul > li:nth-last-child(1) {
  display: none;
}

.cell_lbl {
  display: none;
}

.desktop_lbl {
  display: block;
}

.hide_desktop_col {
  display: none;
}

.hide_desktop_col .sep_hypn {
  display: flex;
  margin: 3px 0px;
  gap: 19px;
  font-size: 85%;
  color: #999;
}

.hide_desktop_col .tenants_dps_flex li .ico {
  height: 3rem;
  min-width: 3rem;
  width: 3rem;
}

.hide_desktop_col .sep_hypn span {
  position: relative;
}

.hide_desktop_col .sep_hypn span:not(:first-child):before {
  position: absolute;
  content: "";
  left: -10px;
  top: 1px;
  width: 1px;
  height: 13px;
  background-color: #8080808a;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
}

.hide_desktop_col .single_line {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.hide_desktop_col .single_line span {
  width: 55px;
}

.hide_desktop_col .single_line div {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.show_lbl_com_cell {
  display: none;
}

.overlay_cell_lnk_all {
  display: none;
}

.cell_block_lbl_cell {
  display: block;
  width: fit-content;
}

.small_form_payment form fieldset {
  max-width: 600px;
}

.small_form_payment form fieldset .bTn {
  justify-content: center;
}

.justify_center_flex_crud {
  justify-content: center;
}

.commercial_dashboard_new .nav.nav-tabs {
  width: fit-content;
  /* margin-bottom: -40px; */
  position: relative;
  z-index: 1;
}

.commercial_dashboard_new .listing_tabs .nav.nav-tabs {
  width: 100%;
  /* margin-bottom: 30px; */
  border: none;
}

.listing_body .outer_list_c {
  margin-top: 30px;
}

.listing_body .listing_page_wrapper .outer_list_c {
  margin-top: 0;
}

.listing_body .outer_list_c.expired_outer_list_c {
  margin-top: 60px;
}

.commercial_dashboard_new .filter_status_offer {
  width: fit-content;
}

.choose_payment_method_method {
  justify-content: center;
  gap: 15px;
}

.choose_payment_method_method .lblBtn label {
  background-color: #9fe0fe;
  margin-left: 0;
  border-radius: 50px;
  padding: 10px 25px;
  color: #000;
  border: 1px solid transparent;
  transition: all 0.5s ease;
}

.choose_payment_method_method .lblBtn label.bankLbl {
  background-color: #ffdacf;
}

.choose_payment_method_method .lblBtn label:hover,
.choose_payment_method_method .lblBtn label.active,
.choose_payment_method_method .lblBtn.active label {
  border-color: inherit;
  transition: all 0.5s ease;
}

.bank_method_acc .listing_tabs {
  max-width: 1000px;
  margin: 0px auto -10px;
}

.show_cell_floor_olny,
.hide_dektop_dektop {
  display: none;
}

.auto_plus_mrgn {
  margin: 0px auto 15px;
}

.chk .cardSec .inner {
  width: 100%;
}

.chk .cardSec .inner > div,
.credit_card_inner > div {
  padding: 12px 12px;
  border: 1px solid #eee;
  border-radius: 6px;
}

.dash_form_blk form fieldset .form_blk.credit_card_inner > div {
  padding: 14px 12px;
}

.dash_form_blk form fieldset .form_blk.credit_card_inner > div > div {
  min-height: unset;
}

.credit_card_inner {
  margin-bottom: 15px;
  width: 100%;
}

.cmnBtn.bTn {
  margin-top: 15px;
}

.update_popup .form_blk {
  position: relative;
  padding-bottom: 20px;
}

.update_popup .form_blk p.error {
  position: absolute;
}

.extend_popup .justify_center_flex {
  margin-bottom: 10px;
}

.sale_colL {
  margin-bottom: 15px;
}

.offer_dash_detail_page .sale_offer_flex .colR {
  display: flex;
  flex-wrap: wrap;
}

.offer_dash_detail_page .sale_offer_flex .colR .sale_colL {
  width: 60%;
  padding-right: 1rem;
}

.offer_dash_detail_page .sale_offer_flex .colR .sale_colR {
  width: 40%;
  padding-left: 1rem;
}

.offer_dash_detail_page
  .sale_offer_flex
  .colR
  .sale_colR
  .sender_bio
  .sender_offer_col,
.offer_dash_detail_page .sale_offer_flex .colR .sale_colR .sale_upload_file {
  width: 100%;
  max-width: 100%;
}

.cover_letter_parent {
  margin-top: 1.5rem;
}

.cover_letter_parent .sale_upload_file {
  margin-top: 0;
}

.cover_letter_parent.offer_note .sale_upload_file {
  border: 1px solid #ff6c40;
  border-radius: 10px;
  background: #fff;
  border: 1px solid #ddf5fd;
  padding: 20px;
}

.offer_dash_detail_page
  .sale_offer_flex
  .colR
  .sale_colR
  .sale_upload_file.sale_offer_details_upload_file {
  border: 1px solid #ff6c40;
  border-radius: 10px;
  background: #fff;
  padding: 20px;
}

.offer_dash_detail_page .sale_offer_flex .colR .sale_colL .offer_cstm_ul li {
  width: 50%;
  padding: 11px;
}

.offer_dash_detail_page .sale_offer_flex .colR .bTn.offer_action_btn {
  max-width: 100%;
  width: 100%;
  align-self: center;
}

.offer_dash_detail_page .sale_offer_action_btn {
  margin-top: 15px;
  align-self: center;
}

.landlord_card_offer_dash .flex .txt p {
  font-size: 13px;
  opacity: 0.7;
}

.sale_label_header {
  justify-content: space-between;
}

.sale_label_header .settings_header,
.p_help {
  margin: 0;
}

.dash_form_blk form fieldset.createLeaseDetails {
  background: transparent;
  padding: 0;
  border: none;
}

.block_new_lease_new {
  margin-top: 4rem;
  margin-bottom: 4rem;
  background: #fbfbfb;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid #eee;
}

.block_new_lease_new ul li {
  display: flex;
  justify-content: space-between;
  padding: 1rem 1rem;
}

.block_new_lease_new ul li:not(:last-child) {
  border-bottom: 1px solid #eee;
}

.block_new_lease_new ul li:last-child {
  background: #eaeaea;
  font-size: 1.7rem;
}

.new_blk_tent_flex {
  column-gap: 1rem;
}

.new_blk_tent_flex .mini_icon {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  overflow: hidden;
}

.new_blk_tent_flex .mini_icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.new_blk_tent_flex h5 {
  flex: 1;
  margin-bottom: 0;
  align-self: center;
}

.block_new_lease_new ul li strong {
  flex: 1;
}

.block_new_lease_new ul li em {
  flex: 0.5;
  text-align: right;
  align-self: center;
}

.new_lease_pop_pop table thead {
  display: none !important;
}

.new_lease_pop_pop table tr {
  display: flex !important;
  flex-wrap: wrap;
  background: #fbfbfb;
  border: 1px solid #eee;
  border-radius: 10px;
}

.new_lease_pop_pop table tr > * {
  display: block !important;
  flex: unset !important;
}

.new_lease_pop_pop tr > td .mini-show-cell {
  display: block !important;
}

.new_lease_pop_pop table tr td {
  width: 50%;
}

.new_lease_pop_pop table tr td:first-child {
  width: 100%;
}

.paymentBlkFrm {
  padding: 20px;
  max-width: 700px;
  margin: 0 auto;
}

.lease_payment_flex .colL {
  width: 25%;
}

.lease_payment_flex {
  justify-content: center;
}

.lease_payment_flex .colR {
  width: 45%;
}

.lease_payment_flex .colR .paymentBlkFrm .bTn.offer_action_btn {
  margin-left: auto;
  margin-right: unset;
  margin-left: auto;
  margin-right: unset;
  max-width: unset;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}

.lease_payment_flex .colR .paymentBlkFrm .bTn.offer_action_btn ul {
  display: flex;
  align-items: center;
}

.lease_payment_flex .colR .paymentBlkFrm .creditCard {
  margin-bottom: 20px;
}

.lease_payment_flex .colR .paymentBlkFrm .chk .small_card_txt {
  border: none;
  padding: 5px 0;
  margin-bottom: 0px;
}

.lease_payment_flex .colR .paymentBlkFrm .chk .small_card_txt p {
  font-size: 16px;
}

.lease_payment_flex .colR .paymentBlkFrm {
  max-width: 100%;
}

.lease_payment_flex .colL .pay_address .pro_img_pop {
  width: 100%;
  margin: 0;
}

.lease_payment_flex .colL .pay_address {
  padding: 0;
  margin: 0;
}

.lease_payment_flex .colL .pay_ment_new_pop {
  padding: 0;
}

.lease_payment_flex .colL .pay_ment_new_pop .pay_address .pay_cntnt_pop_new {
  padding: 15px;
}

.choose_payment_method_method .lblBtn label {
  font-size: 13px;
  opacity: 0.8;
  font-family: "Poppins Regular";
}

.lease_payment_flex .chk .cardSec .inner,
.listing_wrap .popup .inner {
  width: 100%;
  margin: 0 auto;
}
.listing_wrap .popup .inner {
  padding: 5px 0;
}
.card_popup.popup .col-md-12 {
  width: 100%;
}
.card_popup.popup .cardSec {
  border: none;
}
.listing_wrap .popup .form_blk,
.listing_wrap .popup .inner {
  margin-bottom: 10px;
}
.note_text {
  max-width: 500px;
  margin: 10px auto;
  text-align: center;
}

.total_count_price_listing li {
  position: relative;
}

.processing_help_icon {
  position: absolute;
  left: 120px;
}

.lease_payment_flex .colR .paymentBlkFrm {
  overflow: unset;
}

.lease_action_btn {
  justify-content: center;
  gap: 10px;
}

.expense-tabs {
  margin-bottom: -40px;
  border: none;
}

.credit_plus {
  color: #43b753;
  /* font-size: 20px; */
}

.credit_flex {
  align-items: center;
  gap: 3px;
  justify-content: flex-end;
}

.contactFormBlk .contacts_flex_com {
  position: relative;
  background-color: #e7eef3;
  padding: 10px;
  margin: 10px 0px;
  border-radius: 7px;
}

.contactFormBlk .crosBtn {
  width: 20px !important;
  height: 20px;
  min-height: 20px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  color: #778ca3;
  border: 1px solid #aaa !important;
  border-radius: 75% !important;
  top: -5px;
  right: -5px;
  font-size: 14px;
}

/* =========date picker css======== */
.react-datepicker-popper {
  width: 100%;
}

.react-datepicker-popper:hover {
  border: none !important;
}

.react-datepicker {
  width: 100% !important;
  border: 1px solid #eeeeee !important;
  background-color: #ffffff !important;
  overflow: hidden;
}

.react-datepicker__month-container {
  float: unset !important;
  font-size: 1.3rem;
}

.react-datepicker__header {
  background-color: transparent !important;
  border-bottom: none !important;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  font-size: 1.7rem !important;
  font-family: "Poppins Medium" !important;
  margin-bottom: 1rem !important;
  border-bottom: 1px solid #ddd !important;
  padding-bottom: 1rem !important;
}

.react-datepicker__day-names {
  display: flex !important;
  gap: 1rem !important;
  margin-bottom: 0.5rem !important;
  font-family: "Poppins Medium" !important;
  opacity: 0.7 !important;
  justify-content: space-between;
}

.react-datepicker__week,
.react-datepicker__month-wrapper {
  display: flex !important;
  gap: 1rem !important;
  margin-bottom: 0.5rem !important;
  justify-content: space-between;
}

.react-datepicker__day {
  width: 3rem !important;
  height: 3rem !important;
  border-radius: 50px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  -webkit-border-radius: 50px !important;
  -moz-border-radius: 50px !important;
  -ms-border-radius: 50px !important;
  -o-border-radius: 50px !important;
}

.react-datepicker__day-name {
  width: 3rem !important;
}

.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
  background-color: #ff6c40 !important;
}

.react-datepicker__navigation {
  box-shadow: 0px 2px 7px 0px #ddd !important;
  top: 0.5rem !important;
  border-radius: 5px !important;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -ms-border-radius: 5px !important;
  -o-border-radius: 5px !important;
  background: #fff !important;
}

.react-datepicker__navigation.react-datepicker__navigation-icon--previous {
  right: unset !important;
}

.react-datepicker__navigation.react-datepicker__navigation-icon--next {
  left: unset !important;
}

.react-datepicker__navigation span {
  top: 5px !important;
  left: unset !important;
  right: unset !important;
}

.react-datepicker__navigation--next {
  right: 7px !important;
}

.react-datepicker__navigation--previous {
  left: 7px !important;
}

.react-datepicker__navigation span:before {
  border-color: #000 !important;
}

.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before {
  border-bottom-color: #ffffff !important;
}

.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::after {
  border-bottom-color: #ffffff !important;
}

.custom_tbl_tbl {
  max-width: 70%;
  margin: 0 auto;
}

.custom_ofer_heading {
  max-width: 70%;
  margin: 0 auto 2rem;
}

.listingFilterMargin {
  margin-bottom: 25px;
}
.errLst {
  padding-left: 20px;
  position: relative;
  margin-bottom: 3px;
}
.errLst:before {
  content: "";
  position: absolute;
  top: 9px;
  left: 0;
  background: #b25554;
  width: 6px;
  height: 6px;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
}

.maintenance_profile_flex .col {
  width: calc(100% / 3);
  padding: 10px;
}
@media screen and (max-width: 991px) {
  .maintenance_profile_flex .col {
    width: calc(100% / 2);
  }
}
@media screen and (max-width: 480px) {
  .maintenance_profile_flex .col {
    width: calc(100% / 1);
  }
}
.maintenance_profile_flex {
  margin: -1rem;
}
.maintenance_profile_flex .col .inner {
  border-radius: 10px;
  background: #fff;
  overflow: hidden;
  border: 1px solid #ddf5fd;
  position: relative;
}
.setting_blk.propertiesTab .maintenance_profile_flex .col .inner {
  border: 1px solid #dddddd80;
  /* box-shadow: rgb(81 24 24 / 20%) 0 5px 10px 0; */
}
.maintenance_profile_flex .col .inner .cntnt {
  padding: 10px 15px 10px;
}
.maintenance_profile_flex .col .inner .image {
  width: 100%;
  position: relative;
  padding-bottom: 60%;
  overflow: hidden;
}
.maintenance_profile_flex .col .inner .image img {
  position: absolute;
  height: 100%;
  object-fit: cover;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.maintenance_profile_flex .col .inner .image .show_lbl_overlay {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #ff6c40;
  border-radius: 50px;
  padding: 3px 10px;
  font-size: 12px;
  color: #fff;
}
.maintenance_profile_flex .col .inner .cntnt ul.flex {
  gap: 20px;
  margin: 5px 0px;
}
.maintenance_profile_flex .col .inner .cntnt .flex li {
  font-size: 14px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  align-self: center;
}
.maintenance_profile_flex .col .inner .cntnt .flex li span {
  flex: 1;
  color: #848484;
}
.maintenance_profile_flex .col .inner p {
  font-size: 12px;
  opacity: 0.6;
}
.maintenance_profile_flex .col .inner .cntnt .flex li:not(:first-child):before {
  position: absolute;
  content: "";
  left: -10px;
  top: 1px;
  width: 1px;
  height: 20px;
  background-color: #8080808a;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
}
.maintenance_profile_flex .col .inner .cntnt h3 {
  font-size: 17px;
  color: #000000bd;
  font-family: "Poppins SemiBold";
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: 5px;
}
.maintenance_profile_flex .col .inner .btn_blk {
  text-align: right;
}
.maintenance_profile_flex .col .inner .btn-act {
  border: none;
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
  padding: 3px 15px;
  font-size: 1.3rem;
  color: #fff;
}
.maintenance_profile_flex .col .inner .btn-act.deny {
  background-color: #ff6c40;
}

.maintenance_profile_flex .col .inner .btn-act.accept {
  background-color: #249600;
}
.new_field_new div {
  min-height: unset !important;
  border: none !important;
}
.new_field_new .lblBtn label {
  font-family: "Poppins Regular";
  font-size: 1.4rem;
}

.main_mentenance_blk {
  max-width: 900px;
  margin: 0 auto;
}
.maintenance_tabs {
  margin: 0 auto 2rem;
}
.maintenance_tabs > li.active,
.maintenance_tabs > li.active:focus,
.maintenance_tabs > li.active:hover {
  color: #ff6c40;
  cursor: default;
  background-color: #fff0;
  border: 1px solid #ddd0;
  border-bottom-color: transparent;
  transition: all 0.5s ease;
  box-shadow: none;
}

.maintenance_tabs > li {
  text-transform: uppercase;
  font-family: "Poppins Medium";
  font-size: 14px;
  color: #555;
  cursor: pointer;
  padding-right: 2rem;
  transition: all 0.5s ease;
}

.maintenance_tabs > li:hover {
  border-color: #eee0 #eee0 #ddd0;
  color: #ff6c40;
}

.cat_new_ins {
  display: flex;
  flex-wrap: wrap;
  gap: 1.2rem;
}
.cat_new_ins p {
  font-size: 13px;
}
.cat_new_ins > div {
  display: flex;
  position: relative;
}
.cat_new_ins > div img {
  width: 2.5rem;
  height: 2.5rem;
  filter: brightness(0) invert(0.4);
    -webkit-filter: brightness(0) invert(0.4);
}
.cat_new_ins > div:before {
  content: attr(data-skill);
  position: absolute;
  top: -3rem;
  width: -moz-max-content;
  width: max-content;
  padding: 0.5rem 1rem;
  background-color: #000;
  border-radius: 4px;
  opacity: 0;
  visibility: hidden;
  transition: all ease 0.5s;
  font-size: 1.4rem;
  color: #fff;
  z-index: 99;
}
.cat_new_ins > div:hover:before {
  visibility: visible;
  opacity: 1;
  top: -4rem;
  transition: all ease 0.5s;
}
.cat_new_ins > div span {
  flex: 1;
  font-size: 1.3rem;
  align-self: center;
}

.pt_2 {
  padding-top: 1rem;
}

.maintenance_new_clr {
  background-color: #f0f8ff;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  border: 1px solid #f8f8f8;
  padding: 2rem;
  margin-bottom: 2rem;
}
.maintenance_new_clr h5 {
  margin-bottom: 2rem;
}

.new_blk_cost_add.new_clr .inner_blk {
  background-color: #ffffff;
  border: 1px solid #f3f3f3 !important;
}
.new_blk_cost_add.new_clr .inner_blk .closeBtn {
  background: #000;
  color: #ffffff;
}

.review_read_blk.new_clr {
  background-color: #f0f8ff;
  border: 1px solid #f3f3f3;
}

.search_staff_form {
  gap: 1rem;
  margin: 2rem 0;
  align-items: center;
}
.search_staff_form .search_field {
  flex: 1;
  position: relative;
}
.search_staff_form .search_field button {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0.5rem;
  border: none;
  background-color: transparent;
}
.search_staff_form .search_field .css-b62m3t-container .css-13cymwt-control,
.search_staff_form .search_field .css-b62m3t-container .css-t3ipsp-control {
  height: 46px;
}
.search_staff_form .search_field .flex {
  gap: 1rem;
}
.search_staff_form .search_field .flex .col_select {
  flex: 1;
}
.search_staff_form .gap_line_divide {
  display: block;
  background: #fff;
  width: 100%;
}
.col_select .txtBox:hover,
.col_select .txtBox:focus {
  border-color: #088fff;
}
.add-service_new_blk {
  max-width: 100% !important;
}

.result_maintenance_flex .flex {
  margin: -1rem;
}
.result_maintenance_flex .flex .card_col {
  width: calc(100% / 3);
  padding: 1rem;
}
.result_maintenance_flex .flex .card_col .inner_card {
  display: flex;
  background-color: #ffffff;
  border: 1px solid #f3f3f3;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  box-shadow: rgb(81 24 24 / 20%) 0 5px 10px 0;
  padding: 2rem;
  position: relative;
  position: relative;
}
.popup_maintenance_new {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  cursor: pointer;
}
.result_maintenance_flex .flex .card_col .inner_card .info_user {
  max-width: 35%;
  text-align: center;
}
.result_maintenance_flex .flex .card_col .inner_card .info_user .icon_user {
  width: 5rem;
  height: 5rem;
  overflow: hidden;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  margin: 0 auto 0.2rem;
}
.result_maintenance_flex .flex .card_col .inner_card .info_user .icon_user img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.result_maintenance_flex .flex .card_col .inner_card .info_user span {
  font-size: 1.3rem;
}

.result_maintenance_flex .flex .card_col .inner_card .detail_user {
  /* flex: 1;
  align-self: center;
  padding-left: 1rem;
  padding-right: 1.5rem; */
  flex: unset;
  align-self: center;
  padding-left: 1rem;
  padding-right: 1.5rem;
  max-width: 220px;
  width: 100%;
}
.result_maintenance_flex .flex .card_col .inner_card .rating_rate {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
}
.result_maintenance_flex .flex .card_col .inner_card .rating_rate .review_star {
  display: flex;
  gap: 0.3rem;
}
.result_maintenance_flex
  .flex
  .card_col
  .inner_card
  .rating_rate
  .review_star
  i {
  color: #088fff;
  align-self: center;
}
.result_maintenance_flex
  .flex
  .card_col
  .inner_card
  .rating_rate
  .review_star
  span {
  font-size: 1.4rem;
}
.result_maintenance_flex .flex .card_col .inner_card .rating_rate .rate_rate {
  font-size: 1.4rem;
}
.result_maintenance_flex .flex .card_col .inner_card .services_count {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.result_maintenance_flex
  .flex
  .card_col
  .inner_card
  .services_count
  .service_icon {
  width: 2.5rem;
  height: 2.5rem;
  position: relative;
}
.result_maintenance_flex
  .flex
  .card_col
  .inner_card
  .services_count
  .service_icon
  img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.result_maintenance_flex
  .flex
  .card_col
  .inner_card
  .services_count
  .service_icon:before {
  content: attr(data-hover);
  position: absolute;
  top: -3rem;
  width: max-content;
  padding: 0.5rem 1rem;
  background-color: #000;
  border-radius: 4px;
  opacity: 0;
  visibility: hidden;
  transition: all ease 0.5s;
  font-size: 1.4rem;
  color: #fff;
  z-index: 99;
}
.result_maintenance_flex
  .flex
  .card_col
  .inner_card
  .services_count
  .service_icon:hover:before {
  opacity: 1;
  visibility: visible;
  transition: all ease 0.5s;
  top: -4rem;
}

.result_maintenance_flex .flex .card_col .inner_card .services_count span {
  align-self: center;
  font-size: 1.4rem;
}
.result_maintenance_flex
  .flex
  .card_col
  .inner_card
  .services_count
  span.more_service {
  position: relative;
  cursor: pointer;
}
.result_maintenance_flex
  .flex
  .card_col
  .inner_card
  .services_count
  span.more_service
  .after_hover_blk {
  width: 17rem;
  background: #fff;
  border-radius: 6px;
  padding: 1rem;
  position: absolute;
  bottom: 0;
  left: 5rem;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: all 0.5s ease;
  z-index: 1;
}
.result_maintenance_flex
  .flex
  .card_col
  .inner_card
  .services_count
  span.more_service:hover
  .after_hover_blk {
  opacity: 1;
  visibility: visible;
  left: 3rem;
  pointer-events: unset;
  transition: all 0.5s ease;
}
.result_maintenance_flex
  .flex
  .card_col
  .inner_card
  .services_count
  span.more_service
  .after_hover_blk
  .service_icon_hover {
  width: 100%;
  display: flex;
  gap: 0.2rem;
}
.result_maintenance_flex
  .flex
  .card_col
  .inner_card
  .services_count
  span.more_service
  .after_hover_blk
  .service_icon_hover
  img {
  width: 2.5rem;
  height: 2.5rem;
}
.result_maintenance_flex
  .flex
  .card_col
  .inner_card
  .services_count
  span.more_service
  .after_hover_blk
  .service_icon_hover
  span {
  flex: 1;
}
.result_maintenance_flex .flex .card_col .inner_card .action_icons {
  position: absolute;
  top: 1rem;
  right: 1rem;
  display: flex;
  gap: 0.5rem;
}
.result_maintenance_flex .flex .card_col .inner_card .action_icons button {
  width: 2.5rem;
  height: 2.5rem;
}
.result_maintenance_flex .flex .card_col .inner_card .action_icons a {
  width: 2.5rem;
  height: 2.5rem;
  align-self: flex-end;
}

.result_maintenance_flex .flex .card_col .inner_card .yellow_badge {
  margin-top: 1rem;
  background: #f2be00;
}

.invite_popups_all ._inner {
  padding: 4rem;
}

.detail_pop_outer ._inner {
  max-width: 880px;
}
.form_info_popup {
  background: #f0f8ff;
  padding: 2rem;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.form_info_popup .image_icon {
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  overflow: hidden;
  margin-bottom: 2rem;
  border: 1px solid #088fff;
}
.form_info_popup .image_icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.detail_pop_cntnt li strong {
  flex: 0.2;
}
.detail_pop_cntnt li em,
.detail_pop_cntnt li div {
  flex: 1;
}
.detail_pop_cntnt.flex_flex {
  display: flex;
  gap: 1rem;
}
.detail_pop_cntnt.flex_flex li {
  flex: 1;
}

.more_service.more_more {
  position: relative;
  cursor: pointer;
}
.more_service.more_more .after_hover_blk {
  width: 17rem;
  background: #fff;
  border-radius: 6px;
  padding: 1rem;
  position: absolute;
  bottom: 0;
  left: 5rem;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: all 0.5s ease;
  z-index: 1;
}
.more_service.more_more:hover .after_hover_blk {
  opacity: 1;
  visibility: visible;
  left: 3rem;
  pointer-events: unset;
  transition: all 0.5s ease;
}
.more_service.more_more .after_hover_blk .service_icon_hover {
  width: 100%;
  display: flex;
  gap: 0.2rem;
}
.more_service.more_more .after_hover_blk .service_icon_hover img {
  width: 2.5rem;
  height: 2.5rem;
}
.more_service.more_more .after_hover_blk .service_icon_hover span {
  flex: 1;
}

.cmn_gap_b {
  margin-bottom: 1rem;
}

.property_new_lst_new {
  display: flex;
  flex-wrap: wrap;
  margin: -1rem;
  margin-top: 1rem;
}
.property_new_lst_new li {
  width: 50%;
  padding: 5px;
}
.property_new_lst_new li .inner {
  border: 1px solid #eee;
  padding: 10px;
  background: #fefefe;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}
.center_important {
  justify-content: center !important;
  display: flex;
  gap: 1rem;
}
.center_important button {
  padding: 8px !important;
  width: 3.5rem;
  height: 3.5rem;
}
.center_important button.accept {
  background-color: #078fff !important;
}

.hr_rate {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: #fff;
  font-size: 1.3rem;
  padding: 0.3rem 1rem;
  border-radius: 4px;
  box-shadow: 0px 2px 3px 3px #00000040;
}

.property_hover_show {
  position: absolute;
  top: 140px;
  right: 0px;
  left: 0;
  background: #fff;
  font-size: 1.4rem;
  padding: 2rem;
  border-radius: 4px;
  border: 1px solid #eaf8ff;
  box-shadow: rgb(81 24 24 / 20%) 0 5px 10px 0;
  opacity: 0;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  visibility: hidden;
}
.property_hover_show ul li {
  display: block;
}
.maintenence_list_team .col .inner:hover .property_hover_show {
  opacity: 1;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  visibility: visible;
  top: 150px;
}

.result_maintenance_flex
  .flex
  .card_col
  .inner_card:hover
  .property_hover_show {
  opacity: 1;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  visibility: visible;
  top: 150px;
}

.property_hover_show.more_no {
  opacity: 0 !important;
  transition: all 0.5s ease !important;
  -webkit-transition: all 0.5s ease !important;
  -moz-transition: all 0.5s ease !important;
  -ms-transition: all 0.5s ease !important;
  -o-transition: all 0.5s ease !important;
  visibility: hidden !important;
  top: 140px !important;
}
.top_gap_common {
  margin-top: 3rem;
}

.new_form_new .form_blk {
  margin-bottom: 15px;
  position: relative;
}

.over_flow_unset {
  overflow: unset !important;
  padding: 1rem 0rem !important;
  background: #daecfa !important;
}

.over_flow_unset .padding-left-must {
  padding-left: 17px;
}
.over_flow_unset .padding-right-must {
  padding-right: 17px;
}
.profileBtn {
  background: #ff6c40;
  padding: 0.2rem 1rem;
  border-radius: 25px;
  font-size: 1.2rem;
  color: #fff;
}
.profileBtn:hover {
  color: #fff;
}
.offer_dash_page .outer_list .lst .maintenance_property_blk li:nth-child(1) {
  width: 26%;
}
.offer_dash_page .outer_list .lst .maintenance_property_blk li:nth-child(2) {
  width: 20%;
}
.status_price {
  display: flex;
  gap: 10px;
  align-items: center;
}
.react-datepicker-popper[data-placement^="bottom"] {
  z-index: 9;
}

.css-t3ipsp-control {
  border: 1px solid #FF6C47 !important;
  box-shadow: none !important;
}
.css-t3ipsp-control:focus,
.css-t3ipsp-control:hover {
  border: 1px solid #FF6C47 !important;
  box-shadow: none !important;
}
.ach_acc_wrap .css-b62m3t-container {
  transition: all ease 0.5s;
  -webkit-transition: all ease 0.5s;
  -moz-transition: all ease 0.5s;
  -ms-transition: all ease 0.5s;
  -o-transition: all ease 0.5s;
}
.ach_acc_wrap .css-b62m3t-container:hover {
  border-color: #ff6c40 !important;
  transition: all ease 0.5s;
  -webkit-transition: all ease 0.5s;
  -moz-transition: all ease 0.5s;
  -ms-transition: all ease 0.5s;
  -o-transition: all ease 0.5s;
}
.new_field_new .ach_acc_wrap > div {
  padding: 4px 0px !important;
}
.ach_acc_wrap .css-1nmdiq5-menu {
  padding: 0 !important;
  margin: 0 !important;
}
.invite_wrap > div > div {
  border-color: #eeeeee;
  border-radius: 6px;
  padding: 4px 0;
}
.invite_wrap .css-13cymwt-control {
  transition: all ease 0.5s;
  -webkit-transition: all ease 0.5s;
  -moz-transition: all ease 0.5s;
  -ms-transition: all ease 0.5s;
  -o-transition: all ease 0.5s;
}
.invite_wrap .css-13cymwt-control:hover {
  border-color: #ff6c40;
  transition: all ease 0.5s;
  -webkit-transition: all ease 0.5s;
  -moz-transition: all ease 0.5s;
  -ms-transition: all ease 0.5s;
  -o-transition: all ease 0.5s;
}
.invite_wrap .css-t3ipsp-control {
  border: 1px solid #eeeeee !important;
  box-shadow: none !important;
}
.invite_wrap .css-t3ipsp-control:focus,
.invite_wrap .css-t3ipsp-control:hover {
  border: 1px solid #ff6c40 !important;
  box-shadow: none !important;
}
