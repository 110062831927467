/*
409df1
0082f7
site color
*/

/*
|----------------------------------------------------------------------
|       Body Main Css
|----------------------------------------------------------------------
*/

@font-face {
  font-family: "Poppins Light";
  src: url("./assets/fonts/Poppins-Light.ttf");
}

@font-face {
  font-family: "Poppins Italic";
  src: url("./assets/fonts/Poppins-Italic.ttf");
}

@font-face {
  font-family: "Poppins Regular";
  src: url("./assets/fonts/Poppins-Regular.ttf");
}

@font-face {
  font-family: "Poppins Medium";
  src: url("./assets/fonts/Poppins-Medium.ttf");
}

@font-face {
  font-family: "Poppins SemiBold";
  src: url("./assets/fonts/Poppins-SemiBold.ttf");
}

@font-face {
  font-family: "Poppins Bold";
  src: url("./assets/fonts/Poppins-Bold.ttf");
}

/* @font-face {
  font-family: "Poppins Light";
  src: url("./assets/fonts/Lato_font/Lato-Light.ttf");
}

@font-face {
  font-family: "Poppins Italic";
  src: url("./assets/fonts/Lato_font/Lato-Italic.ttf");
}

@font-face {
  font-family: "Poppins Regular";
  src: url("./assets/fonts/Lato_font/Lato-Regular.ttf");
}

@font-face {
  font-family: "Poppins Medium";
  src: url("./assets/fonts/Lato_font/Lato-Medium.ttf");
}

@font-face {
  font-family: "Poppins SemiBold";
  src: url("./assets/fonts/Lato_font/Lato-Semibold.ttf");
}

@font-face {
  font-family: "Poppins Bold";
  src: url("./assets/fonts/Lato_font/Lato-Bold.ttf");
} */

.light {
  font-family: "Poppins Light" !important;
}

.italic {
  font-family: "Poppins Italic" !important;
}

.regular {
  font-family: "Poppins Regular" !important;
}

.medium {
  font-family: "Poppins Medium" !important;
}

.semi {
  font-family: "Poppins SemiBold" !important;
}

.bold {
  font-family: "Poppins Bold" !important;
}

.price {
  font-family: "Poppins Bold";
}

:root {
  --chevron: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="300px" height="300px" fill="%23080a0f" viewBox="-155 247 300 300" style="enable-background:new -155 247 300 300;"><polygon points="78.6356201,306.8178101 -5.0166931,390.4367371 -88.6356277,306.8178101 -137, 355.1821899 -5.0166931,487.1821899 127,355.1821899 "/></svg>');
  --tick: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="300px" height="300px" fill="%23fff" viewBox="0 0 300 300" style="enable-background:new 0 0 300 300;" xml:space="preserve"><polygon points="115.7434006,270.8831787 0,173.503006 21.3676376,148.106369 111.6297684,224.0477905 274.5331421,29.1168175 300,50.3998222"/></svg>');
}

html {
  height: 100%;
}

html.flow {
  overflow: hidden;
}

body {
  position: relative;
  min-height: 100%;
  /* background-color: #f5f6f8; */
  background-color: #fff;
  color: #4c4c4c;
  font-size: 15px;
  font-family: "Poppins Regular";
  margin: 0;
  padding: 0;
  line-height: 1.6;
  word-break: break-word;
  transition: all ease 0.5s;
  overflow-x: hidden;
  counter-reset: my-sec-counter;
}

body {
  display: flex;
  flex-flow: column;
}

body.flow {
  overflow: hidden !important;
}

body > main {
  flex: 1;
  padding-top: 72px;
  overflow: hidden;
}

/* body > main[index]{
	padding-top: 75;
} */

::selection {
  background: #ff6c40;
  color: #fff;
}

::-moz-selection {
  background: #ff6c40;
  color: #fff;
}

body a {
  color: #080a0f;
  word-break: break-word;
}

body a,
body span {
  display: inline-block;
  text-decoration: none;
}

body a:hover {
  color: #ff6c40;
}

body a:focus,
body a:hover {
  outline: none !important;
  text-decoration: none !important;
}

ul {
  margin: 0;
  padding: 0;
}

ul li {
  display: inline-block;
}

ul li a {
  transition: all ease 0.5s;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins Regular";
  line-height: 1.2;
  margin: 0 0 10px;
}

p {
  margin: 0 0 15px;
}

p:nth-last-child(1) {
  margin: 0;
}

p > a {
  color: #3c9cdb;
}

p > a:hover {
  color: #2115dc;
}

.relative {
  position: relative;
}

.tableDv {
  display: table;
  width: 100%;
  height: 100%;
}

.tableCell {
  display: table-cell;
  vertical-align: middle;
}

.toggle {
  position: absolute;
  top: 19px;
  right: 20px;
  /* display: flex; */
  display: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 10px 0px;
  transition: all ease 0.5s;
  z-index: 4;
}

.toggle span,
.toggle span:before,
.toggle span:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  height: 2px;
  background: #4c4c4c;
  border-radius: 50px;
  transition: all ease 0.5s;
}

.toggle span {
  position: relative;
  display: block;
  width: 15px;
  margin: 8px auto;
}

.toggle span:before {
  top: -7px;
  width: 10px;
}

.toggle span:after {
  bottom: -7px;
  width: 20px;
}

.toggle.active span {
  width: 22px;
  background: transparent !important;
}

.toggle.active span:before,
.toggle.active span:after {
  top: 0;
  width: 22px;
}

.toggle.active span:before {
  transform: rotate(405deg);
}

.toggle.active span:after {
  transform: rotate(-45deg);
}

.blockquote {
  background: #fafafa;
  color: #151d23;
  padding: 10px 10px 10px 20px;
  border: 1px solid #f0f5f9;
  border-left: 4px solid #ff6c40;
}

.left_nav {
  position: absolute;
  top: 14px;
  color: #4c4c4c;
  left: 7%;
  width: 380px;
}

.left_nav button,
.search_nav button {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 46px;
  padding: 0;
  background-color: transparent;
  color: #ff6c40;
  box-shadow: none;
  font-size: 18px;
}

.search_nav {
  position: absolute;
  width: 337px;
  margin: 0px auto;
  left: 0;
  right: 0;
  top: 13px;
  bottom: 0;
}

.left_nav .txtBox,
.search_nav .txtBox {
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  padding-left: 20px;
  padding-right: 50px;
}

.left_nav .txtBox::placeholder,
.search_nav .txtBox::placeholder {
  color: rgb(134, 134, 134);
  font-size: 13px;
}

.left_nav button i,
.search_nav button i {
  margin-right: 0px;
}

.hide_search_bar .left_nav {
  display: none;
}

.left_nav .toggle em {
  margin-left: 15px;
  color: #4c4c4c;
  font-size: 14px;
}

.left_nav ul {
  align-self: center;
  display: flex;
  gap: 20px;
}

.left_nav ul li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 36px;
  /* color: #080a0f; */
  color: #4c4c4c;
  text-align: center;
  font-size: 14px;
}

.left_nav ul li a:focus {
  color: #4c4c4c;
}

#nav > li #cta {
  display: none;
}

.left_nav ul li a:hover,
.left_nav ul li a.active {
  color: #ff6c40;
}

.left_nav ul li a i {
  width: 18px;
  height: 18px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  background-color: #4c4c4c;
  color: #fff;
  margin-right: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}

.heading {
  position: relative;
  margin-bottom: 25px;
}

.heading > em {
  color: #ff6c40;
}

.sub_heading {
  color: #ff6c40;
  font-size: 14px;
  font-family: "Poppins Medium";
  text-transform: uppercase;
}

p.pre {
  color: #8b9593;
  margin: -10px 0 30px;
}

.color {
  color: #ff6c40 !important;
}

.background {
  background: #ff6c40 !important;
}

/*
|----------------------------------------------------------------------
|       Body Main Button
|----------------------------------------------------------------------
*/

.webBtn {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 120px;
  height: 46px;
  background: #ff6c40;
  color: #fff;
  font-size: 14px;
  font-family: "Poppins Medium";
  text-align: center;
  padding: 0 30px;
  white-space: nowrap;
  border: 0;
  border-radius: 50px;
  outline: none !important;
  box-shadow: rgb(81 24 24 / 20%) 0 5px 10px 0;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
}

.webBtn:hover,
.webBtn:focus {
  background: #000000;
  color: #fff;
}

.smBtn {
  min-width: 40px;
  height: 36px;
  padding: 0 20px;
}

.mdBtn {
  min-width: 80px;
  height: 40px;
  padding: 0 20px;
}

.lgBtn {
  height: 54px;
  font-size: 16px;
  padding: 0 40px;
}

.colorBtn {
  background: #088fff;
}

.blankBtn {
  background: transparent;
  color: #ff6c40;
}

.blankBtn:hover {
  background: #eeccd1;
  color: #ff6c40;
}

.lightBtn {
  background: #eeccd1;
  color: #ff6c40;
}

.lightBtn:hover {
  background: #cd6675;
  color: #ff6c40;
}

.labelBtn {
  min-width: initial;
  height: auto;
  background: transparent;
  color: #ff6c40;
  padding: 0;
  border: 0;
  box-shadow: none;
}

.labelBtn:hover {
  background: transparent;
  color: #080a0f;
}

.labelBtn > i {
  font-size: 100%;
}

.simpleBtn {
  background: #006fc0;
  color: #ffffff;
  /* border: 1px solid #b5bcc0; */
}

.simpleBtn:hover {
  background: #fff;
  color: #006fc0;
  /* border-color: #ff6c40; */
}

.simpleBtn.icoBtn > img {
  filter: none;
}

.grayBtn {
  background: #fff;
  color: #8b9593;
  border: 1px solid #eee;
}

.grayBtn:hover {
  background: #fafafa;
  color: #8b9593;
}

.borderBtn {
  border: 1px solid #ff6c40;
}

.arrowBtn {
  display: flex !important;
  width: 46px;
  min-width: 46px;
  padding: 0 5px;
}

.arrowBtn > i {
  font-weight: bold;
  margin: 0 !important;
}

.arrowBtn > img {
  width: 18px;
}

.icoBtn > img {
  width: 18px;
  margin-right: 10px;
  filter: brightness(0) invert(1);
}

.roundBtn {
  border-radius: 50px;
}

.blockBtn {
  width: 100%;
}

.blockBtn em {
  flex: 1;
  text-align: left;
}

.webBtn i {
  /* font-size: 140%; */
  margin-right: 10px;
}

.webBtn.smBtn i {
  font-size: 120%;
}

.webBtn i.fi-arrow-right {
  font-weight: bold;
  margin-left: 10px;
  margin-right: 0;
}

.webBtn i.fi-arrow-left {
  font-weight: bold;
  margin-left: 0;
  margin-right: 10px;
}

/*_____ webBtn spinner _____*/

.webBtn .spinner,
.loadingDisabled .spinner,
footer form button .spinner {
  width: auto;
  height: auto;
  background: inherit;
  border-radius: inherit;
  overflow: hidden;
}

footer form button .spinner {
  width: 35px;
  top: 12px !important;
}

.webBtn .spinner ~ i,
.loadingDisabled .spinner ~ i footer form button .spinner ~ i {
  visibility: hidden;
}

.webBtn .spinner,
.webBtn .spinner:before,
.webBtn .spinner:after,
.loadingDisabled .spinner,
.loadingDisabled .spinner:before,
.loadingDisabled .spinner:after,
footer form button .spinner,
footer form button .spinner:before,
footer form button .spinner:after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.loadingDisabled .spinner:before,
.loadingDisabled .spinner:after {
  content: "";
  width: 70px;
  height: 70px;
  border: 3px solid;
  animation: spinner 0.5s linear infinite;
  border-radius: 75%;
  margin: auto;
}

.webBtn .spinner:before,
.webBtn .spinner:after,
footer form button .spinner:before,
footer form button .spinner:after {
  content: "";
  width: 18px;
  height: 18px;
  border: 3px solid;
  animation: spinner 0.5s linear infinite;
  border-radius: 75%;
  margin: auto;
}

.loadingDisabled .spinner:before,
.webBtn .spinner:before,
footer form button .spinner:before {
  border-right-color: transparent;
  border-bottom-color: transparent;
}

.loadingDisabled .spinner:after,
.webBtn .spinner:after,
footer form button .spinner:after {
  border-top-color: transparent;
  border-left-color: transparent;
  opacity: 0.5;
}

.webBtn.smBtn .spinner:before,
.webBtn.smBtn .spinner:after,
footer form button .spinner:before,
footer form button .spinner:after {
  width: 14px;
  height: 14px;
}

.webBtn.lgBtn .spinner:before,
.webBtn.lgBtn .spinner:after,
footer form button .spinner:before,
footer form button .spinner:after {
  width: 20px;
  height: 20px;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}

*[disabled] {
  cursor: not-allowed !important;
}

a[disabled],
button[disabled] {
  opacity: 0.8;
}

.learnBtn {
  background: transparent;
  color: #ff6c40;
  font-family: "Poppins SemiBold";
  padding: 0;
  text-transform: capitalize;
  border: 0;
}

.learnBtn i {
  position: relative;
  top: 3px;
  font-weight: bold;
  margin-left: 5px;
  /* transform: scale(-1); */
  transition: padding ease 0.5s;
}

.learnBtn:hover i {
  padding-left: 10px;
}

/*
|----------------------------------------------------------------------
|       Body Inner Css
|----------------------------------------------------------------------
*/

h1 {
  font-size: 40px;
}

h2 {
  font-size: 27px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 20px;
}

h5 {
  font-size: 16px;
}

h6 {
  font-size: 14px;
}

img {
  width: 100%;
  display: block;
}

em {
  font-style: normal;
}

strong {
  font-family: "Poppins SemiBold";
  font-weight: normal;
}

input,
button,
select,
textarea {
  text-decoration: none !important;
  outline: none !important;
}

.ease,
body a,
body button,
body .webBtn {
  transition: all ease 0.5s;
}

.contain,
.contain-fluid {
  position: relative;
  max-width: 1340px;
  padding: 0 15px;
  margin: auto;
  min-height: 1px;
  z-index: 3;
}

.contain-fluid {
  max-width: 1600px;
}

.contain:before,
.contain:after,
.contain-fluid:before,
.contain-fluid:after {
  content: "";
  display: table;
}

.contain:after,
.contain-fluid:after {
  clear: both;
}

section {
  position: relative;
}

.flex {
  display: flex;
  flex-wrap: wrap;
}

.formRow {
  display: flex;
  flex-wrap: wrap;
  margin: -10px;
}

.formRow > [class*="col-"],
.formRow > [class^="col-"] {
  padding: 10px;
}

.flexBox {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flexDv {
  width: 100%;
}

.flexRow {
  margin: -10px;
}

.flexRow > .col {
  padding: 10px;
}

.bTn {
  display: flex;
  flex-flow: wrap;
  gap: 5px;
}

.bTn.formBtn {
  margin-top: 20px;
}

.text-left.bTn,
.text-left .bTn {
  justify-content: flex-start;
}

.text-center.bTn,
.text-center .bTn {
  justify-content: center;
}

.text-right.bTn,
.text-right .bTn {
  justify-content: flex-end;
}

/*_____ upperlay _____*/

.upperlay {
  position: fixed;
  top: 0;
  left: 280px;
  right: 0;
  bottom: 0;
  background: rgb(10 33 48 / 0.9);
  opacity: 0;
  visibility: hidden;
  backdrop-filter: blur(5px);
  transition: all ease 0.5s;
  z-index: 100;
}

.upperlay.active {
  opacity: 1;
  visibility: visible;
}

/*
|----------------------------------------------------------------------
|       Popup
|----------------------------------------------------------------------
*/

.popup {
  /* display: none; */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(25, 33, 37, 0.95);
  padding: 20px 0;
  overflow: auto;
  z-index: 99999;
}

.popup.view_offer_popup,
.popup.send_msg {
  display: none;
}

.popup.view_offer_popup.active,
.popup.send_msg.active {
  display: flex;
}

.popup-full {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgb(255, 255, 255);
  padding: 80px 0;
  overflow: auto;
  z-index: 101;
}

.popup-full .back_btn {
  position: absolute;

  top: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 48px;
  width: 28px;
  height: 28px;
  /*color: #000000;
  */
  font-size: 15px;
  text-align: center;
  cursor: pointer;
  z-index: 5;
  background: #fff;
  color: #778ca3;
  border: 1px solid #aaa;
  border-radius: 75%;
  -webkit-border-radius: 75%;
  -moz-border-radius: 75%;
  -ms-border-radius: 75%;
  -o-border-radius: 75%;
}

.popup-full ._inner {
  position: relative;
  padding: 0px 15px;
}

.popup ._inner {
  position: relative;
  max-width: 800px;
  /*background: #f7f8f9;*/
  background: #fff;
  padding: 20px;
  margin: auto;
  border-radius: 10px;
  box-shadow: 0 7px 14px 0 rgba(59, 65, 94, 0.1),
    0 3px 6px 0 rgba(0, 0, 0, 0.07);
}

.popup.big-popup ._inner {
  max-width: 800px;
}

.popup.small-popup ._inner {
  max-width: 420px;
}

.popup ._inner h3 {
  padding-right: 25px;
  margin-bottom: 20px;
}

.popup ._inner h4 {
  padding-right: 25px;
  margin-bottom: 15px;
}

.popBtn {
  cursor: pointer;
}

.crosBtn {
  position: absolute;
  top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 15px;
  width: 28px;
  height: 28px;
  color: #1b1b1b;
  font-size: 18px;
  text-align: center;
  cursor: pointer;
  z-index: 5;
}

.video_pop .crosBtn {
  color: #fff;
}

.crosBtn:before {
  content: "\2715";
  line-height: 1;
}

.popup .list > li {
  display: flex;
  margin-bottom: 10px;
}

.popup .list > li > div:nth-child(1) {
  width: 120px;
  min-width: 120px;
  font-family: "Poppins Bold";
  margin-right: 10px;
}

.popup .list > li > div:nth-child(2) {
  width: 100%;
}

.popup .blockLst {
  margin: -15px;
}

/*
|----------------------------------------------------------------------
|       Dropdown
|----------------------------------------------------------------------
*/

.dropBtn {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

._dropBtn {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.dropBtn > i.chevron {
  display: inline-block;
  width: 10px;
  min-width: 10px;
  height: 10px;
  background-image: var(--chevron);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin: 0;
  margin-left: 5px;
  transition: inherit;
}

.dropDown {
  position: relative;
  z-index: 1;
}

._dropDown {
  position: relative;
  z-index: 3;
}

.dropCnt {
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 160px;
  max-width: 260px;
  background: #fff;
  margin-top: 10px;
  visibility: hidden;
  opacity: 0;
  /* border: 1px solid #eee; */
  border-radius: 7px;
  box-shadow: 0 13px 27px -5px rgba(50, 50, 93, 0.25),
    0 8px 16px -8px rgba(0, 0, 0, 0.3), 0 -6px 16px -6px rgba(0, 0, 0, 0.025);
  transition: all ease 0.5s;
  overflow: hidden;
}

.hidden {
  display: none !important;
}

._dropCnt {
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 300px;
  max-width: 300px;
  background: #fff;
  margin-top: 10px;
  visibility: hidden;
  opacity: 0;
  /* border: 1px solid #eee; */
  border-radius: 7px;
  box-shadow: 0 13px 27px -5px rgba(50, 50, 93, 0.25),
    0 8px 16px -8px rgba(0, 0, 0, 0.3), 0 -6px 16px -6px rgba(0, 0, 0, 0.025);
  transition: all ease 0.5s;
  overflow: hidden;
  z-index: 3;
}

.dropCnt.right {
  left: initial;
  right: 0;
}

.dropCnt.active {
  margin-top: 0;
  visibility: visible;
  opacity: 1;
}

._dropCnt.active {
  margin-top: 15px;
  visibility: visible;
  opacity: 1;
}

._dropCnt.lg_drop {
  left: auto;
  right: 0px;
}

header nav ._dropCnt.lg_drop {
  opacity: 1;
  visibility: visible;
}

.notification_listing ul li .notify_header {
  margin-top: -6px;
  text-align: center;
  border-bottom: 1px solid #ddd;
  background-color: rgb(243 243 243 / 26%);
  justify-content: center;
  padding: 15px 0px;
}

.notification_listing ul li .notify_footer {
  margin-bottom: -6px;
  text-align: center;
  border-top: 1px solid #ddd;
  justify-content: center;
  padding: 10px 0px;
}

.notification_listing ul li .notify_footer a {
  font-size: 13px;
}

.notification_listing ul li .notify_header h5 {
  margin-bottom: 0;
  font-size: 14px;
}

.notification_listing ul li .inner {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding-top: 10px;
}

.notification_listing ul li .inner .user_sm_icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  overflow: hidden;
  align-self: flex-start;
}

.notification_listing ul li .inner .user_sm_icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.notification_listing ul li .inner .color_icon_notify {
  background-color: #00acfc;
  padding: 9px;
  width: 35px;
  height: 35px;
}

.notification_listing ul li .inner .color_icon_notify img {
  object-fit: contain;
  height: auto;
  filter: brightness(0) invert(1);
}

.notification_listing ul li .inner .notify_cntnt {
  flex: 1;
  margin-left: 10px;
}

.notification_listing ul li .inner .notify_cntnt p {
  font-size: 13px;
  margin-bottom: 5px;
  line-height: 1.2;
}

.notification_listing ul li .inner .notify_cntnt .time_ago {
  color: rgb(167 167 167);
  font-size: 10px;
}

.dropLst {
  padding: 6px 0;
}

.dropLst > li,
.dropLst > li > * {
  display: block;
}

/* .dropLst > li:nth-child(1) > * {
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
}

.dropLst > li:nth-last-child(1) > * {
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
}

.dropLst > li:not(:nth-last-child(1)) {
    border-bottom: 1px solid #eee;
} */

.dropLst > li > * {
  display: flex;
  align-items: center;
  width: 100%;
  background: #fff;
  /* color: #fff; */
  text-align: left;
  padding: 6px 15px;
  border: 0;
}

.dropLst > li > *:hover {
  background: #fafafa;
}

.dropLst > li > * > i {
  margin-right: 6px;
}

/*_____ more _____*/

.more {
  position: relative;
  width: 20px;
  min-width: 20px;
  height: 20px;
  /* padding-bottom: 10px; */
  margin: 0 auto;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all ease 0.5s;
}

.more > span,
.more > span:before,
.more > span:after {
  width: 4px;
  height: 4px;
  border-radius: 75%;
  background: #aaa;
  transition: all ease 0.5s;
  z-index: 2;
}

.more > span:before,
.more > span:after {
  position: absolute;
  content: "";
}

.more > span:before {
  left: 0;
}

.more > span:after {
  right: 0;
}

.more:hover > span,
.more:hover > span:before,
.more:hover > span:after {
  background: #fff;
}

/*
|----------------------------------------------------------------------
|       Page Loader
|----------------------------------------------------------------------
*/

#pageloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #f5f6f8;
  z-index: 999999;
}

.loader {
  width: 40px;
  height: 40px;
  border: 4px solid #ff6c40;
  border-right: 4px solid #f0f5f9;
  border-radius: 75%;
  animation: spin 0.5s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}

/*
|----------------------------------------------------------------------
|       Ico Css
|----------------------------------------------------------------------
*/

.ico,
.icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.ico {
  background: #fff;
  border-radius: 75%;
  box-shadow: 0 13px 27px -5px rgba(50, 50, 93, 0.25),
    0 8px 16px -8px rgba(0, 0, 0, 0.3), 0 -6px 16px -6px rgba(0, 0, 0, 0.025);
  /* overflow: hidden; */
}

.ico a,
.icon a {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: inherit;
}

.ico img,
.icon img {
  width: 100%;
  height: 100%;
  object-position: center;
}

.ico img {
  object-fit: cover;
  border-radius: inherit;
}

.icon img {
  object-fit: contain;
}

.ico > i.status {
  position: absolute;
  top: 0;
  right: -4px;
  display: block;
  width: 12px;
  height: 12px;
  /* background: #43b753; */
  background: #ff6c40;
  border: 2px solid #fff;
  border-radius: 75%;
}

.ico > i.status.active {
  background: #43b753;
  /* background: #ff6c40; */
}

.ico > i.status.away {
  background: #ffcb00;
}

.ico > i.status.busy {
  background: #5883ff;
}

.ico > i.status.offline {
  background: #ff5465;
}

.ico > .loading {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  /* width: 50%; */
  min-width: 100%;
  height: 3px;
  background: #eee;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  overflow: hidden;
}

.ico > .loading:before {
  content: "";
  width: inherit;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #ff6c40;
}

/*
|----------------------------------------------------------------------
|       RateYo
|----------------------------------------------------------------------
*/

.jq-ry-container {
  position: relative;
  display: block;
  cursor: pointer;
  box-sizing: content-box;
  line-height: 0;
  direction: ltr;
}

.jq-ry-container[readonly="readonly"] {
  cursor: default;
}

.jq-ry-container > .jq-ry-group-wrapper {
  position: relative;
  width: 100%;
}

.jq-ry-container > .jq-ry-group-wrapper > .jq-ry-group {
  position: relative;
  line-height: 0;
  white-space: nowrap;
  z-index: 1;
}

.jq-ry-container > .jq-ry-group-wrapper > .jq-ry-group > svg {
  display: inline-block;
}

.jq-ry-container > .jq-ry-group-wrapper > .jq-ry-group.jq-ry-normal-group {
  width: 100%;
}

.jq-ry-container > .jq-ry-group-wrapper > .jq-ry-group.jq-ry-rated-group {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  overflow: hidden;
  z-index: 3;
}

/*
|----------------------------------------------------------------------
|       Editor
|----------------------------------------------------------------------
*/

.ckEditor {
  font-family: "Poppins Regular", sans-serif, Arial, Verdana, "Trebuchet MS",
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 14px;
  color: #333;
}

.ckEditor .cke_editable {
  font-size: 13px;
  line-height: 1.6;
  word-wrap: break-word;
}

.ckEditor blockquote {
  font-style: italic;
  font-family: Georgia, Times, "Times New Roman", serif;
  padding: 2px 0;
  border-style: solid;
  border-color: #ccc;
  border-width: 0;
}

.ckEditor .cke_contents_ltr blockquote {
  padding-left: 20px;
  padding-right: 8px;
  border-left-width: 5px;
}

.ckEditor .cke_contents_rtl blockquote {
  padding-left: 8px;
  padding-right: 20px;
  border-right-width: 5px;
}

.ckEditor a {
  display: inline;
  color: #0782c1;
}

.ckEditor ol,
.ckEditor ul,
.ckEditor dl {
  margin-right: 0px;
  padding: 0 40px;
}

.ckEditor ul,
.ckEditor ol {
  padding: 0;
  padding-left: 20px;
  margin: 0 0 10px;
  list-style-position: inside;
}

.ckEditor ul > li,
.ckEditor ol > li {
  display: list-item;
  margin-bottom: 5px;
}

.ckEditor ul > li ul,
.ckEditor ol > li ol {
  margin: 5px 0 7px;
}

.ckEditor h1,
.ckEditor h2,
.ckEditor h3,
.ckEditor h4,
.ckEditor h5,
.ckEditor h6 {
  font-weight: normal;
  line-height: 1.2;
}

.ckEditor hr {
  border: 0px;
  border-top: 1px solid #ccc;
}

.ckEditor img.right {
  border: 1px solid #ccc;
  float: right;
  margin-left: 15px;
  padding: 5px;
}

.ckEditor img.left {
  border: 1px solid #ccc;
  float: left;
  margin-right: 15px;
  padding: 5px;
}

.ckEditor pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  -moz-tab-size: 4;
  tab-size: 4;
}

.ckEditor .marker {
  background-color: Yellow;
}

.ckEditor span[lang] {
  font-style: italic;
}

.ckEditor figure {
  text-align: center;
  outline: solid 1px #ccc;
  background: rgba(0, 0, 0, 0.05);
  padding: 10px;
  margin: 10px 20px;
  display: inline-block;
}

.ckEditor figure > figcaption {
  text-align: center;
  display: block;
}

.ckEditor a > img {
  padding: 1px;
  margin: 1px;
  border: none;
  outline: 1px solid #0782c1;
}

.ckEditor .code-featured {
  border: 5px solid red;
}

.ckEditor .math-featured {
  padding: 20px;
  box-shadow: 0 0 2px rgba(200, 0, 0, 1);
  background-color: rgba(255, 0, 0, 0.05);
  margin: 10px;
}

.ckEditor .image-clean {
  border: 0;
  background: none;
  padding: 0;
}

.ckEditor .image-clean > figcaption {
  font-size: 0.9em;
  text-align: right;
}

.ckEditor .image-grayscale {
  background-color: white;
  color: #666;
}

.ckEditor .image-grayscale img,
.ckEditor img.image-grayscale {
  filter: grayscale(100%);
}

.ckEditor .embed-240p {
  max-width: 426px;
  max-height: 240px;
  margin: 0 auto;
}

.ckEditor .embed-360p {
  max-width: 640px;
  max-height: 360px;
  margin: 0 auto;
}

.ckEditor .embed-480p {
  max-width: 854px;
  max-height: 480px;
  margin: 0 auto;
}

.ckEditor .embed-720p {
  max-width: 1280px;
  max-height: 720px;
  margin: 0 auto;
}

.ckEditor .embed-1080p {
  max-width: 1920px;
  max-height: 1080px;
  margin: 0 auto;
}

.ckEditor i,
.ckEditor em {
  font-style: italic;
}

.ckEditor b,
.ckEditor strong {
  font-weight: bold;
}

.ckEditor u {
  text-decoration: underline;
}

.ckEditor span {
  display: initial;
  text-decoration: inherit;
}

/*_____ scrollbar  _____*/

.lg-outer .lg-thumb-outer.lg-thumb-align-middle {
  overflow-x: auto;
}

.scrollbar::-webkit-scrollbar,
.lg-outer .lg-thumb-outer.lg-thumb-align-middle::-webkit-scrollbar {
  background-color: #e3e8ed;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  height: 4px;
}

/* .lg-outer .lg-thumb-outer {
  overflow-y: hidden !important;
  overflow-x: auto !important;
}

.lg-outer .lg-thumb-outer::-webkit-scrollbar {
  background-color: #e3e8ed;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  height: 4px;
  width: 40%;

} */

.popup-full.scrollbar::-webkit-scrollbar {
  height: 2px;
  width: 10px;
}

.scrollbar::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 50px;
}

html::-webkit-scrollbar {
  width: 4px;
  background-color: #e3e8ed;
  border-radius: 50px;
}

html::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 50px;
}

.txtBox.bootstrap-select .dropdown-menu::-webkit-scrollbar {
  width: 4px;
  background-color: #e3e8ed;
  border-radius: 50px;
}

.txtBox.bootstrap-select .dropdown-menu::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 50px;
}

/*
|----------------------------------------------------------------------
|       Header
|----------------------------------------------------------------------
*/

header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 72px;
  background: #fff;
  box-shadow: 0 5px 15px rgb(0 0 0 / 7%);
  z-index: 30;
}

header .contain-fluid {
  padding: 0 15px;
}

.logo {
  float: left;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 72px;
  /* width: fit-content;
  margin:0px auto; */
  padding: 0.6rem;
}

.logo > a {
  display: block;
  height: 100%;
}

.logo > a > img {
  display: block;
  width: 100%;
  height: 100%;
  max-width: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: left center;
  object-position: left center;
  -webkit-transition: inherit;
  transition: inherit;
}

nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 72px;
  /* margin-left: 80px; */
  position: absolute;
  top: 0;
  right: 0;
}

nav > ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  /* margin: 0 -15px; */
}

nav > #nav {
  /* margin-right: 30px; */
  margin-left: auto;
}

nav > ul > li {
  position: relative;
  padding: 0 15px;
}

nav > ul > li > a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 36px;
  /* color: #080a0f; */
  color: #4c4c4c;
  text-align: center;
  font-size: 14px;
}

nav > ul > li > a:hover,
nav > ul > li.active > a:not(.webBtn) {
  color: #2d8cff;
}

nav > ul > li > .sub {
  position: absolute;
  top: 100%;
  right: 15px;
  width: 350px;
  max-height: calc(100vh - 108px);
  background: #fff;
  padding: 25px;
  margin-top: 10px;
  opacity: 0;
  visibility: hidden;
  border-radius: 7px;
  -webkit-box-shadow: 0 5px 20px rgb(18 15 56 / 7%);
  box-shadow: 0 5px 20px rgb(18 15 56 / 7%);
  overflow: auto;
}

nav > ul > li > .sub.sm {
  width: 240px;
}

nav > ul > li.drop:hover > .sub {
  margin-top: 0;
  opacity: 1;
  visibility: visible;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

nav > ul#iconBtn {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 -10px;
  margin-left: 10px;
}

nav > ul#iconBtn > li {
  padding: 0 10px;
}

nav > ul .iconBtn > span.active {
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  background-color: #07b505;
  top: -4px;
  right: 3px;
  z-index: 1;
}

.ring_bell {
  -webkit-animation: ring 4s 0.7s ease-in-out infinite;
  -webkit-transform-origin: 50% 4px;
  -moz-animation: ring 4s 0.7s ease-in-out infinite;
  -moz-transform-origin: 50% 4px;
  animation: ring 4s 0.7s ease-in-out infinite;
  transform-origin: 50% 4px;
}

@-webkit-keyframes ring {
  0% {
    -webkit-transform: rotateZ(0);
  }

  1% {
    -webkit-transform: rotateZ(30deg);
  }

  3% {
    -webkit-transform: rotateZ(-28deg);
  }

  5% {
    -webkit-transform: rotateZ(34deg);
  }

  7% {
    -webkit-transform: rotateZ(-32deg);
  }

  9% {
    -webkit-transform: rotateZ(30deg);
  }

  11% {
    -webkit-transform: rotateZ(-28deg);
  }

  13% {
    -webkit-transform: rotateZ(26deg);
  }

  15% {
    -webkit-transform: rotateZ(-24deg);
  }

  17% {
    -webkit-transform: rotateZ(22deg);
  }

  19% {
    -webkit-transform: rotateZ(-20deg);
  }

  21% {
    -webkit-transform: rotateZ(18deg);
  }

  23% {
    -webkit-transform: rotateZ(-16deg);
  }

  25% {
    -webkit-transform: rotateZ(14deg);
  }

  27% {
    -webkit-transform: rotateZ(-12deg);
  }

  29% {
    -webkit-transform: rotateZ(10deg);
  }

  31% {
    -webkit-transform: rotateZ(-8deg);
  }

  33% {
    -webkit-transform: rotateZ(6deg);
  }

  35% {
    -webkit-transform: rotateZ(-4deg);
  }

  37% {
    -webkit-transform: rotateZ(2deg);
  }

  39% {
    -webkit-transform: rotateZ(-1deg);
  }

  41% {
    -webkit-transform: rotateZ(1deg);
  }

  43% {
    -webkit-transform: rotateZ(0);
  }

  100% {
    -webkit-transform: rotateZ(0);
  }
}

@-moz-keyframes ring {
  0% {
    -moz-transform: rotate(0);
  }

  1% {
    -moz-transform: rotate(30deg);
  }

  3% {
    -moz-transform: rotate(-28deg);
  }

  5% {
    -moz-transform: rotate(34deg);
  }

  7% {
    -moz-transform: rotate(-32deg);
  }

  9% {
    -moz-transform: rotate(30deg);
  }

  11% {
    -moz-transform: rotate(-28deg);
  }

  13% {
    -moz-transform: rotate(26deg);
  }

  15% {
    -moz-transform: rotate(-24deg);
  }

  17% {
    -moz-transform: rotate(22deg);
  }

  19% {
    -moz-transform: rotate(-20deg);
  }

  21% {
    -moz-transform: rotate(18deg);
  }

  23% {
    -moz-transform: rotate(-16deg);
  }

  25% {
    -moz-transform: rotate(14deg);
  }

  27% {
    -moz-transform: rotate(-12deg);
  }

  29% {
    -moz-transform: rotate(10deg);
  }

  31% {
    -moz-transform: rotate(-8deg);
  }

  33% {
    -moz-transform: rotate(6deg);
  }

  35% {
    -moz-transform: rotate(-4deg);
  }

  37% {
    -moz-transform: rotate(2deg);
  }

  39% {
    -moz-transform: rotate(-1deg);
  }

  41% {
    -moz-transform: rotate(1deg);
  }

  43% {
    -moz-transform: rotate(0);
  }

  100% {
    -moz-transform: rotate(0);
  }
}

@keyframes ring {
  0% {
    transform: rotate(0);
  }

  1% {
    transform: rotate(30deg);
  }

  3% {
    transform: rotate(-28deg);
  }

  5% {
    transform: rotate(34deg);
  }

  7% {
    transform: rotate(-32deg);
  }

  9% {
    transform: rotate(30deg);
  }

  11% {
    transform: rotate(-28deg);
  }

  13% {
    transform: rotate(26deg);
  }

  15% {
    transform: rotate(-24deg);
  }

  17% {
    transform: rotate(22deg);
  }

  19% {
    transform: rotate(-20deg);
  }

  21% {
    transform: rotate(18deg);
  }

  23% {
    transform: rotate(-16deg);
  }

  25% {
    transform: rotate(14deg);
  }

  27% {
    transform: rotate(-12deg);
  }

  29% {
    transform: rotate(10deg);
  }

  31% {
    transform: rotate(-8deg);
  }

  33% {
    transform: rotate(6deg);
  }

  35% {
    transform: rotate(-4deg);
  }

  37% {
    transform: rotate(2deg);
  }

  39% {
    transform: rotate(-1deg);
  }

  41% {
    transform: rotate(1deg);
  }

  43% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(0);
  }
}

nav > ul#iconBtn.iconSearch {
  display: none;
}

nav > ul#iconBtn.iconSearch {
  /* margin-right: 10px; */
  margin-right: auto;
  left: 80px;
  right: auto;
}

nav > ul#iconBtn.iconSearch button {
  width: 27px;
  height: 27px;
}

nav > ul .sub .list > li {
  display: block;
}

nav > ul .sub .list > li:not(:nth-last-child(1)) {
  margin-bottom: 10px;
}

nav > ul .sub .list > li > a small {
  display: block;
  color: #8b9593;
}

nav > ul .sub .list > li:nth-last-child(1) > a {
  color: #ff4b4b;
}

nav > ul .iconBtn {
  position: relative;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  width: 20px;
  height: 20px;
  background: transparent;
  padding: 0;
  border: 0;
}

nav > ul .iconBtn > img {
  /* filter: brightness(0) invert(0.5); */
  -webkit-transition: inherit;
  transition: inherit;
}

nav > ul .iconBtn > em {
  position: absolute;
  top: -4px;
  right: -3px;
  -webkit-transition: inherit;
  transition: inherit;
  width: 10px;
  height: 10px;
}

header .srchBar {
  position: relative;
  min-width: 350px;
  margin: 0;
  margin-left: 5%;
}

header .srchBar:before {
  /* content: ''; */
  position: absolute;
  top: 0;
  left: -15px;
  right: -15px;
  bottom: 0;
  background: inherit;
  z-index: -1;
}

header .srchBar > .txtBox {
  height: 40px;
  background: rgb(243 249 253);
  color: #080a0f;
  padding-left: 40px;
  border-color: rgb(243 249 253);
  border-radius: 7px;
}

header .srchBar > .txtBox:focus {
  border-color: #2d8cff !important;
}

header .srchBar > .txtBox::-webkit-input-placeholder {
  color: inherit;
}

header .srchBar > .txtBox::-moz-placeholder {
  color: inherit;
}

header .srchBar > .txtBox:-ms-input-placeholder {
  color: inherit;
}

header .srchBar > .txtBox::-ms-input-placeholder {
  color: inherit;
}

header .srchBar > .txtBox::placeholder {
  color: inherit;
}

header .srchBar > button {
  position: absolute;
  top: 0;
  left: 14px;
  bottom: 0;
  width: 18px;
  height: 18px;
  background: transparent;
  padding: 0;
  margin: auto;
  border: 0;
  /* filter: brightness(0) invert(0.5); */
  -webkit-filter: brightness(0) invert(0);
  filter: brightness(0) invert(0);
}

header .srchBar > button[type="button"] {
  display: none;
  left: initial !important;
  right: 15px !important;
}

nav #cta {
  margin-left: 10px;
}

.loged_blk {
  border: 1px solid #ff6c40;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 20px;
  gap: 11px;
  color: #ff6c40;
}

.loged_blk i {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border: 1px solid #dfebf1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loged_blk:hover {
  color: #fff !important;
  background-color: #ff6c40;
}

nav #cta li:first-child {
  padding-right: 0;
}

/* nav .blnsBtn {
    font-size: 22px;
    margin-right: 20px;
    line-height: 1;
    text-align: right;
}

nav .blnsBtn > small {
	display: block;
	color: #2d8cff;
	font-size: 10px;
} */

nav .blnsBtn {
  color: #2d8cff;
  font-size: 12px;
  font-family: "Poppins SemiBold";
  padding: 3px 7px;
  margin-right: 20px;
  line-height: 1;
  text-align: right;
  border: 2px solid;
  border-radius: 4px;
}

/*_____ proIco _____*/

header .proIco,
.proIco {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  margin-left: 20px;
}

header .proIco {
  margin-right: 20px;
}

.popup .proIco {
  margin: 0 0 20px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.requestContent .badge {
  cursor: pointer;
}

.requestContent h5 {
  margin: 0;
  margin-left: 5px;
}

header .proIco .inside {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

header .proIco .ico,
.proIco .ico {
  width: 36px;
  height: 36px;
  background: #fff;
  -webkit-box-shadow: 0 2px 5px 0 rgba(59, 65, 94, 0.1),
    0 1px 1px 0 rgba(0, 0, 0, 0.07);
  box-shadow: 0 2px 5px 0 rgba(59, 65, 94, 0.1), 0 1px 1px 0 rgba(0, 0, 0, 0.07);
}

header .proIco .proDrop {
  left: initial;
  right: 0;
  min-width: 220px;
  max-height: calc(100vh - 90px);
  -webkit-box-shadow: rgb(0 0 0 / 10%) 0 4px 12px 0;
  box-shadow: rgb(0 0 0 / 10%) 0 4px 12px 0;
  overflow: auto;
  opacity: 1;
  visibility: visible;
  /* padding: 7px 0px; */
}

/* header .proIco .proDrop.active {
	margin-top: 5px;
} */

header .proIco .dropLst > li > a {
  display: block;
  font-family: "Poppins Medium";
}

header .proIco .dropLst > li:nth-last-child(1) > a {
  color: #2d8cff;
}

header .proIco .dropLst > li > a > small {
  display: block;
  color: #8b9593;
  font-size: 80%;
  font-family: "Poppins Regular";
}

header .proIco .dropLst > li:nth-last-child(1) > a {
  color: #ff4b4b;
}

header .proIco .proDrop li {
  display: block;
}

header .proIco .proDrop li a {
  display: flex;
  padding: 5px 10px;
}

header .proIco .proDrop li:not(:last-child) a {
  border-bottom: 1px solid #e9f4fb;
}

header .proIco .proDrop li a .small_icon {
  width: 16px;
  align-self: center;
}

header .proIco .proDrop li a .small_icon img {
  filter: brightness(0) invert(0.5);
}

header .proIco .proDrop li a span {
  flex: 1;
  margin-left: 7px;
  font-size: 13px;
  align-self: center;
  color: #959595;
}

header .proIco .proDrop li .user_header {
  padding: 10px;
  background: #ff6c40;
  text-align: center;
  color: #fff;
  flex-direction: column;
  margin-top: -6px;
}

header .proIco .proDrop li .user_header h5 {
  margin-bottom: 0px;
  font-size: 14px;
}

header .proIco .proDrop li .user_header p {
  font-size: 11px;
  color: #ededed;
  font-family: Poppins SemiBold;
}

.loged-nav li a {
  width: 16px;
  /* overflow: hidden; */
  position: relative;
}

.loged-nav li a img {
  height: 100%;
  width: 100%;
}

.loged-nav li {
  padding: 0 10px;
}

.loged-nav {
  margin-right: 0;
}

.loged-nav li a span.active {
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  background-color: #07b505;
  top: 7px;
  right: 1px;
  z-index: 1;
}

.ring_bell {
  -webkit-animation: ring 4s 0.7s ease-in-out infinite;
  -webkit-transform-origin: 50% 4px;
  -moz-animation: ring 4s 0.7s ease-in-out infinite;
  -moz-transform-origin: 50% 4px;
  animation: ring 4s 0.7s ease-in-out infinite;
  transform-origin: 50% 4px;
}

@-webkit-keyframes ring {
  0% {
    -webkit-transform: rotateZ(0);
  }

  1% {
    -webkit-transform: rotateZ(30deg);
  }

  3% {
    -webkit-transform: rotateZ(-28deg);
  }

  5% {
    -webkit-transform: rotateZ(34deg);
  }

  7% {
    -webkit-transform: rotateZ(-32deg);
  }

  9% {
    -webkit-transform: rotateZ(30deg);
  }

  11% {
    -webkit-transform: rotateZ(-28deg);
  }

  13% {
    -webkit-transform: rotateZ(26deg);
  }

  15% {
    -webkit-transform: rotateZ(-24deg);
  }

  17% {
    -webkit-transform: rotateZ(22deg);
  }

  19% {
    -webkit-transform: rotateZ(-20deg);
  }

  21% {
    -webkit-transform: rotateZ(18deg);
  }

  23% {
    -webkit-transform: rotateZ(-16deg);
  }

  25% {
    -webkit-transform: rotateZ(14deg);
  }

  27% {
    -webkit-transform: rotateZ(-12deg);
  }

  29% {
    -webkit-transform: rotateZ(10deg);
  }

  31% {
    -webkit-transform: rotateZ(-8deg);
  }

  33% {
    -webkit-transform: rotateZ(6deg);
  }

  35% {
    -webkit-transform: rotateZ(-4deg);
  }

  37% {
    -webkit-transform: rotateZ(2deg);
  }

  39% {
    -webkit-transform: rotateZ(-1deg);
  }

  41% {
    -webkit-transform: rotateZ(1deg);
  }

  43% {
    -webkit-transform: rotateZ(0);
  }

  100% {
    -webkit-transform: rotateZ(0);
  }
}

@-moz-keyframes ring {
  0% {
    -moz-transform: rotate(0);
  }

  1% {
    -moz-transform: rotate(30deg);
  }

  3% {
    -moz-transform: rotate(-28deg);
  }

  5% {
    -moz-transform: rotate(34deg);
  }

  7% {
    -moz-transform: rotate(-32deg);
  }

  9% {
    -moz-transform: rotate(30deg);
  }

  11% {
    -moz-transform: rotate(-28deg);
  }

  13% {
    -moz-transform: rotate(26deg);
  }

  15% {
    -moz-transform: rotate(-24deg);
  }

  17% {
    -moz-transform: rotate(22deg);
  }

  19% {
    -moz-transform: rotate(-20deg);
  }

  21% {
    -moz-transform: rotate(18deg);
  }

  23% {
    -moz-transform: rotate(-16deg);
  }

  25% {
    -moz-transform: rotate(14deg);
  }

  27% {
    -moz-transform: rotate(-12deg);
  }

  29% {
    -moz-transform: rotate(10deg);
  }

  31% {
    -moz-transform: rotate(-8deg);
  }

  33% {
    -moz-transform: rotate(6deg);
  }

  35% {
    -moz-transform: rotate(-4deg);
  }

  37% {
    -moz-transform: rotate(2deg);
  }

  39% {
    -moz-transform: rotate(-1deg);
  }

  41% {
    -moz-transform: rotate(1deg);
  }

  43% {
    -moz-transform: rotate(0);
  }

  100% {
    -moz-transform: rotate(0);
  }
}

@keyframes ring {
  0% {
    transform: rotate(0);
  }

  1% {
    transform: rotate(30deg);
  }

  3% {
    transform: rotate(-28deg);
  }

  5% {
    transform: rotate(34deg);
  }

  7% {
    transform: rotate(-32deg);
  }

  9% {
    transform: rotate(30deg);
  }

  11% {
    transform: rotate(-28deg);
  }

  13% {
    transform: rotate(26deg);
  }

  15% {
    transform: rotate(-24deg);
  }

  17% {
    transform: rotate(22deg);
  }

  19% {
    transform: rotate(-20deg);
  }

  21% {
    transform: rotate(18deg);
  }

  23% {
    transform: rotate(-16deg);
  }

  25% {
    transform: rotate(14deg);
  }

  27% {
    transform: rotate(-12deg);
  }

  29% {
    transform: rotate(10deg);
  }

  31% {
    transform: rotate(-8deg);
  }

  33% {
    transform: rotate(6deg);
  }

  35% {
    transform: rotate(-4deg);
  }

  37% {
    transform: rotate(2deg);
  }

  39% {
    transform: rotate(-1deg);
  }

  41% {
    transform: rotate(1deg);
  }

  43% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(0);
  }
}

/*_____ Header Fixed _____*/

header.fix {
  background: #fff;
  -webkit-box-shadow: 0 5px 15px rgb(0 0 0 / 7%);
  box-shadow: 0 5px 15px rgb(0 0 0 / 7%);
}

header.fix .logo {
  -webkit-filter: none;
  filter: none;
}

header.fix nav > ul > li:not(.active) > a:not(.webBtn):not(:hover) {
  color: #080a0f;
}

header.fix form > .txtBox {
  color: #080a0f;
  border-color: #eee;
}

header.fix form > button {
  -webkit-filter: brightness(0) invert(0.5);
  filter: brightness(0) invert(0.5);
}

/*_____ miniLbl _____*/

.miniLbl {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  background: #ff6c40;
  color: #fff;
  font-size: 70%;
  font-family: "Poppins SemiBold";
  border-radius: 75%;
  white-space: nowrap;
}

.green {
  background: #43b753;
}

.green-color {
  color: #43b753;
}

.yellow {
  background: #ffcb00;
}

.yellow-color {
  color: #ffcb00;
}

.red {
  background: #ff5465;
}

.red-color {
  color: #ff5465;
}

.blue {
  background: #5883ff;
}
.orange {
  background: #ff6c40;
}

.blue-color {
  color: #5883ff;
}

.gray {
  background: #aaa;
}

.gray-color {
  color: #aaa;
}

section {
  padding: 75px 0;
}

/*_____ owl-nav _____*/

.owl-carousel.owl-theme .owl-nav {
  position: absolute;
  top: 55%;
  left: 0;
  right: 0;
  margin: 0;
}

.owl-carousel.owl-theme .owl-nav [class*="owl-"],
.slick-slider .slick-next,
.slick-slider .slick-prev {
  position: absolute;
  top: 0;
  width: initial;
  height: initial;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff65;
  color: #000000;
  width: 30px;
  height: 30px;
  border-radius: 50px;
  font-size: 20px;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  z-index: 9;
}

.image_slider .slick-prev:before,
.image_slider .slick-next:before {
  font-size: 30px;
  opacity: 1;
}

.slick-slider .slick-next,
.slick-slider .slick-prev {
  background: transparent;
  z-index: 7;
}

.slick-slider .slick-next,
.slick-slider .slick-prev {
  top: 50%;
}

.slick-slider .slick-prev,
.slick-slider .slick-next {
  font-size: 0;
  /* Set font size to 0 to hide text content */
}

.owl-carousel.owl-theme .owl-nav [class*="owl-"]:hover,
.slick-slider .slick-next:hover,
.slick-slider .slick-prev:hover {
  color: #000000;
  /* background: #000; */
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.owl-carousel.owl-theme .owl-nav .owl-prev,
.slick-slider .slick-prev {
  left: 10px;
  padding-right: 2px;
}

.owl-carousel.owl-theme .owl-nav .owl-next,
.slick-slider .slick-next {
  right: 10px;
  padding-left: 2px;
}

/*_____ owl-dots _____*/

.owl-carousel.owl-theme .owl-dots {
  text-align: center;
  position: relative;
  margin-top: 20px;
  line-height: 0;
  z-index: 5;
}

.slick-slider .slick-dots {
  position: absolute;
  bottom: 12px;
  z-index: 1;
  list-style: none;
}

.owl-carousel.owl-theme .owl-dots .owl-dot,
.slick-slider .slick-dots li {
  display: inline-block;
  padding: 0 2px !important;
  width: unset;
  height: unset;
  margin: 0;
}

.slick-slider .slick-dots li button:before {
  display: none;
}

.owl-carousel.owl-theme .owl-dots .owl-dot span,
.slick-slider .slick-dots li button {
  display: block;
  width: 10px;
  height: 4px;
  margin: auto;
  border: 2px solid #d2d2d2;
  border-radius: 50px;
  transition: all ease 0.5s;
  padding: 0;
}

.owl-carousel.owl-theme .owl-dots .owl-dot.active span,
.slick-slider .slick-dots li.slick-active button {
  width: 24px;
  height: 4px;
  margin: auto;
  border-color: #ff6c40;
}

/*
|----------------------------------------------------------------------
|       Form
|----------------------------------------------------------------------
*/

label {
  display: block;
  font-weight: normal;
  cursor: pointer;
}

label > a {
  color: #3c9cdb;
}

label > a:hover {
  color: #ff6c40;
}

.txtGrp {
  position: relative;
}

.txtGrp:not(:nth-last-child(1)) {
  margin-bottom: 20px;
}

.infoHead {
  margin-top: 40px;
  margin-bottom: 20px;
}

form h5 + p {
  margin: -10px 0 20px;
}

.txtBox {
  display: block;
  width: 100%;
  height: 46px;
  background: #fefefe;
  text-align: left;
  padding: 6px 12px;
  border: 1px solid #eee;
  border-radius: 6px;
  outline: none !important;
  transition: all ease 0.5s;
  font-size: 13px;
}

.txtGrp label ~ .txtBox:not(button) {
  padding: 16px 12px 6px;
}

.txtBox:-webkit-autofill {
  transition: background-color 5000s ease-in-out 0s;
}

textarea.txtBox {
  height: 120px;
  resize: none;
}

/* .txtGrp .txtBox {
    box-shadow: inset 1px 1px 4px #f2f3f7;
} */

.txtGrp .txtBox.uploadImg:not([data-upload]) {
  background: #fff;
  color: #ff6c40;
  text-align: center;
  border-color: #ff6c40;
  box-shadow: inset 1px 1px 4px #fcfcfc;
}

.txtGrp .txtBox.uploadImg[data-upload] {
  display: flex;
  align-items: center;
  color: #8b9593;
}

.txtGrp .txtBox.uploadImg[data-upload]:before {
  content: attr(data-text);
  flex: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

/* .txtGrp .txtBox.uploadImg[data-upload]:after {
  content: "Hochladen";
  color: #ff6c40;
  margin-left: 5px;
}

.txtGrp .txtBox.uploadImg[data-upload].uploaded:after {
  content: "Entfernen";
} */

.txtGrp:not(.lblBtn) > label {
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  min-height: 46px;
  color: #919292;
  line-height: 1;
  margin: 0;
  margin-left: 13px;
  pointer-events: none;
  transition: all ease 0.5s;
  z-index: 1;
}

.txtGrp:not(.lblBtn) > label.move {
  font-size: 10px;
  transform: translateY(-12px);
}

.txtBox:disabled {
  background: #e3e8ee;
  cursor: not-allowed;
  border-color: #d8dee6 !important;
}

.txtBox::placeholder {
  color: #080a0f;
}

.txtBox:focus::placeholder {
  opacity: 0.4;
}

.txtGrp .txtBox:focus::placeholder {
  opacity: 0;
}

.txtBox:hover,
.txtBox:focus {
  border-color: #ff6c40;
}

input[type="radio"],
input[type="checkbox"] {
  position: relative;
  width: 18px;
  min-width: 18px;
  height: 18px;
  margin-top: 0;
  cursor: pointer;
}

input[type="radio"],
input[type="checkbox"] {
  display: inline;
  appearance: initial;
}

input[type="radio"]:before,
input[type="checkbox"]:before,
input[type="radio"]:after,
input[type="checkbox"]:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

input[type="checkbox"]:before,
input[type="checkbox"]:after {
  transition: all linear 0.3s;
}

input[type="radio"]:before,
input[type="checkbox"]:before {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 8px;
  z-index: 1;
}

input[type="radio"]:after,
input[type="checkbox"]:after {
  background: #fff;
  border: 1px solid #d9dade;
  border-radius: 4px;
  box-shadow: inset 1px 1px 4px #f2f3f7;
}

input[type="radio"]:after {
  border-radius: 75%;
}

input[type="checkbox"]:checked:before {
  background-image: var(--tick);
}

input[type="radio"]:checked:after {
  border-color: #ff6c40;
  box-shadow: inset 0 0 0 4px #ff6c40;
}

input[type="checkbox"]:checked:after {
  background: #ff6c40;
  border-color: #ff6c40;
  box-shadow: none;
}

.lblBtn {
  display: flex;
  text-align: left;
}

.lblBtn > input[type="radio"],
.lblBtn > input[type="checkbox"] {
  margin-top: 2px;
}

.lblBtn label {
  align-self: center;
  font-family: "Poppins SemiBold";
  line-height: 1.5;
  cursor: pointer;
  margin: 0 0 0 10px;
}

input[type="file"].uploadFile {
  display: none;
}

.uploadImg {
  position: relative;
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

select.txtBox {
  background-image: var(--chevron);
  background-position: right 10px center;
  background-repeat: no-repeat;
  background-size: 10px;
  padding-right: 24px !important;
  -webkit-appearance: none;
  appearance: none;
}

/*
|----------------------------------------------------------------------
|       Banner
|----------------------------------------------------------------------
*/

.banner {
  background: url(./assets/images/bg-2.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.banner:before {
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgb(0 0 0 / 61%);
}

.banner .contain {
  width: 100%;
  position: relative;
  z-index: 9;
}

.banner .cntnt {
  max-width: 800px;
  margin: 0px auto;
  color: #fff;
}

.banner .cntnt h1 {
  text-transform: capitalize;
  font-family: "Poppins SemiBold";
  font-size: 55px;
}

.banner .cntnt p {
  font-size: 17px;
  max-width: 600px;
  margin: 0px auto;
  color: #ffffffd4;
}

/* --banner form-- */

.banner-form {
  max-width: 55%;
  margin: 20px auto 0px;
}

.banner-form h3 {
  margin-bottom: 20px;
  color: #0c4352;
}

.banner-form .col {
  flex: 2;
}

.banner-form .webBtn {
  min-width: fit-content;
  padding: 0;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ff6c40;
}

.banner-form .webBtn i {
  margin-right: 0;
  font-size: 140%;
}

.banner-form .txtBox {
  height: 50px;
  border-radius: 0;
  border-color: #088fff00;
  border-left: 1px solid #ddd;
}

.banner-form form .flex {
  background-color: #fff;
  padding: 5px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  overflow: hidden;
}

.banner-form form .flex .col:first-child {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex: unset;
  padding-right: 10px;
}

.banner-form form .flex .col:first-child .lblBtn label {
  font-family: "Poppins Medium";
  background: #ededed;
  border-radius: 50px;
  padding: 3px 12px;
  font-size: 12px;
  margin-left: 5px;
  color: #525252;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.banner-form form .flex .col:first-child .lblBtn.active label {
  background: #ff6c40;
  color: #ffffff;
}

.banner-form form .flex .col:first-child .lblBtn.rentBtn.active label {
  background-color: #088fff;
}

.banner-form form .flex .col:first-child .lblBtn label:hover {
  background: #ff6c40;
  color: #ffffff;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.banner-form form .flex .col:first-child .txtBox {
  border-left: none;
}

/* ===================feature_icon=================== */
.feature_icon {
  padding-top: 0 !important;
}

.feature_icon .col {
  width: 25%;
  padding: 10px;
}

.feature_icon .flex {
  margin-top: -50px;
  background-color: #ffffff;
  z-index: 1;
  box-shadow: rgb(81 24 24 / 20%) 0 5px 10px 0;
  border-radius: 15px;
  overflow: hidden;
}

.feature_icon .col .inner {
  text-align: center;
  padding: 25px;
  color: #4c4c4c;
}

.feature_icon .col .image {
  width: 60px;
  height: 60px;
  margin: 0px auto 20px;
  position: relative;
}

/* .feature_icon .col .image:before {
  position: absolute;
  content: "";
  top: 2px;
  left: -15px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  z-index: 0;
  background: #8fdbfe99;
}

.feature_icon .col .image:after {
  position: absolute;
  content: "";
  top: 40px;
  right: 15px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  z-index: 0;
  background: #ffb59fba;
} */

.feature_icon .col .image img {
  position: relative;
  z-index: 1;
}

.feature_icon .flex .col:not(:last-child) {
  border-right: 1px solid #00000014;
}

.feature_icon .col .inner h4 {
  font-size: 18px;
  font-family: "Poppins Medium";
}

.feature_icon .col .inner p {
  font-size: 14px;
}

/* ----sec_heading--- */
.sec_heading {
  margin-bottom: 30px;
}

.sec_heading h2 {
  font-size: 35px;
  font-family: "Poppins SemiBold";
  color: #333;
}

/* ----listing_property--- */
.listing_property {
  padding-top: 0 !important;
}

.listing_property .bTn {
  margin-top: 30px;
  justify-content: center;
}

.propert_list {
  margin: -10px;
}

.propert_list .col {
  width: calc(100% / 4);
  padding: 10px;
}

.propert_list .col .inner {
  border: 1px solid #dddddd80;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  overflow: hidden;
  background: #fff;
  position: relative;
  box-shadow: rgb(81 24 24 / 20%) 0 5px 10px 0;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.propert_list .col .inner:hover {
  box-shadow: none;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.big_link {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
}

.propert_list .col .inner .image_slider .item {
  position: relative;
  overflow: hidden;
  padding-bottom: 70%;
}

.propert_list .col .inner .image_slider .item img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  object-fit: cover;
  height: 100%;
}

.propert_list .col .inner .image_slider .owl-nav {
  opacity: 0;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.propert_list .col .inner:hover .image_slider .owl-nav {
  opacity: 1;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.propert_list .col .inner .image_slider .owl-dots,
.dash_property_listing .owl-carousel.owl-theme .owl-dots {
  /* margin-top: -20px; */
  opacity: 0;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  bottom: 10px;
  position: absolute;
  left: 0;
  right: 0;
}

.propert_list .col .inner:hover .image_slider .owl-dots {
  opacity: 1;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.propert_list .col .inner .cntnt {
  padding: 15px 20px 15px;
}

.propert_list .col .inner h3 {
  font-size: 19px;
  color: #000000bd;
  font-family: "Poppins SemiBold";
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin-bottom: 5px;
}

.propert_list .col .inner .cntnt ul.flex {
  gap: 10px;
  margin: 5px 0px;
}

.flex.last_list {
  background: #f0f0f0;
  padding: 7px;
  border-radius: 7px;
  justify-content: space-between;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
  margin-top: 20px !important;
  margin-bottom: 0 !important;
}

.flex.last_list i {
  align-self: center;
}

.propert_list .col .inner .cntnt .flex li {
  font-size: 14px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  align-self: center;
}

.propert_list .col .inner .cntnt i {
  display: flex;
  font-size: 15px;
  color: #848484;
  margin-right: 5px;
  justify-content: center;
  align-items: flex-start;
}

.propert_list .col .inner .cntnt .flex li span {
  flex: 1;
  color: #848484;
}

.propert_list .col .inner .cntnt .flex li:not(:first-child) {
  padding-left: 3px;
}

.propert_list .col .inner .cntnt .flex li:not(:first-child):before {
  position: absolute;
  content: "";
  left: -3px;
  top: 1px;
  width: 1px;
  height: 20px;
  background-color: #8080808a;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
}

.propert_list .col .inner .cntnt .flex.last_list li::before {
  display: none;
  padding-left: 10px;
}

.propert_list .col .inner .cntnt .flex .review_star span {
  color: #000;
}

.propert_list .col .inner .cntnt .flex .review_star i {
  color: #088fff;
}

.propert_list .col .inner .price_flex .price {
  flex: 1;
}

.propert_list .col .inner .price_flex .price {
  font-size: 15px;
}

.propert_list .col .inner .price_flex p {
  margin-bottom: 0;
  align-self: center;
  font-size: 11px;
  opacity: 0.6;
}

.color_lbl {
  position: absolute;
  top: 15px;
  left: 15px;
  z-index: 3;
  padding: 3px 15px;
  border-radius: 50px;
  font-size: 13px;
  text-transform: uppercase;
  color: #fff;
}
.color_lbl.for_hours {
  bottom: 15px;
  top: unset;
  background: #fff;
  color: #000;
  font-size: 11px !important;
  text-transform: capitalize;
}
.color_lbl.for_sale,
.badge.for_sale {
  background: #ff6c40;
}

.color_lbl.for_rent,
.badge.for_rent {
  background: #00acfc;
}

.color_lbl.for_offers {
  background: #fff;
  color: #000;
  font-size: 11px !important;
  text-transform: capitalize;
}

.badge_prp_label {
  text-transform: capitalize;
  margin-right: 5px;
}

/* ====how-it-work== */
.how-it-work {
  background-color: #fff1ee;
}

.how-it-work .sec_heading {
  margin-bottom: 0;
}

.how-it-work .colL {
  width: 30%;
  padding-right: 20px;
  align-self: center;
}

.how-it-work .colR {
  width: 70%;
  padding-left: 20px;
  display: flex;
  flex-wrap: wrap;
}

.how-it-work .colR .col {
  width: 25%;
  padding: 10px;
  position: relative;
}

.big_step {
  position: absolute;
  top: 0;
  right: 40%;
  font-size: 200px;
  color: #fff;
  opacity: 0.8;
  line-height: 1;
}

.how-it-work .colR .col .image {
  margin-bottom: 10px;
  width: 50px;
  position: relative;
  z-index: 1;
}

.how-it-work .colR .col .image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  filter: invert(1) sepia(0);
}

.how-it-work .colR .col h4 {
  font-size: 16px;
  font-family: "Poppins SemiBold";
  position: relative;
  z-index: 1;
}

.how-it-work .colR .col p {
  font-size: 13px;
  position: relative;
  z-index: 1;
}

/* ====info_sec=== */
.info_sec .cntnt {
  max-width: 750px;
  margin: 0px auto;
}

.info_sec .flex {
  margin: -10px;
}

.info_sec .col {
  padding: 10px;
  width: 60%;
  flex: unset;
}

.info_sec .col:nth-child(2) {
  width: 40%;
}

.info_sec .col .inner {
  border: 1px solid #dddddd3d;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  overflow: hidden;
  position: relative;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  background: #f8f7fc;
}
.info_sec .col:nth-child(2) .inner{
  height: 100%;
}

.info_sec .col .inner .image {
  position: relative;
  padding-bottom: 30%;
  overflow: hidden;
  width: 50%;
}

.info_sec .col:nth-child(2) .inner .image {
  padding-bottom: 54%;
  width: 100%;
}

.info_sec .col .inner .image img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  object-fit: cover;
}

.info_sec .col:first-child .inner {
  display: flex;
  flex-wrap: wrap;
}

.info_sec .col:first-child .inner:not(:last-child) {
  margin-bottom: 20px;
}

.info_sec .col:first-child .inner .cntnt {
  align-self: center;
}

.info_sec .col .inner .cntnt {
  padding: 30px;
  flex: 1;
}

.info_sec .col .inner .cntnt h4 {
  font-size: 20px;
  font-family: "Poppins SemiBold";
  color: rgb(24, 24, 24);
}

.info_sec .col .inner .cntnt p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.info_sec .col:nth-child(2) .inner .cntnt p {
  -webkit-line-clamp: 2;
}

.cstm_more_read {
  display: flex;
  position: relative;
  text-transform: capitalize;
  width: fit-content;
}

.cstm_more_read i {
  align-self: center;
  margin-left: 3px;
  font-size: 18px;
}

.cstm_more_read * {
  position: relative;
  z-index: 1;
}

.cstm_more_read:hover:before {
  opacity: 0;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

/* ---extra_info_sec--- */
.extra_info_sec {
  padding-top: 0 !important;
}

.extra_info_sec .colL {
  width: 35%;
  padding-right: 20px;
  align-self: center;
}

.extra_info_sec .colR {
  width: 65%;
}

.extra_info_sec .colR ._inner {
  display: flex;
  flex-wrap: wrap;
  margin: -10px;
}

.extra_info_sec .colR ._inner .col {
  width: calc(100% / 3);
  padding: 10px;
}

.extra_info_sec .inner {
  border: 1px solid #dddddd3d;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  overflow: hidden;
  position: relative;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  background: #f8f7fc;
  height: 100%;
}

.extra_info_sec .inner .image {
  position: relative;
  padding-bottom: 70%;
  overflow: hidden;
  width: 100%;
}

.extra_info_sec .inner .image img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  object-fit: cover;
}

.extra_info_sec .inner .cntnt {
  padding: 20px;
}

.extra_info_sec .inner .cntnt h4 {
  font-size: 19px;
  font-family: "Poppins SemiBold";
  color: rgb(24, 24, 24);
}

.extra_info_sec .inner .cntnt p {
  /* overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical; */
  font-size: 14px;
}

/* ===destination=== */
.destination {
  padding-top: 0 !important;
}

.destination .flex {
  margin: -10px;
}

.destination .col {
  width: 20%;
  padding: 10px;
}

.destination .col .inner {
  overflow: hidden;
  position: relative;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.destination .col .image {
  position: relative;
  padding-bottom: 130%;
  overflow: hidden;
  width: 100%;
}

.destination .col .image img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  object-fit: cover;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.destination .col .inner:hover .image img {
  transform: scale(1.3);
  -webkit-transform: scale(1.3);
  -moz-transform: scale(1.3);
  -ms-transform: scale(1.3);
  -o-transform: scale(1.3);
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.destination .col .cntnt h2 {
  font-family: "Poppins Bold";
  margin-bottom: 0px;
  font-size: 35px;
}

.destination .col .cntnt .color_line {
  height: 1px;
  width: 75px;
  margin: 10px auto;
  background-color: #ff6c40;
}

.destination .col .cntnt p {
  margin-bottom: 0;
  font-size: 14px;
}

.destination .col .cntnt h4 {
  margin-bottom: 3px;
}

.destination .col .cntnt {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgb(0 0 0 / 66%);
  text-align: center;
  display: flex;
  flex-direction: column;
  color: #fff;
  justify-content: center;
  align-items: center;
}

/* ===============top rated place========== */
.top_rated_searches {
  background: #f8f7fc;
}

.top_rated_searches h4 {
  margin-bottom: 20px;
  font-size: 16px;
  text-transform: uppercase;
  position: relative;
  font-family: "Poppins Medium";
}

.top_rated_searches .flex .colL {
  width: 100%;
  padding-right: 20px;
}

.top_rated_searches .flex ul {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
}

.top_rated_searches .flex ul li {
  width: calc(100% / 6);
  padding: 0px 10px;
}

.top_rated_searches .flex ul li a {
  font-size: 12px;
  color: #919191;
}

.top_rated_searches .flex ul li a:hover {
  color: #ff6c40;
}

.top_rated_searches .flex ul li a em {
  color: #ff6c40;
}

/* =====strip=== */
.sugested_location_strip {
  padding-top: 15px !important;
}

.sugested_location_strip .sec_heading {
  margin-bottom: 0;
}

.sugested_location_strip .colL {
  flex: 1;
  margin-right: 50px;
}

.sugested_location_strip .colR {
  align-self: center;
  flex: 1;
}

.sugested_location_strip h5 {
  text-transform: uppercase;
  font-family: "Poppins SemiBold";
  color: #9b9b9b;
  font-size: 14px;
}

/*
|----------------------------------------------------------------------
|       Footer
|----------------------------------------------------------------------
*/

footer {
  position: relative;
  background: #181818;
  color: #fff;
  padding-top: 80px;
  overflow: hidden;
}

footer .flexRow {
  -ms-flex-flow: nowrap;
  flex-flow: nowrap;
  margin: -25px -15px;
}

footer .flexRow > .col {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding: 25px 15px;
}

footer .flexRow > .col:nth-last-child(1) {
  min-width: 300px;
  -webkit-box-flex: 1.5;
  -ms-flex: 1.5;
  flex: 1.5;
}

footer .flexRow > .col.flex {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

footer h5 {
  position: relative;
  font-family: "Poppins Regular";
  margin-bottom: 20px;
  padding-bottom: 10px;
  opacity: 0.7;
  text-transform: uppercase;
}

footer h5:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 30px;
  height: 2px;
  background: #00acfc;
}

footer .lst > li {
  display: block;
  margin-bottom: 5px;
}

footer .lst > li:nth-last-child(1) {
  margin-bottom: 0 !important;
}

footer a {
  color: #c3c3c3;
  font-size: 13px;
}

footer a:hover {
  color: #00acfc;
}

footer .smLst {
  margin: 0 -10px;
}

footer .smLst > li {
  padding: 0 10px;
}

footer .infoLst > li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px;
}

footer .infoLst > li > img {
  width: 16px;
  min-width: 16px;
  height: 16px;
  margin-top: 2px;
  margin-right: 10px;
}

footer form .txtGrp,
.txtGrp.affiliate {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #080a0f;
  margin: 10px 0 30px;
}

footer form .txtBox,
.txtGrp.affiliate .txtBox {
  padding-right: 46px;
}

.txtGrp.affiliate .txtBox {
  margin-bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

footer form button,
.txtGrp.affiliate button {
  position: absolute;
  top: 0;
  right: 16px;
  bottom: 0;
  background: transparent;
  font-size: 16px;
  padding: 0;
  line-height: 0;
  border: 0;
  cursor: pointer;
}

footer form button {
  right: 26px;
}

footer form > label {
  font-size: 12px;
  margin-bottom: 7px;
}

footer .social {
  margin: 30px -3px -3px;
}

footer .social > li {
  padding: 3px;
}

footer .social > li > a {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 38px;
  min-width: 38px;
  height: 38px;
  background: #fff;
  background: rgb(255 255 255 / 10%);
  padding: 11px;
  border-radius: 75%;
}

footer .social > li > a:hover {
  background: #00acfc;
}

footer .social > li > a > img {
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
}

/*_____ Copyright _____*/

.copyright {
  padding: 25px 0;
  margin-top: 60px;
  border-top: 1px solid rgb(255 255 255 / 7%);
  z-index: 1;
}

.copyright .inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.copyright p {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin: 0;
  font-size: 13px;
}

.copyright p > a {
  color: #00acfc;
}

.copyright p > a:hover {
  color: #fff;
}

/* ===========background_check_sec================= */
.background_check_sec {
  padding-bottom: 0 !important;
}

.background_check_sec .flex {
  margin: -10px;
}

.background_check_sec .col {
  width: 50%;
  padding: 10px;
}

.background_check_sec .col .inner {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #dddddd3d;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  overflow: hidden;
  position: relative;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  background: #f8f7fc;
}

.background_check_sec .col .inner .image {
  position: relative;
  padding-bottom: 50%;
  overflow: hidden;
  width: 50%;
}

.background_check_sec .col .inner .image img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  object-fit: cover;
}

.background_check_sec .col .inner .cntnt {
  padding: 30px;
  flex: 1;
  align-self: center;
}

.background_check_sec .col .inner .cntnt h4 {
  font-size: 20px;
  font-family: "Poppins SemiBold";
  color: rgb(24, 24, 24);
}

/* ====video block popup===== */
.popup_inner_video {
  padding: 10px;
  background-color: #fff;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  max-width: 1000px;
  margin: 0px auto;
}

.videoBlk {
  position: relative;
  display: block;
  padding-bottom: 50%;
  overflow: hidden;
  box-shadow: rgb(81 24 24 / 20%) 0 5px 10px 0;
  overflow: hidden;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}

video[poster] {
  height: 100%;
  width: 100%;
  object-position: center;
  object-fit: cover;
}

.videoBlk video {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  display: block;
  width: 100%;
  margin: 0% auto;
}

.videoBtn {
  font: normal normal normal 14px/1 FontAwesome;
  position: absolute;
  top: 47%;
  left: 0px;
  right: 0px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  height: 75px;
  width: 75px;
  margin: -20px auto 0px;
  background: rgb(255 255 255);
  color: #088fff;
  font-size: 24px;
  border: 2px solid #f3226700;
  border-radius: 75%;
  line-height: 1;
  cursor: pointer;
  -webkit-transition: all linear 0.5s;
  -o-transition: all linear 0.5s;
  transition: all linear 0.5s;
  z-index: 5;
}

.videoBtn::after {
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  left: 0;
  right: 20px;
  height: 70px;
  width: 70px;
  border-radius: 50%;
  animation: ripple-wave 1s linear infinite;
  animation-play-state: running;
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}

@keyframes ripple-wave {
  from {
    box-shadow: 0 0 rgb(255 255 255/10%), 0 0 0 15px rgb(255 255 255/10%),
      0 0 0 30px rgb(255 255 255/10%);
  }

  to {
    box-shadow: 0 0 0 15px rgba(255, 255, 255, 0.1),
      0 0 0 30px rgba(255, 255, 255, 0.1), 0 0 0 45px rgba(255, 255, 255, 0.02);
  }
}

.videoBtn.fa-pause:before {
  padding-left: 0px;
}

.videoBtn.fa-pause {
  opacity: 0;
}

.videoBlk:hover .videoBtn.fa-pause {
  opacity: 1;
}

.propert_list .col .inner .fav_icon {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgb(0 0 0 / 7%);
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  border-radius: 50px;
  font-size: 18px;
  padding-top: 5px;
  cursor: pointer;
  z-index: 2;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  color: #ff6c40;
}

.propert_list .col .inner .fav_icon:hover {
  background-color: #088fff;
  color: #fff;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.propert_list .col .inner .image_slider {
  overflow: hidden;
  position: relative;
}

/* ==========================services page================ */
.services_banner {
  /* background: url(./assets/images/bg-3.jpg);
    background-position: center!important;
    background-repeat: no-repeat!important;
    background-size: cover!important; */
  padding: 150px 0px !important;
}

/* .services_banner:after {
  position: absolute;
  content: '';
  top: 0;
  bottom: 0;
  left: 0;
 right: 0;
  background: url(./assets/images/service_overlay.png);
  background-position: right;
  background-size: cover;
  background-repeat: no-repeat;
} */
.services_banner .top_right_image {
  /* position: absolute;
  top: 0;
  right: 0;
  width: 730px;
  height: 563px; */

  position: absolute;
  top: -153px;
  right: 15px;
  width: 690px;
  height: 563px;
}

.services_banner .colL {
  width: 40%;
  padding-right: 20px;
  align-self: center;
}

.services_banner .colR {
  width: 60%;
  padding-left: 20px;
}

.page_heading {
  margin-bottom: 20px;
}

.top_left_service_top {
  position: absolute;
  top: 4%;
  width: 393px;
  left: -335px;
}

.top_right_service_top {
  position: absolute;
  top: 27%;
  width: 393px;
  right: -355px;
}

.bottom_left_service_top {
  position: absolute;
  bottom: 21%;
  width: 393px;
  left: -335px;
}

.bottom_right_service_top {
  position: absolute;
  bottom: -2%;
  width: 393px;
  right: -341px;
}

.page_heading h1 {
  text-transform: capitalize;
  font-family: "Poppins SemiBold";
  font-size: 50px;
  color: rgb(0, 0, 0);
}

.page_heading h4 {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 14px;
  color: #ff6c40;
  font-family: "Poppins Medium";
}

.services_banner p,
.page_pera p {
  font-size: 16px;
  color: rgb(24, 24, 24);
}

/* ========search_result_page============= */
.search_result_page {
  padding: 0px 0px 0px !important;
}

/* =============filters=============== */
.filter_nav {
  background-color: #ffffff;
  padding: 10px 0px;
  position: fixed;
  left: 0;
  top: 72px;
  z-index: 9;
  width: 50%;
  border-bottom: 1px solid #f5f5f5;
}

.filters {
  gap: 5px;
}

.cmn_filter_btn {
  background: #fff;
  padding: 0px 16px;
  border-radius: 50px;
  border: 1px solid #00acfc;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 29px;
  line-height: 0;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.check_cmn_filter_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 29px;
  font-size: 13px;
}

.check_cmn_filter_btn label {
  font-family: "Poppins Regular";
  margin: 0 0 0 0px;
  line-height: 0;
}

.check_cmn_filter_btn .lblBtn {
  border-top: 1px solid;
  border-bottom: 1px solid;
  padding: 5px 15px;
  height: 100%;
  border-color: #00acfc;
  cursor: pointer;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.check_cmn_filter_btn .lblBtn:nth-child(1) {
  border-top-left-radius: 50px;
  border-top: 1px solid;
  border-left: 1px solid;
  border-bottom: 1px solid;
  border-bottom-left-radius: 50px;
  height: 100%;
  padding: 5px 15px;
  border-color: #00acfc !important;
}

.check_cmn_filter_btn .lblBtn:first-child {
  border-color: #00acfc !important;
}

.check_cmn_filter_btn .lblBtn:nth-child(2) {
  border-top-right-radius: 50px;
  border-top: 1px solid;
  border-right: 1px solid;
  border-bottom: 1px solid;
  border-bottom-right-radius: 50px;
  height: 100%;
  padding: 5px 15px;
  border-color: #00acfc;
}

.check_cmn_filter_btn .lblBtn.active,
.check_cmn_filter_btn .lblBtn:hover,
.cmn_filter_btn.active,
.cmn_filter_btn:hover {
  background-color: #00acfc;
  color: #fff;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.filter_property_type_rating {
  margin: -10px;
}

.filter_property_type_rating .lblBtn {
  width: 25%;
  padding: 10px;
  color: #858585;
}

.filter_property_type_rating .lblBtn span {
  font-family: "Poppins Regular";
}

.filter_property_type_rating .lblBtn label i {
  color: #ffaa40;
}

.filters .inner-drop {
  padding: 20px;
}

.filters .inner-drop .lblBtn {
  margin-bottom: 10px;
}

.filters .inner-drop .lblBtn label {
  font-family: "Poppins Regular";
}

.filters .inner-drop .lblBtn label em {
  display: block;
  font-size: 13px;
  opacity: 0.8;
}

.filter_opt {
  margin-top: 20px;
  justify-content: space-between;
}

.filter_opt .save_opt {
  background: #008cff;
  color: #fff;
  padding: 4px 13px;
  border-radius: 50px;
  font-size: 13px;
  border: none;
}

.filter_opt .clear_opt {
  background: #000000;
  color: #fff;
  padding: 4px 13px;
  border-radius: 50px;
  font-size: 13px;
  border: none;
}

.rating_flex_filter p {
  margin-bottom: 0;
  flex: 1;
  margin-right: 20px;
}

.switchBtn {
  align-self: center;
}

.more_filters {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.more_filters .cmn_filter_btn {
  width: fit-content;
  color: #fff;
  background: #00acfc;
}

.cmn_filter_btn svg {
  align-self: center;
  margin-right: 5px;
}

.cmn_filter_btn span {
  flex: 1;
}

.listing_results {
  margin-top: 30px;
}

.listing_results .propert_list .col {
  width: 50% !important;
}

/* =============filters poppup============= */
.filter_inner h2 {
  padding-bottom: 10px;
}

.filter_inner .filt_divs {
  background: #ffffff;
  padding: 15px 0px;
  margin-bottom: 10px;
}

.filter_inner .filt_divs .filter_opt {
  margin-top: 0px;
}

.filter_inner .filt_divs > * {
  align-self: center;
}

.filter_inner .filt_divs h3 {
  margin-bottom: 10px;
  font-size: 17px;
  color: #ff6c40;
}

.filter_inner form .bTn {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.filter_inner form .bTn li {
  align-self: center;
  width: 50%;
}

.filter_inner form .bTn li:last-child {
  margin-right: 0;
  display: flex;
  justify-content: flex-end;
}

.filter_inner form .bTn li h6 {
  font-size: 16px;
  margin-right: 15px;
  margin-bottom: 0px;
  color: #333;
}

.qtyBtn {
  display: flex;
  align-items: center;
}

.qtyBtn .readBtn {
  padding: 0px;
  font-size: 22px;
  min-width: 32px;
  line-height: 0;
  background: #fff;
  color: #666 !important;
  border: 1px solid #ddd !important;
  border-radius: 50px;
  padding-top: 4px;
}

.qtyBtn input {
  width: 32px;
  height: 32px;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 50px;
}

.qtyBtn .qty {
  background: #fafafa;
  width: 32px;
  height: 32px;
  color: #2196f3;
  border: 1px solid #49a8f4;
  margin: 0px 5px;
  padding: 0px 3px;
  font-size: 16px;
  text-align: center;
  line-height: 0;
  border-radius: 50px;
}

.filters .beds_listing {
  display: flex;
  flex-wrap: wrap;
  margin: -5px;
}

.filters .beds_listing li {
  padding: 5px;
}

.cstm_label_count {
  background: #efefef;
  cursor: pointer;
  padding: 4px 12px;
  border-radius: 50px;
  font-size: 13px;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  line-height: 1;
}

.cstm_label_count:hover,
.cstm_label_count.active {
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  color: #fff;
  background-color: #49a8f4;
}

.filter_popup .crosBtn {
  color: #333 !important;
}

.filter_property_type {
  margin: -10px;
}

.filter_property_type .lblBtn {
  padding: 10px;
}

.filter_property_type .lblBtn label {
  font-family: "Poppins Regular";
  font-size: 14px;
  color: #4c4c4c;
}

.cmn_filter_btn label {
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.cmn_filter_btn label:hover {
  color: #49a8f4;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.cmn_filter_btn label.active {
  color: #49a8f4;
}

.suitability_flex .lblBtn {
  width: 25%;
}

/* ===========searching list==== */
.outer_map {
  min-height: 100%;
  overflow: hidden;
  height: 700px;
  position: relative;
}

.outer_map #map_canvas {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: inherit;
  overflow: hidden;
}

.search_result_page .listing_relative {
  position: relative;
}

.search_result_page .colL {
  width: 50%;
  min-height: 600px;
  margin-top: 100px;
  padding: 20px;
}

.filter_nav .result_header {
  justify-content: space-between;
  margin-top: 20px;
}

.filter_nav .result_header .count_result {
  align-self: center;
}

.filter_nav .result_header .count_result h6 {
  font-size: 13px;
  margin-bottom: 0;
  opacity: 0.8;
}

.filter_nav .result_header .sorted_filter .sorted_heading {
  align-self: center;
  margin-right: 10px;
  font-size: 13px;
  font-family: "Poppins Medium";
}

.filter_nav .result_header .sorted_filter select.txtBox {
  height: 30px;
  padding: 1px 5px;
  border: none;
  border-bottom: 1px solid #ddd;
  border-radius: 0;
  font-size: 13px;
}

.filter_nav .result_header .sorted_filter .sorted_heading i {
  opacity: 0.8;
}

.filter_nav .result_header .sorted_filter .sorting-options {
  display: flex;
  gap: 10px;
  /* align-self: center; */
  margin-left: 10px;
}

.filter_nav .result_header .sorted_filter .sorting-options .change-view-btn {
  color: #121b22 !important;
  border: 1px solid #121b22;
  border-radius: 2px;
  background: transparent;
  padding: 2px 9px;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  height: 30px;
  width: 35px;
}

.ascending_filter {
  margin-left: 10px;
}

.ascending_filter .change-view-btn {
  width: 35px;
  height: 30px;
  overflow: hidden;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  border: 1px solid #121b22;
  padding: 4px;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.ascending_filter .change-view-btn img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.ascending_filter .change-view-btn:hover {
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  background-color: #121b22;
}

.ascending_filter .change-view-btn:hover img {
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  filter: brightness(0) invert(1);
  -webkit-filter: brightness(0) invert(1);
}

.filter_nav .result_header .sorted_filter .sorting-options .active-view-btn,
.filter_nav
  .result_header
  .sorted_filter
  .sorting-options
  .change-view-btn:hover {
  background: #121b22 !important;
  color: #fff !important;
  padding: 2px 9px;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.search_result_page .colR {
  width: 50%;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 72px;
  z-index: 1;
}

.search_result_page .colL .propert_list.grid_view .col {
  width: 50% !important;
}

.search_result_page .colL .propert_list.list_view .col {
  width: 100% !important;
}

.search_result_page .colL .propert_list.list_view .col .inner {
  display: flex;
  flex-wrap: wrap;
}

.search_result_page .colL .propert_list.list_view .col .inner .image_slider {
  width: 40%;
  height: 100%;
}

.search_result_page
  .colL
  .propert_list.list_view
  .col
  .inner
  .image_slider
  .item {
  padding-bottom: 90%;
}

.search_result_page .colL .propert_list.list_view .col .inner .cntnt {
  flex: 1;
  padding: 30px;
  align-self: center;
}

.search_result_page .colL .propert_list.list_view .col .inner .fav_icon {
  /* right: auto; */
  /* left: 20px; */
}

.search_result_page .colL .propert_list.list_view .col.search-explore-grid {
  /* width: 50% !important */
}

.search_result_page
  .colL
  .propert_list.list_view
  .col.search-explore-grid
  .inner
  .image_slider
  .item {
  padding-bottom: 70%;
  display: block !important;
}

.search_result_page .colL .propert_list {
  margin: -20px;
}

/* ======================================logon==================== */

.cmn_logon_bg {
  background: url(./assets/images/login1.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

.cmn_logon_bg:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f6f9fcf3;
}

.logon .log_blk {
  width: 100%;
  max-width: 380px;
  margin: 0 auto;
}

.logon .log_blk h3 {
  margin-bottom: 20px;
}

.logon .log_blk .socialBtn > button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex !important;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center !important;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
  height: 40px;
  background: transparent;
  font-size: 12px !important;
  padding: 0 20px !important;
  border: 1px solid #e0e0e0 !important;
  border-radius: 4px !important;
  -webkit-transition: all ease 0.5s;
  -o-transition: all ease 0.5s;
  transition: all ease 0.5s;
  -webkit-border-radius: 4px !important;
  -moz-border-radius: 4px !important;
  -ms-border-radius: 4px !important;
  -o-border-radius: 4px !important;
  font-family: inherit !important;
  color: inherit !important;
  box-shadow: none !important;
}

.logon .log_blk .socialBtn > button > img {
  width: 18px;
  min-width: 18px;
  margin-right: 10px;
}

.logon .log_blk .socialBtn > button > div {
  min-width: 18px;
  width: 18px;
  height: 18px;
  padding: 0 !important;
}

.logon .log_blk .socialBtn > button > span {
  padding: 0 !important;
  font-weight: inherit !important;
  display: block;
}

.oRLine {
  position: relative;
  text-align: center;
  margin: 20px 0;
}
.popup .oRLine {
  z-index: 0;
}

.oRLine:before {
  content: "";
  position: absolute;
  top: 3px;
  left: 0;
  right: 0;
  bottom: 0;
  height: 1px;
  background: #e0e0e0;
  margin: auto;
}

.oRLine span {
  position: relative;
  background: #fff;
  font-size: 10px;
  padding: 0 6px 0;
  z-index: 2;
}

.log_blk > form {
  background: #fff;
  padding: 25px;
  border-radius: 7px;
  -webkit-box-shadow: 0 5px 20px rgb(18 15 56 / 7%);
  box-shadow: 0 5px 20px rgb(18 15 56 / 7%);
}

/* .log_blk > form p{
  font-family: 'Poppins SemiBold';
} */
.log_blk .haveAccount {
  margin-top: 20px;
}

.log_blk .haveAccount span.a_space {
  margin-right: 3px;
}

.log_blk .haveAccount a {
  color: #2f81f5;
}

.log_blk .haveAccount a:hover {
  color: #080a0f;
}

.log_blk #pass {
  margin-left: auto;
  font-size: 13px;
  font-family: "Poppins Medium";
  color: #5c5c5c;
}

.log_blk > form .lblBtn label {
  font-family: "Poppins Regular";
  color: #5c5c5c;
}

/* ==========service_one========= */
.service_one {
  padding: 0px !important;
  padding-bottom: 150px !important;
  margin-top: -50px;
}

.service_one .contain .flex:nth-child(even) {
  flex-direction: row-reverse;
}

.service_one .contain .flex {
  max-width: 1140px;
  margin: 0px auto;
}

.service_one .colL {
  width: 50%;
  padding: 40px 70px;
  position: relative;
}

.service_one .colR {
  width: 50%;
  align-self: center;
  padding: 40px 70px;
}

.service_one .colL .image {
  width: 100%;
  height: auto;
}

.service_one .colL img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.service_one .sec_heading h2 em {
  color: #2d8cff;
}

.service_one .start_arrow {
  max-width: 190px;
  margin: 0px auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.service_one .start_arrow .arrow_img {
  width: 75px;
  margin-left: 42px;
}

/* ==================about us page============ */
.about_banner {
  padding: 0px !important;
}

.about_banner .cntnt {
  width: 45%;
  padding: 100px 80px 100px 0px;
}

.right_about_img {
  position: absolute;
  right: 0;
  width: 50%;
  bottom: 0;
  top: 0;
  overflow: hidden;
}

.right_about_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}

.u_list ul li {
  display: block;
  margin: 7px 0px;
  padding-left: 25px;
  position: relative;
}

.u_list ul li:before {
  position: absolute;
  content: "\e92f";
  top: -3px;
  left: 0px;
  font-family: font-icon;
  font-size: 18px;
  color: #ff6c40;
}

.about_banner .bTn {
  margin-top: 30px;
}

/* ___why_choose_us___ */

.why_choose_us {
  background: url(./assets/images/bg-pattern-dot.png) 0 0 repeat !important;
}

.why_choose_us .col {
  width: calc(100% / 3);
  padding: 0px 20px;
}

.why_choose_us .col .inner {
  display: flex;
  flex-wrap: wrap;
  background-color: #ffffff;
  padding: 30px;
  -webkit-box-shadow: 0 20px 30px 0 rgb(89 181 210 / 31%);
  box-shadow: 0 20px 30px 0 rgb(89 181 210 / 31%);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  height: 100%;
}

.why_choose_us .col .inner .small_icon {
  width: 70px;
  height: 70px;
  overflow: hidden;
  position: relative;
}

.why_choose_us .col .inner .small_icon:after {
  position: absolute;
  content: "";
  top: 18px;
  left: 0;
  width: 65px;
  height: 16px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  background-color: #c9e1ffc7;
  transform: rotate(-45deg);
}

.why_choose_us .col .inner .small_icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: relative;
  z-index: 1;
}

.why_choose_us .col .inner .cntnt {
  flex: 1;
  padding-left: 20px;
}

.why_choose_us .col .inner .cntnt h4 {
  font-size: 18px;
  font-family: "Poppins Medium";
}

.why_choose_us .col .inner .cntnt p {
  font-size: 14px;
}

/* ------overview---- */
.overview {
  background: url(./assets/images/5116.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.overview:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.92);
}

.overview .col {
  width: 50%;
  padding: 10px;
  text-align: center;
}

.overview .col .small_image {
  width: 60px;
  height: 60px;
  margin: 0px auto 10px;
  overflow: hidden;
}

.overview .col .small_image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.overview .col .inner {
  background-color: #00acfc;
  padding: 20px;
  border-radius: 10px;
}

.overview .col h1 {
  color: #fff;
  font-size: 30px;
  font-family: "Poppins SemiBold";
}

.overview .col p {
  color: #ffffffc7;
}

.overview .colL {
  width: 60%;
  padding-right: 60px;
  align-self: center;
}

.overview .colL .bTn {
  margin-top: 20px;
}

.overview .colL .sec_heading {
  margin-bottom: 20px;
}

.overview .colR {
  width: 40%;
  padding-left: 10px;
}

.overview .colL h3 {
  font-size: 20px;
  color: #ff6c40;
}

.overview .colR .flex .col:nth-child(2) {
  margin-top: 50px;
}

.overview .colR .flex .col:nth-child(3) {
  margin-top: -50px;
}

/* -==== testimonials==== */

.testimonial_sec {
  padding: 0 !important;
  min-height: 500px;
}

.testimonial_sec .cntnt {
  width: 50%;
  margin-left: auto;
  padding: 80px 0px 80px 80px;
  text-align: center;
}

.testimonial_sec .left_side_image {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 50%;
  overflow: hidden;
}

.testimonial_sec .left_side_image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.testimonials .item .inner {
  text-align: center;
}

.testimonials .user_image {
  width: 70px;
  height: 70px;
  margin: 0px auto 15px;
  overflow: hidden;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  -webkit-box-shadow: 0 20px 30px 0 rgb(89 181 210 / 31%);
  box-shadow: 0 20px 30px 0 rgb(89 181 210 / 31%);
  border: 1px solid #ddd;
}

.testimonials .user_image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.testimonials .item .inner h4 {
  font-size: 16px;
  font-family: "Poppins Bold";
  margin-bottom: 5px;
}

.testimonials .item .inner h6 {
  font-size: 13px;
  color: #ff6c40;
}

.testimonials .item .inner .rating_star {
  color: #fcc206;
  margin-bottom: 10px;
}

.testimonials .item .inner .quote_per {
  font-style: italic;
  color: #898989;
}

/* === trusted_partners*/
.trusted_partners {
  background: #f8f7fc;
  border-top: 1px solid #dddddd3d;
}

.trusted_partners .item .image {
  width: 100%;
  height: 100px;
  overflow: hidden;
}

.trusted_partners .item .image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  opacity: 0.7;
  background: transparent;
  cursor: pointer;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.trusted_partners .item .image img:hover {
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  opacity: 1;
}

/* ___contact us page___ */
/* .contact_page {
  background: url(./assets/images/map.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
} */

.contact_page:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: radial-gradient(transparent, #f5f5f5);
}

.contact_page .contain > .flex {
  max-width: 1000px;
  margin: 0px auto;
  background-color: #fff;
  overflow: hidden;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.contact_page .colL {
  width: 60%;
  padding: 50px 50px;
}

.contact_page .colR {
  width: 40%;
  background: url(./assets/images/s1.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  padding: 50px 50px;
  color: #fff;
}

.contact_page .colR:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgb(255 108 64 / 93%);
}

.contact_page .form_blk {
  margin-bottom: 15px;
  position: relative;
}

.contact_page .page_heading {
  width: 50%;
  margin: 0px auto 40px;
  text-align: center;
}

.contact_page .form_cntnt {
  margin-bottom: 40px;
}

.contact_page .colR ul li {
  display: flex;
  margin: 10px 0px;
  flex-wrap: wrap;
}

.contact_page .colR ul li i {
  font-size: 16px;
  color: #ff6c40;
  margin-right: 10px;
  width: 30px;
  height: 30px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
}

.contact_page .colR ul {
  margin-bottom: 30px;
}

.contact_page .colR ul li .cntact_lnks {
  flex: 1;
  color: #fff;
  align-self: center;
}

.contact_page .colR * {
  position: relative;
  z-index: 1;
}

.contact_page .colR .social {
  position: absolute;
  bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  right: 0;
}

.contact_page .colR .social > li {
  padding: 3px;
}

.contact_page .colR .social > li > a {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 38px;
  min-width: 38px;
  height: 38px;
  background: #fff;
  padding: 11px;
  border-radius: 75%;
}

.contact_page .colR .social > li > a > img {
  -webkit-filter: brightness(0) invert(0);
  filter: brightness(0) invert(0);
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.contact_page .colR .social > li > a:hover {
  background: #00acfc;
}

.contact_page .colR .social > li > a:hover img {
  filter: brightness(0) invert(1);
  -webkit-filter: brightness(0) invert(1);
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.contact_page .colL .txtBox::placeholder {
  font-size: 12px;
  color: rgb(150, 150, 150);
  font-family: "Poppins Medium";
}

.contact_page .colL .txtBox {
  background: #f4fbff;
}

.contact_page .colR .webBtn {
  background-color: #000 !important;
  width: 100%;
}

/* ___map__ */
.map_sec {
  padding: 0 !important;
}

.map_sec .map {
  min-height: 100%;
  overflow: hidden;
  height: 500px;
  position: relative;
}

.map_sec .map #map_canvas {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: inherit;
  overflow: hidden;
}

/* ==================textual_page================= */
.textual_page .text_blk {
  max-width: 1000px;
  margin: 0px auto;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  padding: 30px;
  background-color: #fff;
  box-shadow: 0px 0px 20px rgb(202, 202, 202);
}

.textual_page .text_blk .sec_heading {
  margin-bottom: 0px;
}

/* ==========customer support==== */
.help_banner {
  background: url(./assets/images/5116.jpg);
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.help_banner:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.61);
}

.help_banner .cntnt {
  max-width: 600px;
  margin: 0px auto;
}

.support_banner form {
  position: relative;
  margin-top: 20px;
}

.support_banner .srchBar > .txtBox {
  height: 60px;
  background: rgb(255 255 255);
  color: #080a0f;
  padding-left: 60px;
  border-color: rgb(243 249 253);
  border-radius: 7px;
}

.support_banner .srchBar > button {
  position: absolute;
  top: 0;
  left: 14px;
  bottom: 0;
  background: transparent;
  padding: 0;
  margin: auto;
  border: 0;
  font-size: 22px;
  line-height: 0;
}

.help_banner .page_heading h1 {
  color: #fff;
}

.customer_support .blk_support .flex {
  margin: -10px;
  justify-content: center;
}

.customer_support .blk_support:not(:last-child) {
  margin-bottom: 60px;
}

.customer_support .blk_support .col {
  width: calc(100% / 4);
  padding: 10px;
}

.customer_support .blk_support .col .inner {
  width: 100%;
  background: #ffffff;
  padding: 25px 20px;
  box-shadow: 0px 15px 38px rgb(0 0 0 / 10%);
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.customer_support .blk_support .col .inner span {
  width: 30px;
  /* height: 30px; */
  font-size: 30px;
  color: #0071bd;
}

.customer_support .blk_support .col .inner .cntnt {
  flex: 1;
}

.customer_support .blk_support .col .inner .cntnt h3 {
  color: #0071bd;
  font-size: 18px;
  font-family: "Poppins SemiBold";
}

.customer_support .blk_support .col .inner .cntnt p {
  color: #333;
}

.customer_support .blk_support .col .inner:hover {
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  border-left-color: #fff;
}

/* ================faq page==== */
.faq_banner {
  padding-bottom: 40px !important;
}

.outer_faq {
  max-width: 900px;
  margin: 0px auto 40px;
}

.outer_faq h3 {
  font-family: "Poppins SemiBold";
  color: #000;
  font-size: 20px;
}

.faqBox > .faqLst:not(:nth-last-child(1)) {
  margin-bottom: 40px;
}

.faqLst > .faqBlk {
  position: relative;
  display: block;
  background: #f8f7fc;
  padding: 20px 0;
  margin-bottom: 10px;
  border-radius: 10px;
  transition: all ease 0.5s;
  border: 1px solid #dddddd3d;
}

.faqLst > .faqBlk:nth-last-child(1) {
  margin-bottom: 0;
}

.faqLst > .faqBlk h5 {
  position: relative;
  padding: 0 40px 0 25px;
  margin: 0;
  cursor: pointer;
  transition: all ease 0.5s;
  z-index: 2;
  font-family: "Poppins SemiBold";
}

.faqLst > .faqBlk h5:after {
  content: "\f107";
  position: absolute;
  top: -2px;
  right: 20px;
  font-size: 20px;
  font-family: "FontAwesome";
  font-weight: bold;
}

.faqLst > .faqBlk.active h5:after {
  content: "\f106";
}

.faqLst > .faqBlk h5:hover {
  color: #0071bd;
}

.faqLst > .faqBlk .txt {
  /* display: none; */
  color: #252525;
  margin: 0 0 -20px;
  padding: 20px 25px;
}

/* .faqLst>.faqBlk.active .txt {
  display: block;
} */

.nav_right {
  top: 0;
  width: auto;
  bottom: 0;
  display: flex;
}

.contain_search {
  padding: 0 15px;
  margin: auto;
  min-height: 1px;
  z-index: 3;
  position: relative;
}

/* ____Search Property ____ */

.detail_review_star {
  display: flex;
  flex-wrap: wrap;
  align-self: center;
  background: #f7f7f7;
  width: fit-content;
  padding: 3px 15px;
  border-radius: 30px;
  margin-top: -5px;
  margin-left: 13px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
}

.detail_review_star i {
  color: #ff6c40;
  font-size: 15px;
  margin-right: 5px;
  align-self: center;
}

.detail_review_star span {
  color: #000;
}

.search-detail-grid .result_gallery {
  border-radius: 35px;
  overflow: hidden;
  position: relative;
  margin: 40px 0px;
  -webkit-border-radius: 35px;
  -moz-border-radius: 35px;
  -ms-border-radius: 35px;
  -o-border-radius: 35px;
}

.search-detail-grid .result_gallery .imgGallery {
  overflow: hidden;
  margin: -10px;
  /* width: 100%; */
  justify-content: center;
}

.search-detail-grid .result_gallery .imgGallery .lg-react-element,
.view_property_gallery .imgGallery .lg-react-element {
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 400px;
  overflow: hidden;
}

.search-detail-grid .result_gallery .imgGallery .col {
  overflow: hidden;
  height: 200px;
}

.search-detail-grid .result_gallery .imgGallery .col {
  width: calc(100% / 5);
  padding: 10px;
}

.search-detail-grid .result_gallery .imgGallery .col img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -webkit-transform: rotateY(0deg) rotateX(0deg);
  transform: rotateY(0deg) rotateX(0deg);
}

.search-detail-grid .result_gallery .imgGallery .col:hover img {
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  box-shadow: 0 1px 6px rgb(0 0 0 / 12%), 0 1px 4px rgb(0 0 0 / 24%);
  transform: perspective(75em) rotateX(18deg);
}

.result_gallery .bTn {
  margin: 20px 0px;
  position: absolute;
  bottom: 17px;
  right: 30px;
}

.result_gallery .bTn .webBtn {
  background: #fff;
  color: #000;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  height: 40px;
  border: 1px solid #000;
  padding: 0 20px;
  font-size: 12px;
}

.detail_place_estimate {
  gap: 10px;
}

.detail_place_estimate li {
  position: relative;
  font-size: 14px;
  color: #8f8f8f;
}

.detail_place_estimate li:not(:first-child) {
  padding-left: 10px;
}

.detail_place_estimate li:not(:first-child):before {
  position: absolute;
  content: "";
  left: -3px;
  top: 9px;
  width: 5px;
  height: 5px;
  background-color: #8f8f8f;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
}

.result_detail_blk {
  max-width: 1000px;
  margin: 0px auto;
}

.detail_heading {
  margin-bottom: 20px;
  font-size: 20px;
}

.icon_listing li {
  /* display: flex;
  flex-wrap: wrap;
  gap: 10px; */
  width: calc(100% / 5);
  margin: 4px 0px;
  font-family: "Poppins Medium";
  position: relative;
  padding-left: 25px;
}

/* .icon_listing li .img_icon {
  width: 32px;
  overflow: hidden;
  align-self: center;
}

.icon_listing li .img_icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.icon_listing li span {
  flex: 1;
  align-self: center;
} */

.property_detail_main_sr {
  padding-top: 0px;
}

.icon_listing li:before {
  position: absolute;
  content: "\e92f";
  top: -3px;
  left: 0px;
  font-family: font-icon;
  font-size: 18px;
  color: #ff6c40;
}

.property_detail_main_sr .view_propert_detail {
  position: fixed;
  left: 0px;
  right: 0;
  background: #ffffff;
  padding: 28px 20px;
  z-index: 8;
  border-bottom: 1px solid #f7f7f7;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.property_detail_main_sr .view_propert_detail.small_head {
  padding: 10px 20px;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.property_detail_main_sr .search-detail-grid {
  margin-top: 150px;
}

.result_detail_blk .main_detail_head .left_info {
  flex: 1;
}

.result_detail_blk .main_detail_head .right_info {
  align-self: center;
}

.result_detail_blk .main_detail_head .right_info .mini_btn,
.cstm_mini_search {
  margin-left: 5px;
  background: #ffffff;
  padding: 3px 10px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid #a2a8b5;
  color: #6e6e6e;
}

.result_detail_blk .main_detail_head .right_info .mini_btn:hover,
.result_detail_blk .main_detail_head .right_info .mini_btn.active,
.cstm_mini_search:hover,
.cstm_mini_search.active {
  background: #00acfc;
  color: #fff;
  border: 1px solid #00acfc;
}

.cstm_mini_search_vertical {
  align-self: center;
}

.feature_lising li {
  width: calc(100% / 5);
  margin: 4px 0px;
  font-family: "Poppins Medium";
  position: relative;
}

.feature_lising li h6 {
  margin-bottom: 2px;
  /* font-family: 'Poppins Medium'; */
  font-size: 12px;
  color: #8f8f8f;
}

.outer_map_detail {
  margin-top: 20px;
  min-height: 100%;
  overflow: hidden;
  border-radius: 6px;
  height: 400px;
  position: relative;
}

.outer_map_detail #map_canvas {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: inherit;
  overflow: hidden;
}

.property_detail_main_sr hr {
  margin-top: 30px;
  margin-bottom: 30px;
}

/* ___review___ */

.review_read_blk {
  background-color: #f8f8f8;
  border-radius: 15px;
  padding: 20px;
}

.review_heading ul.all_rating {
  /* margin:-10px; */
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  border-top: 1px solid #0000001a;
  /* border-bottom: 1px solid #0000000a; */
  padding-bottom: 5px;
}

.review_heading ul.all_rating li {
  width: calc(100% / 5);
  padding: 10px;
  font-size: 13px;
}

.review_heading li {
  font-size: 16px;
}

.review_heading li i {
  color: #088fff;
}

.review_blk_listing {
  margin-top: 20px;
}

.review_blk_listing li {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  background: #ffffff;
  padding: 10px;
  border-radius: 15px;
}

.review_blk_listing li .image_icon {
  width: 60px;
  height: 60px;
  overflow: hidden;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  margin-right: 15px;
}

.review_blk_listing li .image_icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.review_blk_listing li .client_info {
  flex: 1;
  align-self: center;
}

.intro_review_left {
  flex: 1;
  margin-right: 10px;
}

.review_blk_listing li .client_info h4 {
  margin-bottom: 5px;
  font-size: 15px;
  display: flex;
  gap: 5px;
}

.review_blk_listing li .client_info h6 {
  margin-bottom: 0;
  opacity: 0.7;
  font-size: 11px;
}

._comment {
  width: 100%;
  margin-top: 7px;
}

._comment p {
  font-size: 14px;
  opacity: 0.8;
  font-style: italic;
}

/* ____heading_popup_property___ */

.heading_popup_property {
  position: fixed;
  top: 0;
  left: 20px;
  width: 100%;
  background: #fff;
  z-index: 99;
  padding: 10px 0;
}

.gallery_pop .col {
  /* width: calc(100% / 4);
  padding: 10px;
  height: 300px;
  overflow: hidden; */
  width: calc(100% / 5);
  padding: 10px;
  overflow: hidden;
  height: 190px;
}

.gallery_pop .imgGallery .lg-react-element {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
}

.gallery_pop .col img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.gallery_pop .col {
  position: relative;
}

.gallery_pop .col span {
  position: absolute;
  bottom: 18px;
  right: 18px;
  padding: 2px 8px;
  border-radius: 4px;
  cursor: default;
  background-color: rgb(59, 65, 68);
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  font-size: 12px;
  line-height: 1.33;
}

.property_heading_popup {
  flex: 1;
  margin-left: 20px;
}

.property_heading_popup h3 {
  margin-bottom: 5px;
  font-family: "Poppins Medium";
}

.gallery_category_heading {
  margin: 10px;
  font-size: 18px;
  text-align: center;
}

/* _____offer_send_sec____ */
/* .offer_send_flex_outer{
  max-width:700px;
    margin: 0px auto;
    position: relative;
} */
.offer_send_sec {
  padding: 40px 0px;
  background: #fbfbfb;
}

.offer_send_sec .colL {
  max-width: 400px;
  padding-bottom: 20px;
  position: relative;
}

.multip_step_form_offer .option_lbl ul li {
  width: 130px;
}

.offer_send_sec .landlord_card {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  overflow: hidden;
}

.offer_send_sec .landlord_card .main_heading h3 {
  font-size: 18px;
  margin-bottom: 0;
}

.offer_send_sec .landlord_card .main_heading {
  margin: -20px;
  margin-bottom: 20px;
  background: #00acfc;
  padding: 10px 20px;
  border-bottom: 1px solid #dddddd63;
  color: #fff;
}

.offer_send_sec .landlord_card .flex .image_icon {
  width: 60px;
  height: 60px;
  overflow: hidden;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.offer_send_sec .landlord_card .flex .image_icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.offer_send_sec .landlord_card .flex .txt {
  flex: 1;
  margin-left: 10px;
  align-self: center;
}

.offer_send_sec .landlord_card .flex .txt h5 {
  margin-bottom: 5px;
  font-family: "Poppins Medium";
}

.offer_send_sec .landlord_card .flex .txt p {
  color: #787878;
  font-size: 13px;
}

.offer_send_confirm {
  gap: 10px;
}

.offer_send_confirm li {
  display: flex;
  justify-content: space-between;
  width: 49% !important;
  font-size: 14px;
  margin-bottom: 0 !important;
}

.offer_send_confirm li strong {
  flex: 1;
  align-self: center;
}

.property_card.propert_list .col {
  width: 100%;
}

.property_card.propert_list .col .inner {
  box-shadow: none;
}

.offer_send_confirm li em {
  flex: 2;
  text-align: right;
  align-self: center;
}

.add_more_tenats {
  cursor: pointer;
  margin-bottom: 15px;
  font-size: 13px;
  background-color: #ff6c40;
  width: fit-content;
  /* padding: 3px 15px; */
  border-radius: 50px;
  color: #fff;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2px;
}

.add_more_tenats:hover {
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  background-color: #088fff;
}

.tenants_lbl span {
  font-family: "Poppins Medium";
  font-size: 12px;
  color: #818181;
  background: #f9f9f9;
  padding: 2px 10px;
  border-radius: 6px;
  /* margin-top: 5px; */
  margin-right: 5px;
  border: 1px solid #dbe9ef;
}

.offer_flex_grid_form {
  margin: -10px;
}

.offer_flex_grid_form li {
  width: calc(100% / 3);
  padding: 10px;
}

.offer_flex_grid_form li h5 {
  margin-bottom: 3px;
  color: #7a7a7a;
  font-size: 13px;
}

.offer_flex_grid_form li p {
  font-size: 13px;
}

.ofer_heading {
  font-size: 18px;
  color: #ff6c40;
  font-family: "Poppins Medium";
}

.mini_offer_main_price {
  max-width: 400px;
  margin: 20px auto;
  /* background: #fbfbfb; */
  border: 1px solid #e2f6ff;
}

.mini_offer_main_price li {
  width: 100% !important;
  padding: 10px !important;
  border-bottom: 1px solid #e2f6ff !important;
  font-size: 15px !important;
}

.mini_offer_main_price li:last-child {
  border-bottom: none !important;
  background: #fbfbfb;
}

.mini_offer_main_price li:last-child em {
  font-family: "Poppins SemiBold" !important;
}

.cmn_bt_mrgn {
  margin-bottom: 25px;
}

.medium_blk {
  border: 1px solid #e2f6ff;
  padding: 20px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  background: #ffffff;
}

.ofer_pkg_tbl {
  display: flex;
  flex-wrap: wrap;
}

.ofer_pkg_tbl li {
  width: 50%;
  padding: 5px 10px;
}

.ofer_pkg_tbl li[disabled] {
  opacity: 0.4;
}

.ofer_pkg_tbl li h5 {
  font-family: "Poppins Bold";
}

.ofer_pkg_tbl li label {
  font-family: "Poppins Regular";
}

.multip_step_form_offer {
  border: 1px solid #e2f6ff;
  padding: 20px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  background: #ffffff;
}

/* .multip_step_form_offer .option_lbl ul li{
  width: 200px;
} */
.multip_step_form_offer .option_lbl ul:before {
  right: 7%;
  left: 7%;
}

.offering_send_btns {
  margin-top: 20px;
}

.multip_step_form_offer fieldset .offering_send_btns {
  display: flex;
  flex-flow: wrap;
  gap: 5px;
  justify-content: space-between;
}

.multip_step_form_offer fieldset .offering_send_btns.bTn_single {
  justify-content: center;
}

.multip_step_form_offer fieldset {
  margin-top: 60px;
  display: none;
}

.multip_step_form_offer fieldset:nth-child(2) {
  display: block;
}

.cmn_bt_mrgn_small {
  margin-bottom: 15px;
}

.offer_send_flex_outer .total_count_price_listing {
  flex-direction: column;
  align-items: center;
}

.offer_send_flex_outer .total_count_price_listing li:nth-child(even) {
  padding-left: 0px;
}

.offer_send_flex_outer .total_count_price_listing li:nth-child(odd) {
  padding-right: 0px;
  border-right: none;
}

.offer_send_flex_outer .total_count_price_listing li:nth-last-child(1),
.offer_send_flex_outer .total_count_price_listing li:nth-last-child(2) {
  border-bottom: 1px solid#e5e5e585;
}

.divide_offer_flex .inner_colL {
  width: 25%;
  padding-right: 10px;
}

.divide_offer_flex .inner_colR {
  width: 75%;
  padding-left: 10px;
}

/* ======blog page====== */
.blog_page .colL {
  width: 75%;
  padding-right: 10px;
}

.blog_page .colR {
  width: 25%;
  padding-left: 10px;
}

.blog_page .ctgryBlk {
  background: #fff;
  padding: 20px 20px 10px;
  margin-bottom: 30px;
  border-radius: 10px;
  -webkit-box-shadow: 0 13px 27px -5px rgb(50 50 93 / 25%),
    0 8px 16px -8px rgb(0 0 0 / 30%), 0 -6px 16px -6px rgb(0 0 0 / 3%);
  box-shadow: 0 13px 27px -5px rgb(50 50 93 / 25%),
    0 8px 16px -8px rgb(0 0 0 / 30%), 0 -6px 16px -6px rgb(0 0 0 / 3%);
  overflow: hidden;
}

.blog_page .colR h4 {
  margin-bottom: 20px;
  font-size: 17px;
  font-family: "Poppins Medium";
}

.blog_page .colR .ctgryBlk .ctgryLst > li {
  display: list-item;
  list-style-position: inside;
  line-height: 1.2;
  margin-bottom: 8px;
}

.blog_page .colR .ctgryBlk .ctgryLst > li > a {
  display: inline;
  color: #333;
}

.blog_side_blk {
  margin-bottom: 30px;
}

.blog_side_blk ul li {
  display: flex;
  flex-wrap: wrap;
}

.blog_side_blk ul li:not(:last-child) {
  margin-bottom: 15px;
}

.blog_side_blk ul li .small_image {
  display: block;
  width: 60px;
  height: 60px;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  overflow: hidden;
}

.blog_side_blk ul li .small_image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.blog_side_blk ul li .txt {
  flex: 1;
  margin-left: 10px;
  align-self: center;
}

.blog_side_blk ul li .txt h5 {
  font-size: 14px;
  font-family: "Poppins Medium";
  margin-bottom: 4px;
}

.blog_date {
  font-size: 12px;
  font-family: "Poppins Medium";
  color: #ababab;
}

.tags_list_blog ul {
  display: flex;
  flex-wrap: wrap;
}

.tags_list_blog ul li a {
  margin-right: 5px;
  margin-bottom: 5px;
  padding: 5px 10px;
  border-radius: 2px;
  color: #a6a3ba;
  font-size: 12px;
  border: 1px solid #e6ebf1;
}

.blog_page .colL .inner_blog {
  display: flex;
  flex-wrap: wrap;
  margin: -10px;
}

.blog_page .colL .inner_blog .col {
  width: calc(100% / 3);
  padding: 10px;
}

.blog_page .colL .inner_blog .col .inner .image {
  display: block;
  position: relative;
  background: #fff;
  border-radius: 7px;
  -webkit-box-shadow: 0 3px 15px 0 rgb(0 0 0 / 5%);
  box-shadow: 0 3px 15px 0 rgb(0 0 0 / 5%);
  overflow: hidden;
  padding-bottom: 50%;
}

.blog_page .colL .inner_blog .col .inner .image img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.blog_page .colL .inner_blog .inner .txt {
  padding: 10px 0 0;
}

.blog_page .txt .ctgry {
  color: #00acfc;
  margin-bottom: 5px;
  font-size: 13px;
}

.blog_page .colL .inner_blog .inner .txt h5 {
  margin-bottom: 5px;
}

.blog_page .colL .inner_blog .inner .txt h5 a {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  max-height: 90px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  transition: all 0.5s ease;
}

.blog_page .colL .inner_blog .inner .txt p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  max-height: 90px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  transition: all 0.5s ease;
  font-size: 13px;
  margin-bottom: 0;
  margin-top: 5px;
}

.featured_posts {
  margin-bottom: 40px;
}

.featured_posts .flex {
  margin: -10px;
}

.featured_posts .col_feature {
  width: 50%;
  padding: 10px;
}

.featured_posts .col_feature .inner {
  display: flex;
  flex-wrap: wrap;
}

.blog_page {
  padding: 40px 0px !important;
}

.featured_posts .col_feature .inner .image_feature {
  display: block;
  width: 300px;
  padding-bottom: 30%;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.featured_posts .col_feature .inner .image_feature img {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  object-fit: cover;
}

.featured_posts .col_feature .inner .txt {
  flex: 1;
  margin-left: 20px;
  align-self: center;
}

.featured_posts .col_feature .inner .txt h4 {
  margin-bottom: 5px;
}

.featured_posts .col_feature .inner .txt .blog_date {
  margin-bottom: 5px;
}

.featured_posts .col_feature .inner .txt h4 a {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  max-height: 90px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  transition: all 0.5s ease;
}

.featured_posts .col_feature .inner .txt p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  max-height: 90px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  transition: all 0.5s ease;
  font-size: 13px;
  margin-bottom: 0;
}

/* ===============blog_flex============= */
.blog_flex .blog_detail_blk {
  border: 3px solid rgb(241 241 241 / 54%);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  overflow: hidden;
}

.blog_flex .blog_detail_blk .image {
  width: 100%;
  padding-bottom: 60%;
  overflow: hidden;
  position: relative;
}

.blog_flex .blog_detail_blk .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.blog_flex .blog_detail_blk .ckEditor {
  padding: 0px 20px 20px;
}

.blog_flex .blog_detail_blk .ctgry {
  color: #00acfc;
  font-size: 13px;
}

.imp_info {
  padding: 20px;
  display: flex;
  gap: 10px;
  padding-bottom: 10px;
}

.imp_info * {
  align-self: center;
}

.imp_info span {
  color: #ddd;
}

.blog_flex .blog_detail_blk ul {
  padding-left: 0;
}

/* ----share_opt---- */
.share_opt {
  display: flex;
  gap: 20px;
  max-width: 350px;
  margin: 25px 0 0px;
}

.share_opt h4 {
  margin-bottom: 0;
  align-self: center;
}

.share_opt .social li {
  padding: 3px;
}

.share_opt .social li a {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 38px;
  min-width: 38px;
  height: 38px;
  background: rgb(0 172 252);
  padding: 11px;
  border-radius: 75%;
}

.share_opt .social li a > img {
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
}

.verify_phone_popup .phoneLst {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 320px;
  margin: 20px auto;
}

.verify_phone_popup .phoneLst > li {
  position: relative;
  padding: 4px;
}

.verify_phone_popup .phoneLst > li .txtBox {
  font-size: 16px;
  padding: 5px 10px 6px;
  text-align: center;
}

.otp_fields input {
  display: block;
  width: 100% !important;
  height: 46px !important;
  background: #fefefe;
  border: 1px solid #eee;
  border-radius: 6px;
  outline: none !important;
  transition: all ease 0.5s;

  font-size: 16px;
  padding: 5px 10px 6px;
  text-align: center !important;
}

.verify_phone_popup .phoneLst > li:nth-child(3):after {
  content: "-";
  position: absolute;
  top: 50%;
  right: -10px;
  margin-top: -7px;
  line-height: 1;
}

.verify_phone_popup .phoneLst > li:nth-child(3) {
  margin-right: 14px;
}

/* _____________number_views_property________ */
.number_views_property {
  background: #ffffff;
  border-radius: 50px;
  padding: 3px 15px;
  height: 30px;
  align-self: center;
  margin-top: -5px;
  font-size: 14px;
  color: #6a6a6a;
  border: 1px solid #9d9d9d;
  margin-left: 8px;
}

.view_offering_blk {
  position: relative;
  background: #f7f7f7;
  padding: 10px;
  border-radius: 7px;
  max-width: 430px;
  margin-top: 10px;
}

.view_offering_blk ul {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.view_offering_blk ul li {
  /* flex: 1; */
  /* text-align: center; */
  align-self: center;
}

.view_offering_blk ul li h5 {
  width: 100%;
  font-size: 13px;
  font-family: "Poppins SemiBold";
  color: #999;
  margin-bottom: 5px;
}

.view_offering_blk ul li div {
  font-family: "Poppins SemiBold";
  font-size: 13px;
}

.view_offering_blk ul li .view_popup_offer_mini {
  background: #ff6c40;
  height: 30px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  padding: 3px 11px;
  color: #fff;
}

.result_detail_blk .main_detail_head .view_actions {
  align-self: center;
}

.view_offer_popup ._inner .blockLst {
  margin: 0px;
  background: #fbfbfb;
  border: 1px solid #eee;
  box-shadow: none;
}

.view_offer_popup ._inner .blockLst table tr > * {
  padding: 8px 12px;
}

.space_between_flex {
  display: flex;
  justify-content: space-between;
  margin-bottom: 18px;
}

.space_between_flex li.review_star {
  align-self: center;
}

.space_between_flex li.review_star span {
  margin-left: 4px;
}

.space_between_flex li .webBtn {
  height: 40px;
  padding: 3px 23px;
}

.blue_check_icon {
  color: #088fff;
}

/* _________partners__________ */
.partner_banner {
  background: url(./assets/images/login1.jpg);
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.partner_banner:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.411);
}

.partner_banner .page_heading {
  margin-bottom: 0;
}

.partner_sec .bTn {
  margin-top: 40px;
  justify-content: center;
}

.partner_banner .page_heading h1 {
  margin-bottom: 0;
  color: #fff;
}

.partner_sec .part_cntnt {
  max-width: 700px;
  margin: 0px auto 40px;
}

.partner_sec .colL {
  width: 25%;
  align-self: center;
}

.partner_sec .colR {
  width: 75%;
  padding: 20px;
  background: #f5f5f594;
  /* align-self: center; */
}

.partner_sec_icon {
  background-color: #fff;
}

.partner_sec .flex {
  justify-content: center;
  margin: -20px;
}

.partner_sec ._col {
  width: 50%;
  padding: 20px;
}

.partner_sec .colL .company_logo {
  width: 100px;
  overflow: hidden;
  padding: 16px;
  height: 100px;
  margin: 0px auto;
}

.partner_sec ._col .inner {
  display: flex;
  flex-wrap: wrap;
  box-shadow: 0px 10px 10px 0px #ddd;
  /*padding: 20px;
    */
  border-radius: 10px;
  border-top: 2px solid #ff6c40;
  overflow: hidden;
  position: relative;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  height: 100%;
}

.partner_sec ._col .inner:hover {
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  box-shadow: none;
}

.partner_sec ._col .inner a {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
}

/* ________mission__ */
/* ==========we-offer=============== */

.we-offer {
  background: #f8f7fc;
}

.we-offer .colL {
  width: 35%;
  padding-right: 40px;
  align-self: center;
}

.we-offer .colR {
  width: 65%;
  padding-left: 30px;
}

.we-offer .colR ul {
  margin: -10px;
}

.we-offer .colR ul li {
  width: 50%;
  padding: 10px;
}

.we-offer .colR ul li ._inner {
  box-shadow: rgb(81 24 24 / 20%) 0 5px 10px 0;
  padding: 20px;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  background: #fff;
}

.we-offer .colR ul li .icon-img {
  width: 35px;
  height: 35px;
  overflow: hidden;
  background: #00acfc;
  border-radius: 50px;
  padding: 5px;
}

.we-offer .colR ul li .icon-img img {
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: contain;
  filter: invert(1);
}

.we-offer .colR ul li ._inner .inner-cntnt {
  flex: 1;
  margin-left: 15px;
}

.mission_vision .col {
  width: 50%;
  padding: 20px;
}

.mission_vision .flex {
  margin: -20px;
}

.mission_vision .col:first-child {
  text-align: right;
}

.mission_vision .col h3 {
  font-family: "Poppins SemiBold";
}

.mini_banner_inner .blk_banner {
  background-image: url(./assets/images/house_png_2-e1646989822282.png);
  background-position: right;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #f8f7fc;
  overflow: hidden;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  padding: 60px;
}

.mini_banner_inner .blk_banner .cntnt {
  max-width: 600px;
}

.color__new {
  color: #ea4d2d;
  font-family: "Poppins Medium";
}

.home_tenant_check {
  width: 20px;
  align-self: center;
}

.goals_sec {
  padding-top: 0px !important;
}

.mission_cn_p {
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.goals_sec .colL {
  width: 50%;
  padding-right: 20px;
  height: 480px;
}

.goals_sec .colR {
  width: 50%;
  padding-left: 20px;
  align-self: center;
}

.goals_sec .colL ul {
  position: relative;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  height: 100%;
}

.goals_sec .colL ul li {
  width: 35%;
  height: 200px;
  position: relative;
  /* clip-path: polygon(50% 0%, 0% 100%, 100% 100%); */
  background-color: #00acfc;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding-top: 40px; */
  color: #fff;
  font-size: 18px;
}

.goals_sec .colL ul li:not(:last-child) {
  margin-bottom: 50px;
}

.goals_sec .colL ul li:first-child {
  transform: rotate(43deg);
  position: absolute;
  left: 87px;
  clip-path: polygon(0 50%, 100% 100%, 100% 0);
  top: -1px;
  padding-left: 50px;
}

.goals_sec .colL ul li:nth-child(2) {
  transform: rotate(-25deg);
  position: absolute;
  left: 377px;
  top: 45px;
  clip-path: polygon(0 0, 0 100%, 100% 50%);
  -webkit-transform: rotate(-25deg);
  -moz-transform: rotate(-25deg);
  -ms-transform: rotate(-25deg);
  -o-transform: rotate(-25deg);
  padding-right: 50px;
}

.goals_sec .colL ul li:nth-child(3) {
  clip-path: polygon(0 0, 50% 100%, 100% 0);
  position: absolute;
  bottom: 18px;
  transform: rotate(9deg);
  left: 194px;
  padding-bottom: 33px;
}

.goals_sec .colL ul li:nth-child(3) span {
  transform: rotate(-89deg);
  -webkit-transform: rotate(-89deg);
  -moz-transform: rotate(-89deg);
  -ms-transform: rotate(-89deg);
  -o-transform: rotate(-89deg);
}

.goals_sec .goal_slider .item {
  padding: 10px;
}

.goals_sec .goal_slider .inner {
  box-shadow: 0px 2px 10px 0px #ddd;
  padding: 20px;
  border-radius: 10px;
  height: 315px;
  border-left: 1px solid #00acfc;
}

.goals_sec .goal_slider .inner h4 {
  color: #00acfc;
}

.header_commercial_search_pg {
  display: flex;
  justify-content: space-between;
}

.header_commercial_search_pg .address_commercial {
  /* font-family: 'Poppins SemiBold'; */
  color: #999;
}

.header_commercial_search_pg .cstm_mini_search_vertical .bTn {
  align-self: center;
  margin-left: 10px;
}

.header_commercial_search_pg .cstm_mini_search_vertical .bTn .mini_web_btn {
  width: 34px;
  height: 34px;
}

.search_expense_com_main {
  display: flex;
  flex-wrap: wrap;
  margin: -10px;
  justify-content: center;
}

.search_expense_com_main .colL {
  width: 50% !important;
  padding: 10px;
}

.search_pro_commercial .colL .inner {
  background-color: transparent;
  border: none;
}

.search_pro_commercial .inner .balance_lst:not(:last-child) {
  border-bottom: 1px solid #eef0f1 !important;
}

.search_pro_commercial .inner .balance_lst {
  padding: 5px 0px !important;
  font-size: 14px !important;
}

.search_toggle_com.invoice_toggle_outer
  .invoice_toggle
  > ul
  > li
  .specific_flex_span
  span {
  /* font-size: 14px; */
  display: flex;
  flex-wrap: wrap;
  position: relative;
  align-self: center;
  /* color: #848484; */
}

.search_toggle_com.invoice_toggle_outer
  .invoice_toggle
  > ul
  > li
  .specific_flex_span
  span:not(:first-child):before {
  position: absolute;
  content: "";
  left: -5px;
  top: 1px;
  width: 1px;
  height: 20px;
  background-color: #8080808a;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
}

.listing_list_c_credit_com .lst > ul > li.property_size_li {
  width: 200px !important;
}

.floorBlkTbl .specific_flex_span {
  display: flex;
  flex-wrap: nowrap;
  gap: 10px;
}

.floorBlkTbl .specific_flex_span span {
  position: relative;
}

.floorBlkTbl .specific_flex_span span:not(:first-child):before {
  position: absolute;
  content: "";
  left: -5px;
  top: 1px;
  width: 1px;
  height: 20px;
  background-color: #8080808a;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
}

.search_btn_show {
  display: none;
}

.show_mobile_filter_explore {
  display: none;
}

.send_offer_new_blk .form_blk {
  margin-bottom: 15px;
}

.mini-show-cell {
  display: none;
}

.commercial_header_nav {
  display: none;
}

.styles_faq-row-wrapper__3vA1D .styles_faq-row__2YF3c {
  padding: 5px 0 10px !important;
  border-bottom: none !important;
}

.styles_faq-row-wrapper__3vA1D h2 {
  font-family: "Poppins SemiBold";
  color: #000;
  font-size: 20px !important;
}

.faq-body {
  padding: 0 !important;
}

.styles_faq-row-wrapper__3vA1D .styles_row-body__1NvUo .styles_faq-row__2YF3c {
  position: relative;
  display: block;
  background: #f8f7fc;
  padding: 0px 0;
  margin-bottom: 10px;
  border-radius: 10px;
  transition: all ease 0.5s;
  border: 1px solid #dddddd3d;
}

.styles_faq-row-wrapper__3vA1D
  .styles_row-body__1NvUo
  .styles_faq-row__2YF3c
  .styles_row-title__1YiiY {
  padding: 10px 0 !important;
}

.styles_faq-row-wrapper__3vA1D
  .styles_row-body__1NvUo
  .styles_faq-row__2YF3c
  .styles_row-title__1YiiY
  .styles_row-title-text__1MuhU {
  position: relative;
  padding: 0 40px 0 25px;
  margin: 0;
  cursor: pointer;
  transition: all ease 0.5s;
  z-index: 2;
  font-family: "Poppins SemiBold";
  font-size: 16px !important;
  color: #4c4c4c !important;
}

.styles_faq-row-wrapper__3vA1D
  .styles_row-body__1NvUo
  .styles_faq-row__2YF3c
  .styles_row-title__1YiiY
  .styles_icon-wrapper__2cftw {
  top: 17px !important;
}

.styles_faq-row-wrapper__3vA1D
  .styles_row-body__1NvUo
  .styles_faq-row__2YF3c
  .styles_row-title__1YiiY.styles_expanded__3elPy
  + .styles_row-content__QOGZd {
  height: 100% !important;
}

.styles_faq-row-wrapper__3vA1D
  .styles_row-body__1NvUo
  .styles_faq-row__2YF3c
  .styles_row-content__QOGZd
  .styles_row-content-text__2sgAB {
  padding: 10px 25px 20px !important;
}

.styles_faq-row-wrapper__3vA1D
  .styles_row-body__1NvUo
  .styles_faq-row__2YF3c
  .styles_row-content__QOGZd
  .styles_row-content-text__2sgAB {
  font-size: 14px !important;
  color: #4c4c4c !important;
}

.error_msg {
  font-size: 12px;
  padding: 3px;
  color: red;
  display: none;
}

.log_blk .txtBox:invalid[focused="true"] ~ .error_msg {
  display: block;
}

.contact_page .colL .txtBox:invalid[focused="true"] ~ .error_msg {
  display: block;
}

.cell_show_map {
  display: none !important;
}

#oops {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 2rem 0 4rem;
  overflow: auto;
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

#oops .flex {
  flex-direction: column;
  gap: 20px;
}

#oops .inner {
  max-width: 34rem;
  margin: 0 auto;
}

#oops .logo {
  margin: 0 auto 3rem;
  height: 72px;
}

#oops .icon {
  color: #ff6c40;
  font-size: 12rem;
  font-weight: 700;
  line-height: 0.8;
  margin-bottom: 2.5rem;
}

#loading {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background: #f1f2f3;
  z-index: 9999;
}

#loading.listingsLoading {
  opacity: 0.8;
  height: 400px;
}

#loading.listingsLoading.static {
  position: static;
}

#loading.loadingFixed {
  position: fixed;
}

#loading img {
  width: 100px;
  height: 100px;
  margin: auto;
}

footer form .txtGrp p {
  position: absolute;
  bottom: -28px;
}

p.error {
  color: #f73164;
  font-size: 11px;
}

.txtBox.error {
  border-color: #f73164;
}

input.error,
input[type="radio"].error:after,
input[type="checkbox"].error:after {
  border-color: #f73164;
}

.memberVerify.popup {
  position: static;
  padding: 75px 0;
  background-color: #fff;
}

.memberVerify.popup.emailVerify {
  background-color: transparent;
}

.greenBtn {
  background: #08bc0c;
}

.addressMsg.text-success {
  color: #08bc0c;
}

.gMap {
  width: 100%;
  height: 50vh;
  margin-top: 20px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  overflow: hidden;
}

.addressMsg {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.webBtn.redBtn {
  background: #ff1800;
}

.addressMsg.text-danger {
  color: #ff1800;
}

.imgLoading {
  height: 100px;
}

.imgLoading img {
  height: 100%;
  object-fit: contain;
}

.add_tenants {
  position: relative;
  padding-right: 40px;
}

.add_tenants .closeBtn {
  position: absolute;
  top: 12px;
  right: 12px;
  width: 21px;
  height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  color: #778ca3;
  font-size: 12px;
  cursor: pointer;
  line-height: 1;
  text-align: center;
  border: 1px solid #aaa;
  border-radius: 75%;
  padding-top: 1px;
  padding-left: 1px;
}

.add_tenants .closeBtn:before {
  content: "\2715";
}

.resend_blk {
  text-align: center;
  margin-top: 20px;
}

.resend_blk p {
  font-size: 13px;
  margin-bottom: 5px;
}

.email_verification .inner {
  background: #fff;
  padding: 25px;
  border-radius: 7px;
  -webkit-box-shadow: 0 5px 20px rgb(18 15 56 / 7%);
  box-shadow: 0 5px 20px rgb(18 15 56 / 7%);
}

.email_verification .inner ._header,
.email_verification .inner ._footer {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.email_verification .inner ._header {
  margin-bottom: 20px;
  border-bottom: 1px solid #ff6c40;
}

.appLoad {
  position: relative;
  height: 200px;
  width: 100%;
}

.appLoad .appLoader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  z-index: 3;
}

.appLoad .spiner {
  width: 40px;
  height: 40px;
  border: 4px solid #5aaad7;
  border-right: 4px solid #f0f5f9;
  border-radius: 75%;
  -webkit-animation: spin 0.5s linear infinite;
  animation: spin 0.5s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(-360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}

.loading-wait {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-wait img {
  width: 600px;
  height: 600px;
  object-fit: contain;
  object-position: center;
}

.timer {
  background-color: rgba(255, 255, 255, 0.1);
  display: inline-block;
  /* margin-top: 25px; */
  /* padding: 10px; */
  text-align: center;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.timer .col-4 {
  width: 25%;
  float: left;
  position: relative;
}

.timer .col-4:after {
  content: ":";
  position: absolute;
  top: 10%;
  right: 0px;
  font-size: calc(16px + 2vmin);
}

.timer .col-4:last-child:after {
  display: none;
}

.timer .box {
  border-right: solid 1px rgba(255, 255, 255, 0.2);
  font-weight: 300;
  padding: 10px;
}

.timer .col-4:last-child .box {
  border-right-color: transparent;
}

.timer .box p {
  font-size: calc(16px + 2vmin);
  margin: 0;
}

.timer .text {
  font-size: 14px;
}

.questions_header {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.changePasswordBorder .form_blk {
  margin-bottom: 0;
}

.changePasswordBorder .borderForm {
  border: 2px dashed #f66c41;
  padding: 20px;
  margin-bottom: 15px;
  border-radius: 4px;
  margin-top: 10px;
}

/* ============= email box =================== */

#email_box {
  /* background-color: #eee; */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}

#email_box .inner {
  background-color: #fff;
  padding: 40px;
  box-shadow: 0 0 15px rgb(0 0 0 / 10%);
  max-width: 650px;
  margin: auto;
  text-align: center;
}

#email_box .logo {
  background: #eee;
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px;
  margin: -72px auto 0;
  border-radius: 100%;
  width: 80px;
  height: 80px;
  float: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

#email_box .content {
  margin-top: 40px;
}

#email_box .webBtn {
  margin-bottom: 20px;
  /* background-color: #3aa771; */
}

#email_box .content .strong {
  font-family: "Poppins SemiBold";
}

#email_box .content strong {
  font-family: "Poppins SemiBold";
}

#email_box .content .link {
  color: #3aa771;
  text-decoration: underline;
}

.offer_dash_detail_page .sender_bio .inner .check_bg_ur {
  width: 100%;
}

.loadingDisabled {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 999;
}

.dollar_label {
  position: absolute;
  right: 10px;
  top: 12px;
  color: #9d9d9d;
  padding-left: 10px;
}

.branch_lease_label {
  right: unset;
  left: 0px;
  top: 10px;
}

.branch_lease_txtBox {
  padding-left: 50px;
}

.deposit_form_blk {
  overflow: hidden;
  position: relative;
}

.deposit_form_blk .txtBox {
  padding-left: 12px !important;
}

div.greenBtn.webBtn,
div.redBtn.webBtn {
  cursor: auto;
}

.loading_bar {
  max-width: 201px;
  border-radius: 4px;
  overflow: hidden;
  margin: 0 auto 20px;
}

.file_preview {
  gap: 1rem;
  /* border: 1px solid #eee; */
  border-radius: 12px;
  margin-top: 15px;
  max-width: 17rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
  background-color: rgba(23, 35, 48, 0.04);
}

.file_preview_center {
  margin: 0px auto 0;
}

.file_preview.file_preview_center a {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.file_preview.file_preview_center {
  flex-direction: row;
}

.file_preview .crosBtn {
  top: -10px;
  right: -10px;
  background: #f66c41;
  border: none;
  border-radius: 75%;
  font-size: 12px;
  width: 22px;
  height: 22px;
  color: #fff;
}

.file_preview h5 {
  margin: 0;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.file_preview a {
  color: #008cff;
  font-size: 12px;
  display: flex;
  gap: 0.5rem;
}

.file_preview a i {
  font-size: 17px;
  align-self: center;
}

.file_preview .sm_icon {
  width: 5rem;
  height: 5rem;
  overflow: hidden;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  background: #3690ff;
  padding: 12px;
}

.file_preview.file_preview_center .sm_icon {
  width: 4rem;
  height: 4rem;
  padding: 8px;
}

.file_preview.file_preview_center h6 {
  margin: 0;
}

.file_preview .sm_icon img {
  filter: brightness(0) invert(1);
}

.file_preview ._cnt {
  flex: 1;
  align-self: center;
}

.action_dots.action_plus {
  padding: 8px;
}

.review_heading ul.all_rating li .star-ratings {
  display: block !important;
}

.resetAccount span {
  padding-right: 5px;
}

.lg-show-in .lg-components {
  bottom: 100px;
}

#lg-zoom-in-1 {
  display: none;
}

.action_drop.pDetailsDropDown {
  position: relative;
  top: unset;
  right: unset;
}

.pDetailsDropDown:hover ._dropCnt {
  visibility: visible;
  opacity: 1;
}

.order_flex_change {
  max-width: 80rem;
  margin: auto;
}

.order_flex_change .col,
.view_property_gallery .imgGallery.order_flex_change .col {
  width: 50%;
}

.order_flex_change .col:nth-child(1),
.view_property_gallery .imgGallery.order_flex_change .col:nth-child(1) {
  width: 100%;
}

.order_flex_change.last_full_wide .col:last-child {
  width: 100%;
}

.order_flex_change .col {
  position: relative;
  padding-bottom: 37% !important;
  border: 3px solid #fff;
}

.order_flex_change .col:nth-child(1),
.order_flex_change.last_full_wide .col:last-child {
  padding-bottom: 70% !important;
}

.order_flex_change .col img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.lg-download {
  display: none;
}

.ratings_wrap {
  gap: 30px;
  align-items: center;
}

.ratings_wrap h5 {
  max-width: 250px;
  width: 100%;
}

.ratings_wrap .star-ratings {
  flex: 1;
}

.popup.review_popup h3 {
  margin-bottom: 50px;
}

.like_reply {
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-top: 10px;
}

.like_reply ._col li {
  margin: 0;
  padding: 0 10px;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  align-items: center;
}

.like_reply ._col li a {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.like_reply ._col .flex {
  align-items: center;
}
.like_reply ._col .flex .reply {
  margin-left: 5rem;
}
.like_reply ._col .flex .reply .image_icon {
  width: 40px;
  height: 40px;
}
.like_reply ._col .flex .reply h4 {
  font-size: 13px;
}
.like_reply ._col .webBtn {
  text-decoration: underline;
  background: none;
  box-shadow: none;
  color: #000;
  padding-right: 0;
  font-size: 12px;
}

.reply_link {
  font-size: 12px;
}

.replyWrap {
  width: 100%;
}

.lg-outer .lg-img-wrap {
  padding: 6rem !important;
}

.update_popup .crosBtn,
.extend_popup .crosBtn {
  height: 14px;
}
.update_popup .form_blk > .react-datepicker-wrapper {
  width: 100%;
}
.crud_image .inside .imgLst.mediaImgLst {
  flex-wrap: wrap;
}

.popup .content {
  margin: 20px 0;
}

.popup .icons {
  margin: 15px 0 20px 0;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.content .icons button {
  height: 50px;
  width: 50px;
  font-size: 20px;
  text-decoration: none;
  border: 1px solid transparent !important;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  border-radius: 75%;
}

.icons button i {
  transition: transform 0.3s ease-in-out;
}

.popup .icons button:nth-child(1) {
  color: #1877f2 !important;
  border-color: #b7d4fb !important;
}

.popup .icons button:nth-child(1):hover {
  background: #1877f2 !important;
}

.popup .icons button:nth-child(2) {
  color: #46c1f6 !important;
  border-color: #b6e7fc !important;
}

.popup .icons button:nth-child(2):hover {
  background: #46c1f6 !important;
}

.popup .icons button:nth-child(3) {
  color: #e1306c !important;
  border-color: #f5bccf !important;
}

.popup .icons button:nth-child(3):hover {
  background: #e1306c !important;
}

/* .popup .icons button:nth-child(4) {
  color: #25d366 !important;
  border-color: #bef4d2 !important;
}

.popup .icons button:nth-child(4):hover {
  background: #25d366 !important;
} */
.popup .icons button:nth-child(4) {
  color: #000 !important;
  border-color: #000 !important;
}

.popup .icons button:nth-child(4):hover {
  background: #000 !important;
}

.popup .icons button:nth-child(5) {
  color: #0088cc !important;
  border-color: #b3e6ff !important;
}

.popup .icons button:nth-child(5):hover {
  background: #0088cc !important;
}

.popup .icons button:nth-child(6) {
  color: #c12127 !important;
  border-color: #c121274f !important;
}

.popup .icons button:nth-child(6):hover {
  background: #c12127 !important;
}

.popup .icons button:hover {
  color: #fff !important;
  border-color: transparent !important;
}

.popup .icons button:hover i {
  transform: scale(1.2) !important;
  -webkit-transform: scale(1.2) !important;
  -moz-transform: scale(1.2) !important;
  -ms-transform: scale(1.2) !important;
  -o-transform: scale(1.2) !important;
}

.popup .content .field {
  margin: 12px 0 -5px 0;
  height: 45px;
  border-radius: 4px;
  padding: 0 5px;
  border: 1px solid #e1e1e1;
  position: relative;
}

.popup .field.active {
  border-color: #7d2ae8;
}

.popup .field > i {
  font-size: 18px;
  text-align: center;
  position: absolute;
  left: 10px;
  top: 14px;
  width: auto;
}

.popup .field.active i {
  color: #7d2ae8;
}

.popup .field input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  padding-left: 30px;
}

.popup .field button {
  position: absolute;
  top: 3px;
  right: 3px;
  border-radius: 4px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  background-color: #fff;
  color: #ff6c40;
}

.popup .field button > i {
  margin: 0;
}

.cancelRequest.webBtn {
  min-width: 100%;
  border-radius: 3px;
  margin-top: 10px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}

.small_popup ._inner > .bTn > .alert {
  margin-top: 20px;
  margin-bottom: 0;
}

/* .invoice_bdy>.flex.icon_listing {
  margin-left: 0;
} */
.blockLst.pricesBlock table tr > * {
  text-align: center;
}

.blockLst.pricesBlock table tr > *:nth-child(1) {
  padding-left: 7px;
}

.selectTxtGrp > div > div {
  min-height: 46px;
  border: 1px solid #eee;
}

.dash_property_listing .col .inner .image .show_lbl_overlay.listing_type_label {
  top: 10px;
  text-transform: uppercase;
}

.dash_property_listing
  .col
  .inner
  .image
  .show_lbl_overlay.listing_type_label.for_rent {
  background: #00acfc;
}

.dash_property_listing
  .col
  .inner
  .image
  .show_lbl_overlay.expire_listing_label {
  left: unset;
  right: 10px;
}

/* report popup design  */
.offerReportPopup #content {
  padding-top: 0;
  font-family: "Poppins Regular";
}

.offerReportPopup #content h1,
.offerReportPopup #content h2,
.offerReportPopup #content h3,
.offerReportPopup #content h4,
.offerReportPopup #content h5,
.offerReportPopup #content h6 {
  font-family: "Poppins Regular";
}

.offerReportPopup #content h3 {
  color: #088fff;
  font-size: 20px;
}

.offerReportPopup #content h4 {
  font-size: 16px;
  margin-bottom: 3px;
}

.offerReportPopup #content div {
  font-size: 14px;
}

.offerReportPopup .crosBtn {
  top: 8px;
  right: 5px;
}

/* .offerReportPopup ._inner {
  padding: 0;
  overflow: hidden;
} */
.asurint-report-wrapper header{
  position: relative;
  top: unset;
  margin-top: 2rem;
  box-shadow: none;
}

.offerReportPopup
  .shareable\.credit-body
  .shareable\.credit-data-block
  .shareable\.credit-columns
  span {
  font-size: 18px;
}

.offerReportPopup .shareable\.credit-footer-logo,
.offerReportPopup .shareable\.criminal-footer-logo,
.offerReportPopup .shareable\.eviction-footer-logo {
  width: unset;
}

.offerReportPopup .shareable\.criminal-text-light,
.offerReportPopup .shareable\.eviction-text-light {
  font-size: 17px;
  font-family: "Poppins Regular";
  margin-bottom: 5px;
}

.offerReportPopup
  .shareable\.criminal-body
  .shareable\.criminal-circle-holder
  .shareable\.criminal-circle-lg,
.offerReportPopup
  .shareable\.eviction-body
  .shareable\.eviction-circle-holder.shareable\.eviction-circle-holder-xlg
  .shareable\.eviction-circle-xlg {
  background: #ff6c40;
  color: #fff;
  font-size: 17px !important;
  font-family: "Poppins Regular";
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  line-height: unset;
}

.leaflet-container {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.propert_list.map_flex .col {
  width: 100%;
  padding: 0px 0 0;
}

.propert_list.map_flex .col .inner {
  box-shadow: none;
  border: none;
  overflow: hidden;
}

.leaflet-container a.leaflet-popup-close-button {
  z-index: 999;
}

.propert_list.map_flex .col .inner .image_map {
  width: 100%;
  position: relative;
  padding-bottom: 60%;
  overflow: hidden;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  overflow: hidden;
}

.propert_list.map_flex .col .inner .image_map img {
  position: absolute;
  height: 100%;
  object-fit: cover;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.propert_list.map_flex {
  margin: 0;
}

.leaflet-popup-content-wrapper {
  background: transparent !important;
}

.leaflet-popup-close-button {
  display: none !important;
}

.propert_list.map_flex .col .inner .cntnt {
  padding: 16px 20px 15px;
}

.propert_list.map_flex .col .inner .cntnt ul.flex {
  margin: 5px 0 10px;
}

.propert_list.map_flex .col .inner .price_flex .price {
  align-self: center;
}

.propert_list.map_flex .col .inner .price_flex p {
  margin-top: 0;
}

#map_canvas .leaflet-popup-content {
  max-width: 250px;
  width: 250px !important;
  margin: 0;
}

.exploreSearch {
  width: 100%;
  /* height: 100vh; */
  display: flex;
  align-items: center;
  position: relative;
  padding-bottom: 55%;
}

.exploreSearch img {
  position: absolute;
  height: 100%;
  object-fit: contain;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.marker-cluster-small {
  background-color: rgba(181, 226, 140, 0.6);
}

.marker-cluster-small div {
  background-color: rgba(110, 204, 57, 0.6);
}

.marker-cluster-medium {
  background-color: rgba(241, 211, 87, 0.6);
}

.marker-cluster-medium div {
  background-color: rgba(240, 194, 12, 0.6);
}

.marker-cluster-large {
  background-color: rgba(253, 156, 115, 0.6);
}

.marker-cluster-large div {
  background-color: rgba(241, 128, 23, 0.6);
}

/* IE 6-8 fallback colors */
.leaflet-oldie .marker-cluster-small {
  background-color: rgb(181, 226, 140);
}

.leaflet-oldie .marker-cluster-small div {
  background-color: rgb(110, 204, 57);
}

.leaflet-oldie .marker-cluster-medium {
  background-color: rgb(241, 211, 87);
}

.leaflet-oldie .marker-cluster-medium div {
  background-color: rgb(240, 194, 12);
}

.leaflet-oldie .marker-cluster-large {
  background-color: rgb(253, 156, 115);
}

.leaflet-oldie .marker-cluster-large div {
  background-color: rgb(241, 128, 23);
}

.marker-cluster {
  background-clip: padding-box;
  border-radius: 20px;
}

.marker-cluster div {
  width: 30px;
  height: 30px;
  margin-left: 5px;
  margin-top: 5px;

  text-align: center;
  border-radius: 15px;
  font: 12px "Helvetica Neue", Arial, Helvetica, sans-serif;
}

.marker-cluster span {
  line-height: 30px;
}

.leaflet-cluster-anim .leaflet-marker-icon,
.leaflet-cluster-anim .leaflet-marker-shadow {
  -webkit-transition: -webkit-transform 0.3s ease-out, opacity 0.3s ease-in;
  -moz-transition: -moz-transform 0.3s ease-out, opacity 0.3s ease-in;
  -o-transition: -o-transform 0.3s ease-out, opacity 0.3s ease-in;
  transition: transform 0.3s ease-out, opacity 0.3s ease-in;
}

.leaflet-cluster-spider-leg {
  /* stroke-dashoffset (duration and function) should match with leaflet-marker-icon transform in order to track it exactly */
  -webkit-transition: -webkit-stroke-dashoffset 0.3s ease-out,
    -webkit-stroke-opacity 0.3s ease-in;
  -moz-transition: -moz-stroke-dashoffset 0.3s ease-out,
    -moz-stroke-opacity 0.3s ease-in;
  -o-transition: -o-stroke-dashoffset 0.3s ease-out,
    -o-stroke-opacity 0.3s ease-in;
  transition: stroke-dashoffset 0.3s ease-out, stroke-opacity 0.3s ease-in;
}

.leaflet-marker-icon {
}

.leaflet-marker-icon > div {
}

.leaflet-marker-icon:has(.marker-price) {
  width: auto !important;
  height: auto !important;
  border: none;
  background: none;
}

.leaflet-marker-icon:has(.marker-cluser-parent) {
  width: 40px !important;
  height: 40px !important;
  background-clip: padding-box;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  overfloaw: hidden;
  object-fit: contain;
}

.leaflet-marker-icon:has(.marker-cluser-parent) > div {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
}

.map-sidebar {
  box-sizing: border-box;
  padding: 2em;
  width: 30vw;
}

.leaflet-popup-content .color_lbl p {
  margin: 0 !important;
}

.sale_form_offer fieldset label {
  font-size: 13px;
  opacity: 0.8;
  font-family: "Poppins Regular";
}

.sale_form_offer fieldset h6 {
  opacity: 0.8;
  font-size: 13px;
}

.apply_form .upload_blk {
  display: flex;
  justify-content: center;
  -webkit-box-orient: vertical;
  background: rgb(53 143 255);
  /* border: 1px dashed rgba(6, 18, 55, .2); */
  box-shadow: 0 5px 15px rgba(15, 17, 55, 0.02);
  border-radius: 4px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  height: 4.7rem;
  gap: 1rem;
  cursor: pointer;
  transition: all 0.5s ease;
  width: 4.7rem;
  margin: 2rem auto;
  box-shadow: rgb(81 24 24 / 20%) 0 5px 10px 0;
}

.apply_form .upload_blk img {
  width: 3.5rem;
  height: 3.5rem;
  align-self: center;
  /* transform: rotate(180deg); */
  filter: brightness(0) invert(1);
  -webkit-filter: brightness(0) invert(1);
}

.apply_form .upload_blk span {
  color: #061237;
  align-self: center;
}

.sale_upload_file {
  background: rgba(6, 18, 55, 0.02);
  border: 1px dashed rgb(53 143 255 / 60%);
  padding: 1rem;
  /* overflow: hidden; */
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  width: -moz-fit-content;
  width: 100%;
  display: flex;
  column-gap: 1rem;
  margin-top: 2rem;
  max-width: 100%;
  align-items: center;
}

.sale_upload_file.file_preview > a,
.sale_offer_actions > a {
  width: 3rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  color: #fff;
  /*max-width: 7rem;
  */
  /*min-width: 8rem;
  */
  padding: 0 10px;
  height: 3rem;
  background: #358fff;
  border-radius: 4px;
  box-shadow: rgb(81 24 24 / 20%) 0 5px 10px 0;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}
.sale_offer_actions {
  gap: 15px;
}
.sale_upload_file.file_preview > a.danger,
.sale_offer_actions > a.danger {
  background-color: #d43f3a;
}

.sale_upload_file i {
  align-self: center;
}

.sale_upload_file span {
  align-self: center;
  font-size: 13px;
  opacity: 0.8;
}

.sale_upload_file .crosBtn {
  top: -8px;
  right: -8px;
  font-size: 11px;
  background-color: #ff6c40;
  color: #fff;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  overflow: hidden;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sale_upload_file.file_preview ._cnt {
  gap: 6px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start;
  align-self: center;
  text-align: left;
}

.outer_list .lst ul .icoBlk .txt h5 {
  display: flex;
  align-items: center;
  gap: 10px;
}

.outer_list .lst ul .icoBlk .txt h5 .show_lbl_overlay {
  background-color: #ff6c40;
  border-radius: 50px;
  padding: 3px 10px;
  font-size: 12px;
  color: #fff;
  text-transform: uppercase;
}

.outer_list .lst ul .icoBlk .txt .color_lbl {
  position: unset;
  line-height: 1;
}

.sale_list_design.offer_cstm_ul {
  display: block;
  margin-top: 15px;
}

.offer_dash_detail_page
  .sale_offer_flex
  .colR
  .sale_colL
  .offer_cstm_ul.sale_list_design
  li {
  width: calc(100% / 2);
  margin: 4px 0px;
  font-family: "Poppins Medium";
  position: relative;
  padding: 0;
  padding-left: 25px;
}

.offer_dash_detail_page
  .sale_offer_flex
  .colR
  .sale_colL
  .offer_cstm_ul.sale_list_design
  li:before {
  position: absolute;
  content: "\e92f";
  top: -5px;
  left: 0px;
  font-family: font-icon;
  font-size: 18px;
  color: #ff6c40;
}

.offer_dash_detail_page
  .sale_offer_flex
  .colR
  .sale_colL
  .offer_cstm_ul.sale_list_design
  li:first-child {
  padding-left: 0;
  width: 100%;
}

.offer_dash_detail_page
  .sale_offer_flex
  .colR
  .sale_colL
  .offer_cstm_ul.sale_list_design
  li:first-child:before {
  display: none;
}

.offer_dash_detail_page
  .sale_offer_flex
  .colR
  .sale_colL
  .offer_cstm_ul.sale_list_design
  li:first-child
  h6 {
  font-family: "Poppins Medium";
}

.sale_offer_btn {
  margin-top: 15px;
  justify-content: center;
}

.sale_offer_note {
  height: 20rem;
  overflow: hidden;
  position: relative;
}

.sale_offer_note:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(360deg, #fff, transparent);
  content: "";
}

.sale_offer_note:after {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  content: "\f107";
  font-family: "FontAwesome";
  margin: 0 auto;
  width: 2rem;
  font-size: 2.5rem;
  cursor: pointer;
}

.sale_offer_note.active {
  height: auto;
}

.sale_offer_note.active:before {
  display: none;
}

.sale_offer_note.active:after {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
}

.flterFlexListing {
  margin-bottom: 15px;
}

.flterFlexListing .nav-tabs {
  border-bottom: none;
}

.total_count_price_listing.rent_listing_package {
  flex-wrap: nowrap;
  gap: 10px;
  justify-content: center;
}

.total_count_price_listing.rent_listing_package.justify-left {
  justify-content: unset;
}

.total_count_price_listing.rent_listing_package li {
  max-width: 80px;
  /* justify-content: center; */
}

.cover_letter_details {
  position: relative;
}

.cover_letter_details a {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.search_list {
  min-width: 100%;
  top: 110%;
  border-radius: 0;
  padding: 10px 20px;
  z-index: 10;
  position: relative;
}

.banner-form form .flex.search_flex {
  overflow: unset;
}

.search_list li {
  border-bottom: 1px solid #ddd;
  padding: 5px 0;
  display: block;
}

.search_list li:last-child {
  border: none;
}

.search_list li a {
  padding-left: 0;
  font-size: 13px;
}

.search_list h5 {
  color: #ff6c40;
  margin: 0;
}

.search_list h5:last-child {
  margin-top: 20px;
}

.clear_boundary {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 9999;
  display: flex;
  flex-direction: column;
}

.clear_boundary .webBtn {
  background: #fff;
}

.custom-zoom-buttons {
  position: absolute;
  bottom: 20px;
  right: 20px;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.custom-zoom-buttons > button {
  width: 30px;
  height: 30px;
  background: #fff;
  outline: none;
  border: 1px solid #ddd;
  font-size: 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.marker-price {
  width: fit-content !important;
  padding: 0 5px;
  height: 20px !important;

  word-wrap: break-word;
  white-space: pre;
  display: flex !important;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 25px;
  font-size: 10px;
  /* box-shadow: rgb(81 24 24 / 20%) 0 5px 10px 0 */
}

.marker-price.rent {
  background: #3cacfc;
}

.marker-price.sale {
  background: #ff6c40;
}

.marker-price.commercial {
  background: #fff;
  color: #ff6c40;
}

.marker-hovered .marker-price,
.marker-price:hover {
  background: red;
}

.marker-price.commercial:hover {
  color: #fff;
}

.bedsFlex {
  border: 1px solid #ddd;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  /* justify-content: space-between; */
  flex-flow: nowrap;
  overflow: hidden;
}

.bedsFlex li {
  flex: 1;
  min-width: auto;
}

.bedsFlex li button {
  background: transparent;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border: none;
  text-align: center;
  width: 100%;
  padding: 6px 0;
  font-size: 14px;
}

.bedsFlex li.active button {
  background-color: #ff6c41;
  color: #fff;
}

.bedsFlex li:not(:first-child) {
  border-left: 1px solid #ddd;
}

.bedsPopup._dropCnt {
  max-width: 480px;
  min-width: 480px;
}

.rangeIcon {
  position: absolute;
  top: 11px;
  left: 12px;
  color: #222;
  opacity: 0.9;
}

.txtWithLabel {
  padding-left: 25px;
}

.search_result_page .colL.dataNotFound .propert_list {
  align-items: center;
  height: 100%;
}

.search_result_page .colL.dataNotFound .propert_list .notText {
  display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    max-width: 50rem;
    margin: auto;
    gap: 3rem;
}
.search_result_page .colL.dataNotFound .propert_list .notText .icon{
  width: 10rem;
  height: 10rem;
}

.search_result_page .colL.dataNotFound .propert_list .notText img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.search_result_page .colL.dataNotFound .propert_list .notText h4 {
  margin-top: 15px;
}

.box {
  width: 100%;
}

.box li {
  width: 100%;
  height: 300px;
  border: 1px solid #ddd;
  display: block;
}

.loadMoreBtn {
  margin: 25px 0;
}

.main-item {
  padding: 10px;
  background-color: #fff;
  width: 100%;
}

.background-masker {
  background-color: #fff;
  position: absolute;
}

.btn-divide-left {
  top: 0;
  left: 25%;
  height: 100%;
  width: 5%;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -800px 0;
  }

  100% {
    background-position: 800px 0;
  }
}

.animated-background {
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background-color: #f6f7f8;
  background: linear-gradient(to right, #eee 8%, #bbb 18%, #eee 33%);
  background-size: 800px 104px;
  height: 70px;
  position: relative;
}

.static-background {
  background-color: #f6f7f8;
  background-size: 800px 104px;
  height: 70px;
  position: relative;
  margin-bottom: 20px;
}

.shared-dom {
  width: 800px;
  height: 110px;
}

.sub-rect {
  border-radius: 100%;
  width: 70px;
  height: 70px;
  float: left;
  margin: 20px 20px 20px 0;
}

.pure-background {
  background-color: #eee;
}

.search_result_page .colL .propert_list.grid_view .col.css-dom:empty {
  width: 49% !important;
  height: 220px;
  border-radius: 6px;
  box-shadow: 0 10px 45px rgba(0, 0, 0, 0.2);
  background-repeat: no-repeat;
  background-image: radial-gradient(circle 16px, lightgray 99%, transparent 0),
    linear-gradient(lightgray, lightgray), linear-gradient(lightgray, lightgray),
    linear-gradient(lightgray, lightgray), linear-gradient(lightgray, lightgray),
    linear-gradient(#fff, #fff);
  background-size: 32px 32px, 200px 32px, 180px 32px, 230px 16px, 100% 40px,
    280px 100%;
  background-position: 24px 30px, 66px 30px, 24px 90px, 24px 142px, 0 180px, 0 0;
}

.search_result_page .colL .flex.emptyFlex {
  gap: 10px;
  margin: 20px 0;
}

.main-loading {
  margin: 0;
  padding: 0;
  width: 100%;
  /* animation: pulse 5s infinite;
  -webkit-animation: pulse 5s infinite; */
}

.loading-container {
  position: relative;
  width: 100%;
  margin: 20px auto;
  padding: 20px 40px;
  border-radius: 4px;
  box-sizing: border-box;
  background: #fff;
  /* box-shadow: 0 10px 20px rgba(0, 0, 0, .5); */
}

.loading-div {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 10px;
  background: #f1f1f1;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  overflow: hidden;
}

.loading-div:after {
  content: "";
  position: absolute;
  left: 0;
  width: 0;
  height: 100%;
  border-radius: 4px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  animation: load 5s infinite;
}

@keyframes load {
  0% {
    width: 0;
    background: #a28089;
  }

  25% {
    width: 40%;
    background: #a0d2eb;
  }

  50% {
    width: 60%;
    background: #00acfc;
  }

  75% {
    width: 75%;
    background: #ff6c40;
  }

  100% {
    width: 100%;
    background: #494d5f;
  }
}

@keyframes pulse {
  0% {
    background: #a28089;
  }

  25% {
    background: #a0d2eb;
  }

  50% {
    background: #00acfc;
  }

  75% {
    background: #ff6c40;
  }

  100% {
    background: #494d5f;
  }
}

#map_canvas .leaflet-popup-content-wrapper {
  box-shadow: none;
}

.propert_list.map_flex .col .inner .image {
  width: 100%;
  height: 100%;
  position: relative;
  padding-bottom: 70%;
  overflow: hidden;
}

.propert_list.map_flex .col .inner .image img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

@keyframes rotate-loading {
  0% {
    transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
  }
}

@-moz-keyframes rotate-loading {
  0% {
    transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes rotate-loading {
  0% {
    transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
  }
}

@-o-keyframes rotate-loading {
  0% {
    transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
  }
}

@keyframes rotate-loading {
  0% {
    transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
  }
}

@-moz-keyframes rotate-loading {
  0% {
    transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes rotate-loading {
  0% {
    transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
  }
}

@-o-keyframes rotate-loading {
  0% {
    transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
  }
}

@keyframes loading-text-opacity {
  0% {
    opacity: 0;
  }

  20% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@-moz-keyframes loading-text-opacity {
  0% {
    opacity: 0;
  }

  20% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@-webkit-keyframes loading-text-opacity {
  0% {
    opacity: 0;
  }

  20% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@-o-keyframes loading-text-opacity {
  0% {
    opacity: 0;
  }

  20% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.loading-container,
.loading {
  height: 100px;
  position: relative;
  width: 100px;
  border-radius: 100%;
}

.main-loading .loading,
.main-loading #loading-text {
  width: 60px;
  height: 60px;
}

.main-loading #loading-text {
  margin-top: 20px;
}

.loading-container {
  margin: 40px auto;
}

.loading {
  border: 2px solid transparent;
  border-color: transparent #3cacfc transparent #3cacfc;
  -moz-animation: rotate-loading 1.5s linear 0s infinite normal;
  -moz-transform-origin: 50% 50%;
  -o-animation: rotate-loading 1.5s linear 0s infinite normal;
  -o-transform-origin: 50% 50%;
  -webkit-animation: rotate-loading 1.5s linear 0s infinite normal;
  -webkit-transform-origin: 50% 50%;
  animation: rotate-loading 1.5s linear 0s infinite normal;
  transform-origin: 50% 50%;
}

.loading-container:hover .loading {
  border-color: transparent #e45635 transparent #e45635;
}

.loading-container:hover .loading,
.loading-container .loading {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

#loading-text {
  -moz-animation: loading-text-opacity 2s linear 0s infinite normal;
  -o-animation: loading-text-opacity 2s linear 0s infinite normal;
  -webkit-animation: loading-text-opacity 2s linear 0s infinite normal;
  animation: loading-text-opacity 2s linear 0s infinite normal;
  color: #3cacfc;
  font-family: "Helvetica Neue, " Helvetica ", " "arial";
  font-size: 10px;
  font-weight: bold;
  margin-top: 15px;
  opacity: 0;
  position: absolute;
  text-align: center;
  text-transform: uppercase;
  top: 0;
  width: 100px;
  height: 100px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

#leadingMainContainer {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  justify-self: center;
  align-items: center;
}

.leaflet-left.leaflet-top {
  /* display: none; */
}

.leaflet-bottom.leaflet-right {
  bottom: 0px;
}

.explore-range .rc-slider-step {
  background: #3cacfc;
}

.explore-range .rc-slider-handle {
  border-color: #3cacfc;
}

.range-slider .range-labels {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.range-slider .range-labels .range-input {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 15px;
}

.range-slider .range-labels .range-input .txtBox {
  padding-left: 25px;
}

.range-slider .rc-slider-track {
  background-color: #3cacfc;
}

.range-slider .rc-slider-handle:hover,
.range-slider .rc-slider-handle {
  border-color: #3cacfc;
}

.owl-nav button:hover {
  background: #fff !important;
}

.expired_label {
  background-color: #ff1800;
  border-radius: 50px;
  padding: 3px 10px;
  font-size: 12px;
  color: #fff;
}

.commercial_listing_flex {
  justify-content: space-between;
}

.commercial_listing_flex .pagination {
  margin: 0;
}

.commercial_listing_flex .pagination > li > a {
  background: transparent;
  border: none;
  color: #2d3748;
  min-width: 40px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  border-radius: 0.375rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.commercial_listing_flex .pagination > li > a.active {
  background-color: rgba(237, 242, 247, 1);
  color: rgba(74, 85, 104, 1);
  border: none;
  border-radius: 0.375rem;
}

.no_chat {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loading_attachment {
  max-width: 200px;
}

.loading_attachment .progress {
  height: 10px;
}

.attachment_document {
  gap: 10px;
  align-items: center;
  padding: 5px;
}

.attachment_document .icon {
  width: 32px;
  height: 32px;
  padding: 6px;
  border-radius: 75%;
  background-color: #fff;
}

.attachment_document .icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.attachment_files {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.attachment_files li {
  background: #e4e6eb;
  /* padding: 10px; */
  border-radius: 10px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
}

.attachment_files li .crosBtn {
  width: 20px;
  height: 20px;
  top: -10px;
  right: -5px;
  background: #fff;
  border-radius: 75%;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
}

.attachment_files li .crosBtn:before {
  font-size: 14px;
  color: #65676b;
}

.attachment_files li .image {
  width: 48px;
  height: 48px;
  border-radius: 10px;
  overflow: hidden;
}

.attachment_files li .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

[inbox] .buble .cntnt .attachment_files li {
  width: calc(90% / 3);
  background: rgba(255, 255, 255, 0.6);
  margin-bottom: 15px;
}

[inbox] .buble .cntnt .attachment_files li .attachment_document {
  justify-content: center;
  text-align: center;
  color: #000;
}

[inbox] .buble .cntnt .attachment_files li .image {
  width: 100%;
  height: 100%;
}

.attachment_files li .download_btn {
  background-color: #fff;
  display: block;
  height: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 80px;
  margin: 5px auto 0;
  border-radius: 4px;
}

.images_contain {
  width: 100%;
  height: 100%;
}

#msg_unread {
  right: -3px;
  top: -2px;
}

.msg_contain_toaster .crosBtn {
  right: -3px;
  top: 2px;
}

.leases_dashboard {
  /* z-index: 99; */
}

.leaseLoading#leadingMainContainer {
  height: 200px;
  width: 200px;
  margin: 0 auto;
}

/*=======Toggle lease button==========*/
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.add_tenants_lease.add_tenants {
  padding-right: 0;
  margin-bottom: 10px;
}

.add_tenants_lease.add_tenants .closeBtn {
  z-index: 99;
}

.lease_property_details.dash_property_listing {
  margin: 0 !important;
}

.lease_property_details.dash_property_listing .col {
  padding: 0;
}

.main_dashboard.height-100vh {
  height: 100vh;
}

.block_new_lease_new ul {
  background-color: #fff;
}

.enterRsBlk {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-size: 3.2rem;
  font-family: "Poppins Bold";
  padding: 0.6rem 2rem;
  border: 1px solid #eee;
  border-radius: 0.6rem;
}

.enterRsBlk strong {
  font-size: 2.2rem;
}

.enterRsBlk * {
  line-height: 1;
  white-space: nowrap;
}

.enterRsBlk input {
  padding: 0;
  margin-left: 1.5rem;
  text-align: right;
  border: 0;
  font-size: 3.2rem;
}

.enterRsBlk * {
  line-height: 1;
  white-space: nowrap;
}

.creditCard .headCredit .inner.lease_payment_credit_flex {
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.creditCard
  .headCredit
  .inner.lease_payment_credit_flex
  .add_new_method
  .webBtn {
  width: 25px;
  height: 25px;
  padding: 0;
  padding-left: 2px;
  padding-top: 1px;
}

.chk .flex-2 .inner.lease_payment_credit_flex .lblBtn {
  align-items: center;
}

.addNewPaymentMethodBtn {
  width: 100%;
  margin: 10px 0;
}

.chk .popup .cardSec {
  border: 1px solid #f1f1f1;
}

/* ====================new css================= */
.open_house_slider {
  position: relative;
}

.open_house_slider .slick-prev:before,
.open_house_slider .slick-next:before {
  font-size: 30px;
  color: #201f1f;
}

.open_house_slider .slick-slider .slick-prev {
  left: -4rem;
}

.open_house_slider .slick-slider .slick-next {
  right: -2rem;
}

.open_house_slider .slick-slider .slick-disabled {
  display: none !important;
}

.open_house_slider .item .flex .open_day {
  width: 140px;
  padding-right: 1rem;
}

.open_house_slider .item .flex .open_day .inner {
  text-align: center;
  background-color: #ffede8;
  border: 0.5px solid #dfdfdf;
  padding: 1rem;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  color: #000;
}

.open_house_slider .item .flex .open_day .inner em {
  display: block;
  font-family: "Poppins Bold";
  font-size: 20px;
}

.open_house_slider .item .flex .open_day .inner span {
  text-transform: uppercase;
}

.open_house_slider .item .flex .open_time {
  flex: 1;
  padding-left: 1rem;
  padding-right: 2rem;
  display: flex;
  justify-content: space-between;
  align-self: center;
  position: relative;
}

.open_house_slider .item .flex .open_time:after {
  position: absolute;
  content: "";
  top: 50%;
  left: 2rem;
  right: 3rem;
  height: 1px;
  background-color: #d8d8d8;
  z-index: -1;
}

.open_house_slider .item .flex .open_time .mid_to {
  background-color: #fff;
  padding: 0 1rem;
  align-self: center;
}

.open_house_slider .item .flex .open_time .open_time_outer .open_time_inner {
  background-color: #fff;
  border: 1px solid #d8d8d8;
  padding: 1rem 2rem;
  text-align: center;
  text-transform: uppercase;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

/* =====around_home_school===== */
.view_property_flex .around_home_school .colL,
.around_home_school .colL {
  width: 60%;
  padding-right: 4rem;
}

.view_property_flex .around_home_school .colR,
.around_home_school .colR {
  width: 40%;
  padding-left: 2rem;
}

.view_property_flex .around_home_school .detail_heading,
.around_home_school .detail_heading {
  margin-bottom: 0.5rem;
}

/* .around_home_school p.mini_text{
  font-size: 1.3rem;
} */
.view_property_flex .around_home_school .equal_top,
.around_home_school .equal_top {
  margin-top: 2rem;
}

.outer_around_home ul li {
  display: flex;
}

.outer_around_home ul li:not(:last-child) {
  margin-bottom: 3rem;
}

.outer_around_home ul li .left_li {
  flex: 0.5;
}

.outer_around_home ul li .left_li .img_icon {
  width: 4rem;
  height: 4rem;
  align-self: center;
}

.outer_around_home ul li .left_li .score {
  flex: 1;
  margin-left: 1rem;
}

.outer_around_home ul li .left_li .score p.number_score {
  margin-bottom: 0;
}

.outer_around_home ul li .right_li {
  align-self: center;
  flex: 1;
}

.outer_great_school ul li {
  display: flex;
}

.outer_great_school ul li h4 {
  margin-bottom: 0;
  align-self: center;
}

.outer_great_school ul li:not(:last-child) {
  margin-bottom: 3rem;
}

.outer_great_school ul li .ps_number {
  flex: 1;
  margin-left: 2rem;
}

.outer_great_school ul li .ps_number h5 {
  margin-bottom: 0.3rem;
}

.outer_great_school ul li .ps_number p {
  font-size: 1.3rem;
  opacity: 0.7;
}

.payment_estimate .top_head {
  display: flex;
  width: 40%;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}

.payment_estimate .top_head h5 {
  margin-bottom: 0;
  font-family: "Poppins SemiBold";
  align-self: center;
}

.payment_estimate .top_head button {
  padding: 0.4rem 2rem;
  background-color: #515151;
  color: #ff6c40;
  font-size: 1.3rem;
  border: none;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}

.payment_estimate .payment_estimate_flex .colL {
  width: 40%;
}

.payment_estimate .payment_estimate_flex .colR {
  width: 60%;
  padding-left: 5rem;
  padding-top: 1rem;
}

.payment_estimate .payment_estimate_flex .colL .color_progress {
  overflow: hidden;
  width: 100%;
  display: flex;
  border-radius: 100px;
}

.payment_estimate .payment_estimate_flex .colL .color_progress span {
  height: 1rem;
}

.payment_estimate
  .payment_estimate_flex
  .colL
  .color_progress
  span:first-child {
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
}

.payment_estimate .payment_estimate_flex .colL .color_progress span:last-child {
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
}

.payment_estimate .payment_estimate_flex .colL .color_progress span {
  width: 20%;
}

.payment_estimate .light_blue {
  background-color: #aae1e6;
}

.payment_estimate .dark_blue {
  background-color: #4199eb;
}

.payment_estimate .orange {
  background-color: #ff8d7d;
}

.payment_estimate .pink {
  background-color: violet;
}

.payment_estimate .colL ul {
  margin-top: 1.5rem;
}

.payment_estimate .colL ul li {
  display: flex;
  justify-content: space-between;
}

.payment_estimate .colL ul li:not(:last-child) {
  margin-bottom: 0.5rem;
}

.payment_estimate .colL ul li .left_left {
  padding-right: 1rem;
  display: flex;
}

.payment_estimate .colL ul li .left_left p {
  flex: 1;
}

.payment_estimate .colL ul li .left_left span {
  margin-right: 1rem;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  overflow: hidden;
  align-self: center;
}

.payment_estimate .colR .type_payment_lbl .inner_type_payment_lbl {
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  border: 1px solid #dfdfdf;
  padding: 1rem 2rem;
  cursor: pointer;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.payment_estimate
  .colR
  .type_payment_lbl
  .inner_type_payment_lbl:not(:last-child) {
  margin-bottom: 1.2rem;
}

.payment_estimate .colR .type_payment_lbl .inner_type_payment_lbl:hover,
.payment_estimate .colR .type_payment_lbl .inner_type_payment_lbl.active {
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  border-color: #ff8d7d;
  box-shadow: 2px 3px 11px 0px #ff8d7d73;
}

.payment_estimate .colR .type_payment_lbl .inner_type_payment_lbl p {
  align-self: center;
  margin-bottom: 0;
}

.payment_estimate .colR .type_payment_lbl .inner_type_payment_lbl h5 {
  margin-bottom: 0;
  font-size: 1.6rem;
  align-self: center;
  line-height: 0;
}

.search_expense_com .tbl_new_style .balance_lst {
  display: flex;
  margin-bottom: 1rem;
}

.search_expense_com .tbl_new_style .balance_lst:first-child {
  border-bottom: 1px solid #e9e9e9;
}

.search_expense_com .tbl_new_style .balance_lst > div {
  flex: 1;
  display: flex;
  font-size: 1.4rem;
}

.search_expense_com .tbl_new_style .balance_lst > div:last-child {
  flex: 0.5;
  justify-content: flex-end;
}

.search_expense_com .tbl_new_style .balance_lst > div img {
  width: 1.6rem;
  height: 2rem;
  align-self: center;
  margin-right: 0.3rem;
}

.search_expense_com .tbl_new_style .balance_lst > div h5 {
  font-size: 1.6rem;
}

.search_expense_com.logs_section .tbl_new_style .balance_lst h5 {
  margin-bottom: 15px;
}

.cycle_datepicker > div {
  width: 100%;
}

.billing_cost_flex {
  margin-top: 20px;
}

.billing_cost_flex ul {
  display: flex;
  width: 100%;
  /* margin: -1rem; */
  flex-wrap: wrap;
}

.billing_cost_flex ul li {
  width: calc(100% / 2);
}

.billing_cost_flex ul li > div {
  padding: 1rem;
  background: #fefefe;
  margin-bottom: 8px;
  border: 1px solid #eee;
  width: 100%;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
  display: flex;
  justify-content: space-between;
}

.billing_cost_flex ul li:nth-child(even) {
  padding-left: 1rem;
}

.billing_cost_flex ul li:nth-child(odd) {
  padding-right: 1rem;
}

.branches_flex {
  gap: 10px;
}

.promotion_listing_btn {
  width: 35px;
  height: 35px;
  display: flex;
  position: relative;
}

.promotion_listing_btn:before {
  position: absolute;
  top: 0;
  right: 35px;
  bottom: 0;
  width: 200px;
  padding: 10px 15px 10px;
  content: attr(data-description);
  background: #fff;
  white-space: break-spaces;
  height: max-content;
  box-shadow: 0px 2px 8px 3px #f7f5f5;
  color: #000;
  font-size: 11px;
  font-family: "Poppins Regular";
  text-align: left;
  border-radius: 4px;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: all ease 0.5s;
}

.promotion_listing_btn:hover:before {
  opacity: 1;
  right: 46px;
  visibility: visible;
  pointer-events: unset;
  transition: all ease 0.5s;
  z-index: 9;
}

.payment_area {
  padding: 0;
  position: absolute;
  top: 4rem;
  width: 100%;
  z-index: 99;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
}

.payment_area.active {
  top: 5rem;
  visibility: visible;
  opacity: 1;
  pointer-events: all;
}

.block_1 {
  margin-bottom: 1.2rem;
}

.payment_content .flex {
  gap: 1rem;
}

.payment_content .flex label {
  flex: 0.5;
  align-self: center;
}

.payment_area .inner {
  box-shadow: rgb(81 24 24 / 20%) 0 5px 10px 0;
  padding: 23px;
  border-radius: 10px;
  background-color: #fff;
}

.inner_type_payment_lbl.active .payment_area {
  top: 6rem;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.payment_area .input_1 {
  flex: 1;
  padding-right: 20px;
}

.payment_area .input_1 span,
.payment_area .input_3 span,
.payment_area .input_2 span {
  position: absolute;
  left: 10px;
  top: 12px;
  color: #9d9d9d;
}

.payment_area .lblBtn label {
  font-family: "Poppins Regular";
  font-size: 14px;
  color: #4c4c4c;
}

.payment_area .input_2 {
  flex: 1;
}

.payment_area .input_3 {
  width: 100%;
}

.payment_area .input_num {
  border-bottom: 1px solid #ddd;
  padding-left: 4rem;
}

.payment_area .listings {
  padding-top: 15px;
}

.payment_area .listings li p {
  margin-bottom: 0;
}

.payment_area .listings li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0;
}

.payment_area .listings li:last-child {
  padding-bottom: 0;
}

.payment_area .inner label {
  font-size: 14px;
  font-family: "Poppins Medium";
  color: #3f3e3e;
}

.payment_area .inner .bar {
  padding-bottom: 28px;
  padding-top: 1rem;
}

.payment_area .inner .form-range {
  position: relative;
  --track-height: 8px;
  --track-color: #e9e9e9;
  --thumb-size: 16px;
  --thumb-color: #ff6c40;
  --thumb-value: 0;
  -webkit-appearance: none;
  appearance: none;
  height: var(--track-height);
  width: 100%;
  background-color: var(--track-color);
  border-radius: calc(var(--track-height) / 2);
}

.payment_area .radios {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 2rem;
}

.form-range::-webkit-slider-thumb {
  width: var(--thumb-size);
  height: var(--thumb-size);
  background-color: var(--thumb-color);
  border: none;
  border-radius: 50%;
  -webkit-appearance: none;
  appearance: none;
}

.form-range::-moz-range-thumb {
  width: var(--thumb-size);
  height: var(--thumb-size);
  background-color: var(--thumb-color);
  border: none;
  border-radius: 50%;
}

.form-range::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: calc((100% * var(--thumb-value)) - var(--thumb-size) / 2);
  /* Adjust width based on thumb value */
  background-color: #ff6c40;
  /* Change track color when thumb moves forward */
  border-radius: calc(var(--track-height) / 2);
}

.payment_area .payment_head {
  padding-top: 20px;
}

.payment_area .radios .flex {
  align-items: center;
  padding: 6px 0;
}

.payment_area .radios label {
  margin-bottom: 0;
  margin-left: 10px;
  font-family: "Poppins Regular";
  font-size: 15px;
}

.payment_area .radios .cta a {
  color: #00acfc;
  font-family: "Poppins Medium";
  padding-top: 7px;
}

.contact_operation_flex {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.search_expense_com .contact_operation_flex h5 {
  margin-bottom: 0;
}

.promotion_price {
  display: flex;
}

.promotion_price img {
  width: 2.5rem;
  height: 2.5rem;
  margin-left: 0.3rem;
}

button.lst_download_icon {
  padding: 0.3rem;
  border-radius: 5px;
  width: 3rem;
  height: 3rem;
  background: #def3ff;
  border: 1px solid #b7e2f8;
  transition: all ease 0.5s;
  -webkit-transition: all ease 0.5s;
  -moz-transition: all ease 0.5s;
  -ms-transition: all ease 0.5s;
  -o-transition: all ease 0.5s;
}

button.lst_download_icon:hover {
  opacity: 0.5;
  transition: all ease 0.5s;
  -webkit-transition: all ease 0.5s;
  -moz-transition: all ease 0.5s;
  -ms-transition: all ease 0.5s;
  -o-transition: all ease 0.5s;
}

button.lst_download_icon img {
  filter: brightness(0);
  -webkit-filter: brightness(0);
}

.assigned_staff_z_index {
  z-index: 999;
  position: relative;
}

.permission_wrap {
  padding: 10px;
}

.permission_blk {
  border: 1px solid #dddddd80;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  overflow: hidden;
  background: #fff;
  position: relative;
  /* box-shadow: rgb(81 24 24 / 20%) 0 5px 10px 0; */
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  display: flex;
  flex-wrap: wrap;
  padding: 15px 20px 15px;
  align-items: center;
}

.permission_blk span {
  flex: 1;
}

.permission_blk .icon {
  width: 20px;
  height: 20px;
}
/* .position-static {
  position: static !important;
}
.m-auto{
  margin:0 auto
} */
.maint_persons_outer .start_chat {
  margin-top: 5px;
}

@keyframes ldio-inner {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.ldio-inner div {
  position: absolute;
  animation: ldio-inner 1s linear infinite;
  width: 40px;
  height: 40px;
  top: 20px;
  left: 20px;
  border-radius: 50%;
  box-shadow: 0 4px 0 0 #ff6c40;
  transform-origin: 20px 22px;
}
.loadingio-spinner {
  width: 80px;
  height: 80px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.ldio-inner {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-inner div {
  box-sizing: content-box;
}
.staff_loading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 1);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mainPayFlex {
  justify-content: space-between;
  margin-bottom: 15px;
}
.mainPayFlex .webBtn {
  height: 30px;
  padding: 0 15px;
  min-width: 80px;
}
.cost_materials_flex > * {
  width: 100%;
}
.cost_materials_flex ul {
  max-width: 100%;
  gap: 10px;
  width: 100%;
}
.cost_materials_flex ul li {
  width: calc(98% / 2);
  padding: 10px;
  margin: 0;
}

.new_wide_blk {
  max-width: 70rem;
  margin: 0 auto;
}
.new_wide_blk .new_listing_list {
  border-radius: 7px;
  background: #fff;
  overflow: hidden;
  padding: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.new_wide_blk .new_listing_list li {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px dashed #bfe2ff;
  font-size: 14px;
}
.new_wide_blk .new_listing_list li:last-child {
  border-bottom: none;
}
.new_wide_blk .new_listing_list li:last-child strong {
  font-family: "Poppins Medium";
  font-size: 2rem;
}
.new_wide_blk .new_listing_list li:last-child span {
  align-self: center;
}
.new_field_new .ach_acc_wrap > div {
  background: #fefefe;
  text-align: left;
  padding: 4px 12px;
  border: 1px solid #eee !important;
  border-radius: 6px;
}
.property_new_lst_new li .inner.pending {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faebcc;
}
.bankalertFlex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  border-color: #ff6c40;
  color: #000;
  border-radius: 10px;
}
.gap-10 {
  gap: 10px;
}
.justify-center {
  justify-content: center;
}
.justify-flex-end {
  justify-content: flex-end;
}
.recommendation_slider {
  display: block !important;
  flex-wrap: unset !important;
  width: 100% !important;
  overflow: visible !important;
  margin: 0 !important;
}
.recommendation_slider .col {
  width: auto !important;
}
.recommendation_slider.new_recommended_slider .col{
  width: calc(100% / 4)!important;
}
.recommendation_slider .col .inner {
  width: 100% !important;
}
.recommendation_slider > .slick-slider > .slick-prev {
  left: -3rem !important;
}
.recommendation_slider > .slick-slider > .slick-prev:before,
.recommendation_slider > .slick-slider > .slick-next:before {
  color: #000 !important;
  font-size: 37px;
}
.recommendation_slider > .slick-slider > .slick-next {
  right: -3rem !important;
}
.dash_form_blk form fieldset .form_blk > div > div.react-datepicker-popper {
  z-index: 9;
}
.dateFormBlk > div {
  width: 100%;
}
.amount_flex {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
}
.amount_flex .info {
  align-items: center;
  gap: 10px;
}
/* .flex_maint_persons > * ._innercol.landlordProfile {
  cursor: not-allowed;
  pointer-events: none;
}
.flex_maint_persons > * ._innercol.landlordProfile:disabled {
  cursor: not-allowed;
  pointer-events: all !important;
} */
.filter_status_offer .switch-off{
  background: #00acfc;
}
.service-align-right .amount_flex{
  justify-content: flex-end;
}
._clear_blk{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}
._clear_blk ._clear_btn{
  font-size: 12px;
  color:#ff6c40;
  text-decoration: underline;
}